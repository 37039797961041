import axios from "axios";

const API_URL = "/api/";
const { localStorage } = window;

class AuthService {
  async login(credentials) {
    const res = await axios({
      method: "post",
      url: API_URL + "login",
      data: {
        email: credentials.username,
        password: credentials.password,
        token: credentials.token,
      },
    });
    if (res.data && res.data.success) {
      localStorage.setItem("auth-data", JSON.stringify(res.data.data));
    }
    return res.data;
  }

  logout() {
    localStorage.removeItem("auth-data");
  }

  async refreshToken() {
    const authData = JSON.parse(localStorage.getItem("auth-data"));
    const res = await axios({
      method: "post",
      url: API_URL + "token/refresh",
      data: {
        refreshToken: authData.refreshToken,
      },
    });
    if (res.data) {
      localStorage.setItem("auth-data", JSON.stringify(res.data.data));
    }
    return res.data;
  }
}

export default new AuthService();
