<template>
  <div>
    <v-form v-model="valid" ref="updClientForm" autocomplete="off" >
      <ValidationObserver ref="clientValidator">
        <v-row>
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="Forme Juridique" rules="required" vid="legalStatus">
              <v-select :items="itemsLists['legalStatuses']" v-model="client.legalStatus" return-object
                item-text="libelle" item-value="code" label="Forme Juridique" outlined
                :loading="getClientLoading || listLoading" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="4">
            <ValidationProvider v-slot="{ errors }" name="Raison Sociale" rules="required" vid="socialReason">
              <v-text-field v-model="client.socialReason" label="Raison Sociale" outlined hide-details clearable
                clear-icon="clear" :error-messages="errors" :loading="getClientLoading"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="4">
            <ValidationProvider v-slot="{ errors }" name="Nom commercial" rules="required" vid="tradeName">
              <v-text-field v-model="client.tradeName" label="Nom commercial" outlined hide-details clearable
                clear-icon="clear" :error-messages="errors" :loading="getClientLoading"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <ValidationProvider v-slot="{ errors }" name="RIDET" rules="required" vid="ridet">
              <v-text-field  v-model="client.siret" v-mask="'### ###'" label="RIDET" outlined hide-details clearable clear-icon="clear"
                :error-messages="errors" :loading="getClientLoading"></v-text-field>
            </ValidationProvider>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <ValidationProvider v-slot="{ errors }" name="RIDET" rules="required" vid="ridet">
              <v-text-field v-model="client.address.adress1" label="Adresse 1" outlined hide-details clearable
                clear-icon="clear" :error-messages="errors" :loading="getClientLoading"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field v-model="client.address.adress2" label="Adresse 2" outlined hide-details clearable
              clear-icon="clear" :loading="getClientLoading"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field v-model="client.address.adress3" label="Adresse 3" outlined hide-details clearable
              clear-icon="clear" :loading="getClientLoading"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" name="code postal" rules="required|integer|length:5" vid="zipCode">
              <v-text-field autocomplete="off" v-model="client.address.zipCode" label="CP *" outlined
                :error-messages="errors" clearable clear-icon="clear" :loading="getClientLoading" />
            </ValidationProvider>
          </v-col>

          <!-- Fix rule with zip code  -->
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="ville" rules="required">
              <v-select :items="itemsLists['citiesForPerson']" v-model="client.address.city" item-text="libelle"
                item-value="libelle" label="Ville *" outlined :loading="getCitiesLoading || getClientLoading"
                :disabled="itemsLists['citiesForPerson'] == 0"
                :error-messages="itemsLists['citiesForPerson'] == 0 ? '' : errors" />
            </ValidationProvider>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="4" sm="3">
            <ValidationProvider v-slot="{ errors }" name="country" rules="required" vid="country">
              <v-select :items="itemsLists['countries']" v-model="client.address.country" return-object
                item-text="libelle" item-value="code" label="Pays" outlined :error-messages="errors"
                :loading="getClientLoading || listLoading"></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="4" sm="3">
            <ValidationProvider v-slot="{ errors }" name="effectif" vid="effectif">
              <v-text-field v-model="client.effectif" label="Effectif" outlined hide-details clearable
                :error-messages="errors" clear-icon="clear" :loading="getClientLoading"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="4" sm="3">
            <ValidationProvider v-slot="{ errors }" name="webSite" vid="webSite">
              <v-text-field v-model="client.webSite" label="Site Web" outlined hide-details clearable clear-icon="clear"
                :loading="getClientLoading" :error-messages="errors"></v-text-field>
            </ValidationProvider>
          </v-col>
          <!--  <v-spacer /> -->
        </v-row>
        <v-row>
          <v-col cols="8" sm="3">
            <ValidationProvider v-slot="{ errors }" name="mail" rules="required" vid="mail">
              <v-text-field v-model="client.mail.mail" label="Adresse mail" outlined hide-details clearable
                clear-icon="clear" :error-messages="errors" :loading="getClientLoading"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="8" sm="3">
            <vue-tel-input v-model="client.phone.number" v-bind="bindProps" @validate="telValidate"></vue-tel-input>
          </v-col>

          <v-col cols="4" sm="3">
            <v-checkbox true-value="true" false-value="false" :input-value="client.phone.acceptSms" hide-details
              color='button_light'>
              <template v-slot:label>
                <div>Accepte SMS</div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col v-if="this.isEstimate == true" cols="6" sm="3">
            <v-dialog v-model="searchDialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn block large color="button" class="button_color--text" v-bind="attrs" v-on="on">
                  <v-icon left>search</v-icon>Rechercher un client
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Rechercher un client</span>
                </v-card-title>

                <v-card-text>
                  <ClientSearch :isEstimate="true" @update-client="updateClient" personTypeToSearch="MOR" />
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col v-if="this.isEstimate == true" cols="6" sm="3">
            <v-btn block large color="button" class="button_color--text" @click="nextStep">
              <v-icon left>right</v-icon>Suivant
            </v-btn>
          </v-col>
          <v-col v-if="this.isEstimate == false" cols="6" sm="3">
            <v-btn block large color="button" class="button_color--text" @click="addClient">
              <v-icon left>add</v-icon>
              {{
      this.clientId == "-1"
        ? "Nouveau client"
        : "Modifier client"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
  </div>
</template>

<style scoped>
.container {
  padding: 0 !important;
}

.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}

.vue-tel-input {
  width: 100%;
  min-height: 58px;
}
</style>

<script>
import ClientSearch from "../client/Search.vue";
import { VueTelInput } from 'vue-tel-input';
import {
  required,
  integer,
  length
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre",
});
extend("length", {
  ...length,
  message: "Le champ {_field_} doit contenir {length} chiffres",
});


extend('isPhoneNumber', {
  validate: (value) => {
    const regexFrance = /^[0-9]{10}$/;//+33
    const regexCal = /^[0-9]{6}$/;//+687
    const regexTahiti = /^[0-9]{8}$/;//+689
    if (value.match(regexCal) || value.match(regexFrance) || value.match(regexTahiti)) {
      return true;
    }
    return '{_field_} n\'est pas un numero de téléphone valide';
  }
});

export default {
  name: "CreationModificationMoralPerson",
  components: {
    ClientSearch,
    ValidationObserver, 
    ValidationProvider,
    VueTelInput
  },
  data: () => ({
    valid: true,
    searchDialog: false,
    menuBirthDate: false,
    formatedBirthDate: null,
    bindProps: {
      required: true,
      autoFormat: true,
      onlyCountries: ["NC", "PF", "FR"],
      defaultCountry: "NC",
      placeholder: "Votre numéro de téléphone",
      mode: "international",
      inputOptions: {
        showDialCode: true
      },
      wrapperClasses: "country-phone-input",
      validCharactersOnly: true,
    }
  }),
  props: {
    isEstimate: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: "-1",
    },
    isEstimateCopy: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // this.getClientItemsLists();
  },
  computed: {
    ...mapState({
      listLoading: (state) => state.estimation.loader["listClient"],
      getClientLoading: (state) => state.estimation.loader["getClient"],
      getCitiesLoading: (state) => state.estimation.loader["listCitiesForPerson"],
      itemsLists: (state) => state.estimation.itemsLists,
      client: (state) => state.estimation.estimate.person,
    }),
  },
  methods: {
    ...mapActions("estimation", ["getClientItemsLists", "fetchClient", "getCitiesListForPerson"]),
    verifyAge(dob) {
      if (dob != undefined) {
        var today = new Date();
        var parts = dob.split("/");
        var birthDate = new Date(
          parseInt(parts[2], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[0], 10)
        );

        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age >= 18 && age <= 77;
      } else return false;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', { timeZone: 'Pacific/Noumea' });
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async nextStep() {
      await this.$refs["clientValidator"].validate().then(async (valid) => {
        if (valid) {
          this.$store.commit("estimation/nextEstimationStep");
        }
      });
    },
    async updateClient(clientId) {
      this.searchDialog = false;
      await this.fetchClient(clientId);
    },
    capitalizeFirstLetter: (str) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },
    capitalize: (str) => {
      return str ? str.toUpperCase() : "";
    },
  },
  watch: {
    "client.phone.numero": {
      handler(val) {
        if (val) {
          this.client.phone.number = val;
        }
      },
      deep: true
    },
    "client.birthDate": {
      handler(val) {
        console.log('birthdate client')
        this.formatedBirthDate = this.formatDate(val);
      },
      deep: true
    },
    //Au changement du code postal, supprime la ville et le tableau de choix des villes
    "client.address.zipCode": {
      handler(val) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        if (!val) {
          this.client.address.city = "";
          this.itemsLists['citiesForPerson'] = []
        } else if (val.length === 5) {
          console.log(val);
          this.timeoutId = setTimeout(() => {
            console.log('timeout', val)
            this.getCitiesListForPerson(val);

          }, 1000);
        }
      },
      deep: true,
      immediate: true
    },
    "client.socialReason": function (newValue) {
      if (newValue) {
        this.client.socialReason = this.capitalize(newValue);
      }
    },
  },
};
</script>
