<template>
  <v-card>
    <v-row>
      <v-col>
        <h2>Véhicule *</h2>
      </v-col>

      <v-col>
        <v-dialog v-model="searchVehicleDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn large color="button" class="button_color--text" v-bind="attrs" v-on="on">
              <v-icon left>search</v-icon> Rechercher
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Rechercher un véhicule</span>
            </v-card-title>

            <v-card-text>
              <SRA @update-vehicle="updateVehicle" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-show="Object.keys(productRisk.vehicle).length > 0">
      <v-col cols="6">
        <p>Marque: {{ productRisk.vehicle.brand }}</p>
        <p>Modèle: {{ productRisk.vehicle.model }}</p>
        <p>Version: {{ productRisk.vehicle.version }}</p>
        <p>Type Mines: {{ productRisk.vehicle.minesType }}</p>
        <p>Code SRA: {{ productRisk.vehicle.SRACode }}</p>
      </v-col>

      <v-col cols="6">
        <p>Energie: {{ productRisk.vehicle.energy }}</p>
        <p>Puissance administrative: {{ productRisk.vehicle.fiscalPower }}</p>
        <p>Carrosserie: {{ productRisk.vehicle.body }}</p>
        <p>Transmission: {{ productRisk.vehicle.boiteVitesses }} </p>
      </v-col>
    </v-row>

    <ValidationObserver ref="observerVehicle" v-slot="{ valid }">
      <v-form>
        <!-- ----- ACQUISITION ----- -->
        <v-row>
          <v-col cols="3" sm="3">
            <h2>Acquisition / Usage</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <ValidationProvider v-slot="{ errors }" name="immatriculation"   rules="imat">
              <v-text-field v-model="productRisk.vehicleInfos.registration"
               label="Immatriculation *" outlined
                :error-messages="errors" clearable 
                 required
                 />
            </ValidationProvider>
          </v-col>
          <v-col cols="3" sm="3">
            <v-menu ref="menuFirstUseDate" v-model="menuFirstUseDate" :close-on-content-click="false"
              transition="scale-transition" offset-y max-width="290px" min-width="auto">

              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" name="date de 1ère mise en circulation" rules="required">
                  <v-text-field v-model="formatedFirstUseDate" v-mask="'##/##/####'"
                    label="Date de 1ère mise en circulation *" outlined prepend-icon="calendar" clearable
                    clear-icon="clear" @click:clear="
                      productRisk.vehicleInfos.firstUseDate = '';
                    " v-bind="attrs" @blur="productRisk.vehicleInfos.firstUseDate = parseDate(formatedFirstUseDate)"
                    v-on="on" required :error-messages="errors"></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker v-model="productRisk.vehicleInfos.firstUseDate" scrollable header-color="tertiary"
                color="tertiary_light" first-day-of-week="1" no-title @input="menuFirstUseDate = false"></v-date-picker>
            </v-menu>

          </v-col>
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="usage" rules="required">
              <v-select :items="itemsLists.uses" v-model="productRisk.vehicleInfos.use" return-object item-text="libelle"
                item-value="code" label="Usage *" outlined :loading="selectsLoading" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row>

          <v-col cols="3" sm="3">
            <v-menu ref="menuFirstUseDate" v-model="menuAcquisitionDate" :close-on-content-click="false"
              transition="scale-transition" offset-y max-width="290px" min-width="auto">

              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" name="date d'acquisition" rules="required">
                  <v-text-field v-model="formatedAcquisitionDate" label="Date d'acquisition *" outlined clearable
                    clear-icon="clear" @click:clear="productRisk.vehicleInfos.acquisitionDate = '';
                    " v-bind="attrs"
                    @blur="productRisk.vehicleInfos.acquisitionDate = parseDate(formatedAcquisitionDate)" v-on="on"
                    required :error-messages="errors" v-mask="'##/##/####'"></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker v-model="productRisk.vehicleInfos.acquisitionDate" scrollable header-color="tertiary"
                color="tertiary_light" first-day-of-week="1" no-title
                @input="menuAcquisitionDate = false"></v-date-picker>
            </v-menu>

          </v-col>
          <v-col cols="3">
            <!-- <ValidationProvider v-slot="{ errors }" name="Zone de circulation" rules="required"> -->

              <v-select :items="itemsLists.drivingAreas" v-model="productRisk.vehicleInfos.drivingArea" return-object
                item-text="libelle" item-value="code" label="Zone de circulation *" outlined :loading="selectsLoading"
                clearable clear-icon="clear" />
            <!-- </ValidationProvider> -->
          </v-col>
        </v-row>

        <!-- ----- SUIVANT ----- -->
        <v-row>
          <v-spacer />
          <v-col>
            <v-btn block large color="button" class="button_color--text" @click="nextStep">
              Suivant
            </v-btn>
            <ValidationProvider v-slot="{ errors }" rules="isTrue" name="Veuillez sélectionner un véhicule">
              <!-- <span>{{errors[0]}}</span> -->
              <v-input v-model="isVehicleSelected" :error-messages="errors" hidden />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<style scoped></style>

<script>
import {
  required,
  integer,
  length,
  double,
  between,
  alpha_num,
} from "vee-validate/dist/rules";
import SRA from "../../../components/SRA.vue";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
import axios from "axios";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre entier",
});
extend("length", {
  ...length,
  message: "Le champ {_field_} doit contenir {length} chiffres",
});
extend("double", {
  ...double,
  message: "Le champ {_field_} doit être une valeur à {decimals} décimale ",
});
extend("between", {
  ...between,
  message: "Le champ {_field_} doit être compris entre {min} et {max}",
});
extend("alpha_num", {
  ...alpha_num,
  message: "Le champ {_field_} doit être alpha-numérique",
});
/* extend("required_if", {
  ...required_if,
  message: "",
}); */

extend("isTrue", {
  validate(value) {
    //console.log('isTrue : '+value)
    return value;
  },
  message: "{_field_}",
});

extend("imat", {
  validate(value) {
    return   ((value && value.length === 8 && value.slice(-2) === 'NC') || (value && value.length === 6 && value.slice(-2) === 'WW') || (value && value.length === 5 && value.slice(-2) === 'WW') );
  },
  message: "L\'{_field_} est composé de 6 chiffres et se terminer par NC ou doit être de type 123WW ou 1234WW",
});

export default {
  name: "VehicleStep",
  components: { SRA, ValidationObserver, ValidationProvider },
  data: () => ({
    searchVehicleDialog: false,

    menuFirstUseDate: false,
    formatedFirstUseDate: null,
    menuAcquisitionDate: false,

    formatedAcquisitionDate: null,

    //isVehicleSelected: false,

    selectsLoading: false,
    citiesLoading: false,
    itemsLists: {
      cities: []
    },
  }),
  props: {
    //vehicle: Object,
    productRisk: Object,
  },
  async created() {
    this.getVehicleItemsLists();
  },
  mounted() {
    this.formatedAcquisitionDate = this.formatDate(this.productRisk.vehicleInfos.acquisitionDate);
    this.formatedFirstUseDate = this.formatDate(this.productRisk.vehicleInfos.firstUseDate);
  },
  computed: {
    isVehicleSelected() { return this.productRisk.vehicle.id != "-1" },

  },
  methods: {
    async getVehicleItemsLists() {
      console.log('getVehiclesI');
      const usesReq = axios.get("/api/listItems/uses");
      const drivingAreasReq = axios.get(
        "/api/listItems/drivingAreas"
      );

      try {
        this.selectsLoading = true;
        const responses = await axios.all([
          /* acquisitionModesReq, */
          usesReq,
          drivingAreasReq
        ]);

        this.itemsLists['uses'] = responses[0].data.listItems;
        this.itemsLists['drivingAreas'] = responses[1].data.listItems;

        this.selectsLoading = false;
      } catch (error) {
        console.log(error);
        this.selectsLoading = false;
      }


    },
   async updateVehicle(vehicle) {
      console.log("VEHICULE", vehicle);
      this.searchVehicleDialog = false;
      const responses = await axios.get(`/api/vehicles/${vehicle.apsadClasse}/franchise-dta-voli`);
      console.log("GET VEHICULE FRANCHISE : ",responses);
      this.searchVehicleDialog = false;
      this.productRisk.vehicle = {...vehicle, ...responses.data};
      console.log( this.productRisk.vehicle);
    },

    nextStep() {
      this.$refs["observerVehicle"].validate().then((valid) => {
        if (valid) {
          console.log(this.risk)
          this.$store.commit('estimation/nextRiskStep')
        }
      });
    },

    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    'productRisk.vehicleInfos.acquisitionDate': {
      handler(val) {
        this.formatedAcquisitionDate = this.formatDate(val);
      },
    },
    'productRisk.vehicleInfos.firstUseDate': {
      handler(val) {
        this.formatedFirstUseDate = this.formatDate(val);
      },
    }
  },

};
</script>
