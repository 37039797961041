<template lang="pug">
div
  ProfileForm
  PasswordForm
</template>

<script>
import ProfileForm from "@/components/account/ProfileForm";
import PasswordForm from "@/components/account/PasswordForm";

export default {
  name: "UpdateMyProfile",
  components: {
    ProfileForm,
    PasswordForm,
  },
};
</script>
