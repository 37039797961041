<template>
  <v-container fluid class="container">
    <v-data-table
      :headers="this.headers"
      :items="this.data"
      item-key="Effet"
      :sort-by="this.options.sortBy"
      :sort-desc="this.options.sortDesc"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100],
      }"
      :search="search"
      class="elevation-3 row-pointer"
    >
      <template v-slot:[`item.dueDateBrut`]="{ item }">
        <v-col>{{ item.dueDate }}</v-col>
      </template>
      <template v-slot:[`item.subject`]="{ item }">
        <v-col v-if=item.comment>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ item.subject }}</span>
          </template>
          <span class="comment">{{ item.comment }}</span>
        </v-tooltip>
        </v-col>
        <v-col v-else>{{ item.subject }}</v-col>
      </template>
    </v-data-table
    >
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}
.comment {
  white-space: pre-line;
  word-wrap: break-word;
}
</style>

<script>
export default {
  name: "genericDatatable",
  data: () => ({}),
  props: {
    data: Array,
    headers: Array,
    search: String,
    options: {
        type: Object | Array,
        default: function () {
          return { sortBy: 'dueDateBrut', sortDesc: true }
        }
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
