<template>
  <div>
    <v-navigation-drawer app v-model="drawer" clipped class="drawer_background">
      <v-list dense>
        <v-list-item router :to="'/'">
          <v-list-item-icon class="mr-4">
            <v-icon class="drawer_color--text">home</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="mr-n4">
            <v-list-item-title class="drawer_color--text text-body-2"
              >Accueil</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense expand>
        <v-list-group
          v-for="item in menu"
          :key="item.category.categoryTitle"
          v-model="item.category.categoryIsExpanded"
          no-action
          :ripple="false"
          class="drawer_color--text"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon
                class="drawer_color--text"
                v-text="item.category.categoryIcon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="mr-n4">
              <v-list-item-title
                class="text-body-2 drawer_color--text"
                v-text="item.category.categoryTitle"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.category.menus"
            :key="child.menuTitle"
            router
            :to="child.menuRoute"
          >
            <v-list-item-content class="mr-n4">
              <v-list-item-title
                class="text-body-2 drawer_color--text font-weight-regular"
                v-text="child.menuTitle"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style scoped>
/* Add elevation to the right side of navigation drawer */
nav {
  box-shadow: -10px 0px 10px 5px var(--v-primary-base);
}

/* Make list more compact */
::v-deep .v-list {
  padding: 0;
}
::v-deep .v-list-item {
  min-height: 30px;
}
::v-deep .v-list-group__header {
  margin-top: 7px;
  padding-bottom: -5px;
}
::v-deep .v-list-group__items > .v-list-item {
  padding-left: 57px !important;
}
/* No way else as far as i know to change arrow append icon color collapsed */
::v-deep .v-icon {
  color: var(--v-drawer_color-base) !important;
}
</style>

<script>
import axios from "axios";

export default {
  name: "MainMenu",
  data: () => ({
    drawer: true,
    menu: [],
  }),
  created() {
    this.menuCategoryList().then((data) => {
      this.menu = data.items;
    });
  },
  mounted() {
    this.$root.$on("NavigationDrawer", () => {
      this.menuCategoryList().then((data) => {
        this.menu = data.items;
      });
    });
  },
  computed: {},
  methods: {
    menuCategoryList() {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/menu/category/list", {
            isEnabled: true,
          })
          .then((response) => {
            let items = response.data.menuCategoryList;

            resolve({
              items,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {},
};
</script>
