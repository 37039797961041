import Vue from 'vue'
import Vuex from 'vuex'


import axios from "axios";

Vue.use(Vuex);

const gavStoreModule = {
    namespaced: true,
    // https://vuex.vuejs.org/guide/modules.html#module-reuse !!
    state: () => ({
        principalAssured: {
            isSubscriber: 'false',
            person: {
                civility: [],
                lastname: "",
                firstname: "",
                maritalStatus: "",
                birthDate: "",
                profession: "",
                socioProfessionalCategory: []
            },
        },
        //subscriber: {},
        secondariesAssured: [],
        defaultSecondaryAssured: {
            person: {
                civility: [],
                lastname: "",
                firstname: "",
                maritalStatus: [],
                birthDate: "",
                profession: "",
                socioProfessionalCategory: []
            },
            relationRole: []
        },
        defaultPrincipalAssured: {
            person: {
                civility: [],
                lastname: "",
                firstname: "",
                maritalStatus: [],
                birthDate: "",
                profession: "",
                socioProfessionalCategory: []
            }
        },
        itemsLists: {
            relations: [],
        },
        loaders: {
            gavLists: false
        }
    }),
    mutations: {
        setProductRisk(state, productRisk) {
            logger.info('set productRisk to GAV view');
            state.principalAssured = productRisk.principalAssured;
            //Vue.set(state, 'subscriber', Object.assign({}, productRisk.principalAssured));
            // Si secondariesAssured est rempli avec des valeurs par défault
            // Supprime l'entré en assignaant un tableau vide 
            // Le li a 2ndy assured pour la remontté des infos
            if(productRisk.secondariesAssured.length == 1 && productRisk.secondariesAssured[0].person.id == '-1')
                productRisk.secondariesAssured = [];
            state.secondariesAssured = productRisk.secondariesAssured;
        },
        resetPersonState(state) {
            logger.info('Reset insured initial state');
            state.principalAssured.person = JSON.parse(JSON.stringify(state.defaultPrincipalAssured.person));
        },
    },
    actions: {
        async getGavItemsLists(context) {
            const relationsReq = axios.get("/api/listItems/relations");
        
            try {
                context.state.loaders['gavLists'] = true;
                const responses = await axios.all([
                    relationsReq,
                ]);

                context.state.itemsLists['relations'] = responses[0].data.listItems;

                context.state.loaders['gavLists'] = false;
                logger.info('END - ListsItems Gav')
            } catch (error) {
                console.log(error)
                this.handleAxiosError(error);
                context.state.loaders['gavLists'] = false;
            }
        },
    }
}

export default gavStoreModule
