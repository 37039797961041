<template>
  <v-data-table :headers="headers" :items="secondariesAssured" class="elevation-1 mb-5">
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn large color="button" class="button_color--text mb-2" v-bind="attrs" v-on="on">
              <v-icon left>add</v-icon>Ajouter des assurés additionnels
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <ValidationObserver ref="form" v-if="dialog === true">
                  <v-form>
                    <div>
                      <v-row>
                        <v-col cols="2">
                          <ValidationProvider v-slot="{ errors }" rules="required" name="civilité">
                            <v-select
                              :items="itemsLists.itemsListsClient['civilities']"
                              item-text="libelleCourt"
                              item-value="code"
                              v-model="editedSecondaryAssured.person.civility"
                              return-object
                              label="Civilité"
                              outlined
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="4">
                          <ValidationProvider v-slot="{ errors }" rules="required" name="nom">
                            <v-text-field
                              v-model="editedSecondaryAssured.person.lastname"
                              label="Nom"
                              outlined
                              clearable
                              clear-icon="clear"
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="4">
                          <ValidationProvider v-slot="{ errors }" rules="required" name="prénom">
                            <v-text-field
                              v-model="editedSecondaryAssured.person.firstname"
                              label="Prénom"
                              outlined
                              clearable
                              clear-icon="clear"
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="2">
                          <ValidationProvider v-slot="{ errors }" rules="required" name="relation">
                            <v-select
                              :items="itemsLists.itemsListsProduct['relations']"
                              item-text="libelle"
                              item-value="code"
                              v-model="editedSecondaryAssured.relationRole"
                              return-object
                              label="Relation"
                              outlined
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="situation matrimoniale"
                          >
                            <v-select
                              :items="itemsLists.itemsListsClient['maritalStatuses']"
                              v-model="editedSecondaryAssured.person.maritalStatus"
                              return-object
                              item-text="libelle"
                              item-value="code"
                              label="Sit. Mat."
                              outlined
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="4" sm="4">
                          <v-menu
                            ref="menuBirthDate"
                            v-model="menuBirthDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                                name="date de naissance"
                              >
                                <v-text-field
                                  v-model="formatedBirthDate"
                                  label="Date de naissance "
                                  outlined
                                  persistent-hint
                                  clearable
                                  clear-icon="clear"
                                  @click:clear="editedSecondaryAssured.person.birthDate = ''"
                                  v-bind="attrs"
                                  @blur="$set(editedSecondaryAssured.person,'birthDate', parseDate(formatedBirthDate))"
                                  v-on="on"
                                  required
                                  :error-messages="errors"
                                />
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="editedSecondaryAssured.person.birthDate"
                              scrollable
                              header-color="tertiary"
                              color="tertiary_light"
                              first-day-of-week="1"
                              no-title
                              @input="menuBirthDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="save">Sauvegarder</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Etes vous sûr de vouloir supprimer cette entrée ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.person.birthDate="{ item }">
      <span>{{ formatDate(item.person.birthDate) }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template> 
  </v-data-table>
</template>

<style scoped>
</style>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} doit être renseigné.",
});

export default {
  name: "Secondaries Assured Component",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    menuBirthDate: false,
    formatedBirthDate: null,
    valid: false,
    editedSecondaryAssured: {},
    editedIndex: -1,
    headers: [
      {
        text: "Civilité",
        value: "person.civility.libelleCourt",
        sortable: false,
      },
      {
        text: "Nom",
        value: "person.lastname",
        sortable: false,
      },
      {
        text: "Prénom",
        value: "person.firstname",
        sortable: false,
      },
      {
        text: "Relation",
        value: "relationRole.libelle",
        sortable: false,
      },
      {
        text: "Sit.Mat.",
        value: "person.maritalStatus.libelle",
        sortable: false,
      },
      {
        text: "Date de naissance",
        value: "person.birthDate",
        sortable: false,
      },
      {
        text: "CSP",
        value: "person.socioProfessionalCategory.libelle",
        sortable: false,
      },
      {
        text: "Profession",
        value: "person.profession",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    dialog: false,
    dialogDelete: false,
  }),
  props: {
    secondariesAssured: Array,
    defaultSecondaryAssured: {},
    itemsLists: Object,
    loader: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    console.log('assign');
    console.log(this.defaultSecondaryAssured);
    this.editedSecondaryAssured = JSON.parse(JSON.stringify(this.defaultSecondaryAssured));
  },

  mounted() {},
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ajouter un assuré" : "Editer un assuré";
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.secondariesAssured.indexOf(item);
      this.editedSecondaryAssured = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.secondariesAssured.indexOf(item);
      this.editedSecondaryAssured = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.secondariesAssured.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedSecondaryAssured = JSON.parse(JSON.stringify(this.defaultSecondaryAssured));
        this.editedIndex = -1;
        this.$refs.form?.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedSecondaryAssured = JSON.parse(JSON.stringify(this.defaultSecondaryAssured));
        this.editedIndex = -1;
      });
    },

    async save() {
      await this.$refs["form"].validate().then((valid) => {
        if (valid) {
          if (this.editedIndex > -1) {
            Object.assign(
              this.secondariesAssured[this.editedIndex],
              this.editedSecondaryAssured
            );
          } else {
            this.secondariesAssured.push(this.editedSecondaryAssured);
          }
          this.close();
        }
      });
    },
    verifyAge(dob) {
      var today = new Date();
      var parts = dob.split("/");
      var birthDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18 && age <= 77;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
   /*  defaultSecondaryAssured(val) {
      console.log('assign');
      this.editedSecondaryAssured = Object.assign({}, val);
    }, */
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "editedSecondaryAssured.person.birthDate"(val) {
      this.formatedBirthDate = this.formatDate(val);
    },
  },
};
</script>









 