import Vue from 'vue'
import Vuex from 'vuex'


import axios from "axios";

Vue.use(Vuex);

const personInitialState = {
    civility: [],
    lastname: "",
    firstname: "",
    maritalStatus: "",
    birthDate: "",
    profession: "",
    socioProfessionalCategory: []
};

const mrhBudgetStoreModule = {
    namespaced: true,
    // https://vuex.vuejs.org/guide/modules.html#module-reuse !!
    state: () => ({
        principalAssured: {
            isSubscriber: 'true',
            person: {
                civility: [],
                lastname: "",
                firstname: "",
                maritalStatus: "",
                birthDate: "",
                profession: "",
                socioProfessionalCategory: []
            },
            risk: {
                clientHomeStatus: [],
                homeType: [],
                homeAddress: {
                    adress1: "",
                    zipCode: "",
                    city: "",
                    country: []
                },
                moreThan30MSquareRoomsNb: "",
                roomsNumber: ""
            }
        },
        options: {
            brisGlace: [],
            brokerFees: 0,
            coeffReduc: 0,
            dezonage: [],
            franchiseIDV: [],
            juridicProtection: [],
            rcViePrivee: [],
            vol: [],
        },
        loaders: {
            homeAddressCities : false,
            mrhLists : false,
            mrhOptsLists : false,
        },
        itemsLists: {
            homeAddressCities: [],
            homeType: [],
            clientHomeStatus: [],
            yesNo: ["OUI", "NON"],
            franchiseIDV: [],
        },
    }),
    mutations: {
        setAddress(state, address) {
            logger.info('Set client adress as home adress')
            state.principalAssured.risk.homeAddress.adress1= address.adress1;
            state.principalAssured.risk.homeAddress.zipCode= address.zipCode;
            state.principalAssured.risk.homeAddress.city = address.city;
            state.principalAssured.risk.homeAddress.country = address.country;
        },
        setProductRisk(state, productRisk) {
            logger.info('set productRisk to MH view');
            state.principalAssured = productRisk.principalAssured;
            state.options = productRisk.options;
        },
        resetPersonState(state) {
            logger.info('Reset insured initial state');
            state.principalAssured.person = personInitialState;
        },
    },
    actions: {
        async getHomeAddressCitiesList(context, zipCode) {
            logger.info('getHomeAddressCitiesList');
            if (zipCode != null && zipCode != "") {
                try {
                    context.state.loaders.homeAddressCities = true;
                    const response = await axios
                        .get("/api/listItems/citiesByZipCodeForPerson", {
                            params: {
                                zipCode: zipCode,
                            },
                        }); 

                    context.state.itemsLists.homeAddressCities = response.data.listItems;

                    context.state.loaders.homeAddressCities = false;
                } catch (error) {
                    context.state.principalAssured.risk.address.city = [];
                    context.state.itemsLists.homeAddressCities = [];

                    context.state.loaders.homeAddressCities = false;
                    this.handleAxiosError(error);
                }
            }
        },
        async getMrhItemsLists(context) {
            const homeTypeReq = axios.get("/api/listItems/homeType");
            const clientHomeStatusReq = axios.get("/api/listItems/clientHomeStatus");
        
            try {
                context.state.loaders['mrhLists'] = true;
                const responses = await axios.all([
                    homeTypeReq,
                    clientHomeStatusReq,
                ]);

                context.state.itemsLists['homeType'] = responses[0].data.listItems;
                context.state.itemsLists['clientHomeStatus'] = responses[1].data.listItems;

                context.state.loaders['mrhLists'] = false;
                logger.info('END - ListsItems Client')
            } catch (error) {
                console.log(error)
                this.handleAxiosError(error);
                context.state.loaders['mrhLists'] = false;
            }
        },
        async getMrhOptsItemsLists(context) {
            const franchiseIDVReq = axios.get("/api/listItems/franchiseIDV");

            try {
                context.state.loaders['mrhOptsLists'] = true;
                const responses = await axios.all([
                    franchiseIDVReq,
                ]);

                context.state.itemsLists['franchiseIDV'] = responses[0].data.listItems;

                context.state.loaders['mrhOptsLists'] = false;
                logger.info('END - mrhOptsLists')
            } catch (error) {
                console.log(error)
                this.handleAxiosError(error);
                context.state.loaders['mrhOptsLists'] = false;
            }
        },
    }
}

export default mrhBudgetStoreModule
