var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"color":"primary","absolute":"","right":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_vm._t("form",null,{"editedItem":_vm.editedItem})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v("Sauvegarder")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.removeText ? _vm.removeText : 'Êtes-vous sûr de bien vouloir supprimer ?'))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 ma-3",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Chargement en cours...","multi-sort":"","options":_vm.options,"server-items-length":_vm.nbItemsTotal,"footer-props":{showFirstLastPage: true, itemsPerPageOptions:[5,10,20,50,100]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_vm._t([("item." + (header.value))],function(){return [_vm._v(_vm._s(_vm.getVal(item, header.value)))]},{"item":item})]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("edit")]),(_vm.remove)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }