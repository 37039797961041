<template>
  <div>
    <!-- Card Header content -->
    <v-skeleton-loader v-if="firstLoad" :loading="loading" type="card-heading, list-item@2" class="mb-8"></v-skeleton-loader>

    <div id="contractFixedHeader">
      <v-card v-show="!firstLoad" v-model="contract" class>
         <v-card-title class="text-h6 text-uppercase tertiary list_color--text">
          <v-icon class="list_color--text" left>{{ contract.details.icon }}</v-icon>
          {{ contract.details.number }}
          <v-spacer></v-spacer>
          <div v-if="!firstLoad">
            <v-menu open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="10"
                  class="tertiary_light button_color--text"
                  x-large
                >
                  Actions
                </v-btn>
              </template>

              <v-list dense no-action class="pt-0">
                <template v-if="!this.contract.details.isEstimate">
                  <v-list-item
                    v-for="(action, i) in actionsContractMenu"
                    :key="i"
                    router
                    :to="action.route"
                    @click="functionCall(action.action)"
                  >
                    <v-list-item-title class="font-weight-regular">{{
                      action.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item
                    v-for="(action, i) in actionsInvoiceMenu"
                    :key="i"
                    router
                    :to="action.route"
                    @click="functionCall(action.action)"
                  >
                    <v-list-item-title class="font-weight-regular">{{
                      action.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>

        <v-card-text>
          <v-simple-table v-model="contract">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-md-right d-block d-md-table-cell">Client</td>
                  <td
                    v-if="contract.details.person.personType == 'PHY'"
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.person.fullname"
                    @click="$router.push('/client/search/' + contract.details.person.id)"

                  ></td>
                  <td
                    v-else
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.person.tradeName"
                    @click="$router.push('/client/search/' + contract.details.person.id)"
                  ></td>
                  <td class="text-md-right d-block d-md-table-cell">Type</td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.type.libelle"
                  ></td>
                  <td class="text-md-right d-block d-md-table-cell">Date d'effet</td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="formatDate(contract.details.effectDate)"
                  ></td>
                  <td class="text-md-right d-block d-md-table-cell d-sm-none">
                    <v-icon>email</v-icon>
                  </td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.addresses.mail.mail"
                  ></td>
                </tr>
                <tr>
                  <td class="text-md-right d-block d-md-table-cell">Produit</td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.product.libelle"
                  ></td>
                  <td class="text-md-right d-block d-md-table-cell">Statut</td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.status"
                  ></td>
                  <td class="text-md-right d-block d-md-table-cell">Solde TTC</td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.computed.nextTakingAmount + ' ' + $currency"
                  ></td>
                  <td class="text-md-right d-block d-md-table-cell d-sm-none">
                    <v-icon>call</v-icon>
                  </td>
                  <td
                    class="font-weight-bold d-block d-md-table-cell"
                    v-text="contract.details.addresses.phone.number.match(/.{1,2}/g).join(' ')"
                  ></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <!-- Tab titles -->
      <v-skeleton-loader v-if="firstLoad" :loading="loading" type="table-thead"></v-skeleton-loader>

      <v-tabs
        v-show="!firstLoad"
        v-model="tab"
        grow
        color="list_color"
        background-color="list_background"
        active-class="tertiary"
      >
        <v-tabs-slider color="tertiary"></v-tabs-slider>

        <v-tab v-for="tab in tabs" :key="tab.title" class="list_color--text" :to="tab.href">
          {{ tab.title }}
          <v-icon right small color="tertiary_light">{{ tab.icon }}</v-icon>
          <span class="tertiary_light--text">{{ tab.number }}</span>
        </v-tab>
      </v-tabs>
    </div>

    <!-- Page content -->
    <div id="contractContent">
      <v-tabs-items v-model="tab" :value="tab">
        <!-- First tab item, General contract -->
        <v-tab-item value="informations">
          <v-skeleton-loader v-if="firstLoad" :loading="loading" type="heading, table-tbody" class="mt-8"></v-skeleton-loader>

          <v-simple-table v-show="!firstLoad">
            <template v-slot:default>
              <tbody>
                <!-- General Informations -->
                <tr>
                  <td colspan="4" class="pa-0 pt-5">
                    <v-subheader class="text-h5">Informations générales</v-subheader>
                  </td>
                </tr>

                <tr>
                  <td class="text-right">Commercial</td>
                  <td class="font-weight-bold" v-text="contract.details.commercialCode"></td>

                  <td colspan="2" rowspan="4">
                    <template
                      v-if="contract.details.annualPrime != 0 && contract.details.annualPrime"
                    >
                      <v-simple-table class="list_background_light pa-4">
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td class="text-center" style="vertical-align: top">
                                <span class="text-h5">Prime annuelle TTC</span>
                                <br />
                                <span class="text-h4" v-text="contract.details.annualPrime + ' ' + $currency"></span>
                              </td>
                              <td
                                class="text-right"
                                style="border-left: 1px solid black"
                                v-if="contract.computed.payementMethod"
                              >
                                <span class="text-h5">Prochain prélévement</span>
                                <br />
                                <span class="text-h4">
                                  de {{ contract.computed.nextTakingAmount + " " + $currency
                                  }}
                                  <br />
                                  le {{ contract.computed.nextTakingDate }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text-right">Formule</td>
                  <td class="font-weight-bold" v-text="contract.details.formula.libelle"></td>
                </tr>
                <tr>
                  <td class="text-right">Fractionnement</td>
                  <td class="font-weight-bold" v-text="contract.details.formula.splitting"></td>
                </tr>
                <tr>
                  <td class="text-right">Mode de paiement</td>
                  <td class="font-weight-bold" v-text="contract.computed.payementMethod"></td>
                </tr>

                <!-- Subscriber Informations -->
                <tr>
                  <td colspan="4" class="pa-0 pt-5">
                    <v-subheader class="text-h5">Informations souscripteur</v-subheader>
                  </td>
                </tr>

                <template v-if="contract.details.person.personType == 'PHY'">
                  <tr>
                    <td class="text-right">Civilité</td>
                    <td
                      class="font-weight-bold"
                      v-text="contract.details.person.civility.libelleLong"
                    ></td>
                    <td colspan="2" rowspan="5">
                      <div class="d-flex">
                        <v-icon left color="tertiary_light" size="64">location_on</v-icon>
                        <div class="text-h6">
                          {{ contract.details.addresses.address.adress1 }}
                          <br />
                          {{ contract.details.addresses.address.zipCode }} {{ contract.details.addresses.address.city }}
                        </div>
                      </div>

                      <br />
                      <br />

                      <div class="d-flex" v-if="contract.details.addresses.bankAccount">
                        <v-icon left color="tertiary_light" size="64">payment</v-icon>
                        <div class="text-h6">
                          {{ contract.details.addresses.bankAccount.accountOwner }}
                          <br />
                          {{ contract.details.addresses.bankAccount.ibanCode }}
                          <br />
                          {{ contract.details.addresses.bankAccount.bicCode }} {{contract.details.addresses.bankAccount.bicCode != "" ? "/" : ""}} {{ contract.details.addresses.bankAccount.domiciliation }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">Date de naissance</td>
                    <td class="font-weight-bold" v-text="formatDate(contract.details.person.birthDate)"></td>
                  </tr>
                  <tr>
                    <td class="text-right">Situation maritale</td>
                    <td
                      class="font-weight-bold"
                      v-text="contract.details.person.maritalStatus.libelle"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Catégorie socioprofessionnelle</td>
                    <td
                      class="font-weight-bold"
                      v-text="contract.details.person.socioProfessionalCategory.libelle"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Profession</td>
                    <td class="font-weight-bold" v-text="contract.details.person.profession"></td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-right">Nom commercial</td>
                    <td class="font-weight-bold" v-text="contract.details.person.tradeName"></td>
                    <td colspan="2" rowspan="5">
                      <div class="d-flex">
                        <v-icon left color="tertiary_light" size="64">location_on</v-icon>
                        <div class="text-h6">
                          {{ contract.details.person.address.adress1 }}
                          <br />
                          {{ contract.details.person.address.zipCode }} {{ contract.details.person.address.city }}
                        </div>
                      </div>

                      <br />
                      <br />

                      <div class="d-flex" v-if="contract.details.addresses.bankAccount">
                        <v-icon left color="tertiary_light" size="64">payment</v-icon>
                        <div class="text-h6">
                          {{ contract.details.addresses.bankAccount.accountOwner }}
                          <br />
                          {{ contract.details.addresses.bankAccount.ibanCode }}
                          <br />
                          {{ contract.details.addresses.bankAccount.bicCode }} {{contract.details.addresses.bankAccount.bicCode != "" ? "/" : ""}} {{ contract.details.addresses.bankAccount.domiciliation }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">Forme juridique</td>
                    <td
                      class="font-weight-bold"
                      v-text="contract.details.person.legalStatus.libelle"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Code SIREN</td>
                    <td class="font-weight-bold" v-text="contract.details.person.siren"></td>
                  </tr>
                  <tr>
                    <td class="text-right">Effectif</td>
                    <td class="font-weight-bold" v-text="contract.details.person.effective"></td>
                  </tr>
                  <tr>
                    <td class="text-right">Site web</td>
                    <td class="font-weight-bold" v-text="contract.details.person.website"></td>
                  </tr>
                </template>

                <!-- Commercial Informations -->
                <template
                  v-if="
                    contract.details.productRisk && contract.details.productRisk.options &&(
                    contract.details.productRisk.options.brokerFees ||
                    contract.details.commission ||
                    contract.details.productRisk.options.coeffReduc ||
                    contract.details.productRisk.options.isCooperative)
                  "
                >
                  <tr>
                    <td colspan="4" class="pa-0 pt-5">
                      <v-subheader class="text-h5">Informations commerciales</v-subheader>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">Commission</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.commission"
                    ></td>
                    <!-- <td class="text-right">Frais de courtage annexes</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.options.brokerFees"
                    ></td> -->
                  </tr>
                  <!-- <tr>
                    <td class="text-right">Aménagement commercial</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.options.coeffReduc"
                    ></td>
                    <td class="text-right">Coopérative</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.options.isCooperative"
                    ></td>
                  </tr> -->
                </template>

                <!-- Risk Informations -->
                <template
                  v-if="contract.details.product.code == $codeAuto || contract.details.product.code == $codeAutoKm"
                >
                  <tr>
                    <td colspan="4" class="pa-0 pt-5">
                      <v-subheader class="text-h5">Informations sur le risque</v-subheader>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">Immatriculation</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.registration"
                    ></td>
                    <td class="text-right">Date de 1ère mise en circulation</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="formatDate(contract.details.productRisk.vehicleInfos.firstUseDate)"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Marque</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.brand"
                    ></td>
                    <td class="text-right">Date d'acquisition</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="formatDate(contract.details.productRisk.vehicleInfos.acquisitionDate)"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Modèle</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.model"
                    ></td>
                    <td class="text-right">Usage</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.use.code"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Version</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.version"
                    ></td>
                    <td class="text-right">Nombre de Km par an</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.kmsByYear"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Type Mines</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.minesType"
                    ></td>
                    <td class="text-right">Zone de circulation</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.parkDetails.area"
                    ></td>
                  </tr>
                  <tr>
                    <td class="text-right">Code SRA</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.SRACode"
                    ></td>
                    <!-- <td class="text-right">Code postal</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.parkDetails.zipCode"
                    ></td> -->
                  </tr>
                  <tr>
                    <td class="text-right">Energie</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.energy"
                    ></td>
                    <!-- <td class="text-right">Ville</td>
                    <td
                      class="font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.parkDetails.city"
                    ></td> -->
                  </tr>
                  <tr>
                    <td class="text-right">Puissance administrative</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.fiscalPower"
                    ></td>
                    <!-- <td class="text-right">Zone</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicleInfos.parkDetails.area"
                    ></td> -->
                  </tr>
                  <tr>
                    <td class="text-right">Genre</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.vehicle.type"
                    ></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>

                <!-- Driver Informations -->
                <template
                  v-if="contract.details.product.code == $codeAuto || contract.details.product.code == $codeAutoKm"
                >
                  <tr>
                    <td colspan="4" class="pa-0 pt-5">
                      <v-subheader class="text-h5">Informations conducteur(s)</v-subheader>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Nom</th>
                              <th class="text-left">Type</th>
                              <th class="text-left">CRM</th>
                              <th class="text-left">Date de naissance</th>
                              <th class="text-left">Date de permis</th>
                              <th class="text-left">Nombre d'année(s) Bonus 50</th>
                              <th class="text-left">Période assurance</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(driver, index) in contract.details.productRisk.drivers"
                              :key="index"
                            >
                              <td>{{ driver.person.fullname }}</td>
                              <td>{{ driver.isPrincipal == "true" ? "Principal" : "Secondaire" }}</td>
                              <td>{{ (driver.risk || {}).crm || null }}</td>
                              <td>{{ formatDate(driver.person.birthDate) }}</td>
                              <td>{{ formatDate(driver.person.driverLicence.effectDate)}}</td>
                              <td>{{ (driver.risk || {}).minCRMDuration || null }}</td>
                              <td>{{ (driver.risk || {}).insurancePeriod || null }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>
                </template>

                <!-- Guarantees Informations -->
                <template v-if="contract.details.formula.guarantees.length">
                  <tr>
                    <td colspan="4" class="pa-0 pt-5">
                      <v-subheader class="text-h5">Garantie(s) souscrite(s)</v-subheader>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Garantie</th>
                              <th class="text-right">TTC</th>
                              <th class="text-right">Franchise</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(guarantee, index) in contract.details.formula.guarantees"
                              :key="index"
                            >
                              <td>{{ guarantee.libelle }}</td>
                              <td class="text-right">{{ guarantee.amount }} {{ $currency }}</td>
                              <td class="text-right" v-if="guarantee.franchiseValue != 0">
                                {{ guarantee.franchiseValue }}
                                {{ guarantee.franchiseCurrencylabel }}
                              </td>
                              <td v-else></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>
                </template>

                <!-- Optional Guarantees -->
                <template
                  v-if="contract.details.productRisk && contract.details.productRisk.options && (contract.details.productRisk.options.isConduiteExclusive || contract.details.productRisk.options.niveauAssistance)"
                >
                  <tr>
                    <td colspan="4" class="pa-0 pt-5">
                      <v-subheader class="text-h5">Garantie(s) optionnelle(s)</v-subheader>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">Conduite exclusive</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.options.isConduiteExclusive"
                    ></td>
                    <td class="text-right"></td>
                    <td class="text-uppercase font-weight-bold"></td>
                  </tr>
                  <tr>
                    <td class="text-right">Niveau d'assistance</td>
                    <td
                      class="text-uppercase font-weight-bold"
                      v-text="contract.details.productRisk.options.niveauAssistance.code"
                    ></td>
                    <td class="text-right"></td>
                    <td class="text-uppercase font-weight-bold"></td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>

        <!-- Todo, Second tab item, Movements -->
        <v-tab-item value="mouvements">
          <GenericDataTable
            :data="this.contract.movements.data"
            :headers="this.contract.movements.headers"
            :options="this.contract.movements.options"
            :hideFooter="true"
          />
        </v-tab-item>

        <!-- Todo, Third tab item, Primes -->
        <v-tab-item v-if="!this.contract.isEstimate" value="primes">
          <PrimesDataTable
            :data="this.contract.primes.data"
            :headers="this.contract.primes.headers"
            :options="this.contract.primes.options"
            :contract="this.contract"
            :getContractParent="submitGetContract"
          />
        </v-tab-item>

        <!-- Todo, Third tab item, Documents -->
        <v-tab-item value="pieces">
          <PiecesDataTable
            :data="this.contract.documents.data"
            :headers="this.contract.documents.headers"
            :options="this.contract.documents.options"
            :contract="this.contract"
            :getContractParent="submitGetContract"
          />
        </v-tab-item>

        <!-- Todo, Third tab item, Claims/Sinistres -->
        <v-tab-item v-if="!this.contract.isEstimate" value="sinistres">
          <ClaimsDataTable
            v-if="!this.contract.isEstimate"
            :data="this.contract.claims.data"
            :headers="this.contract.claims.headers"
            :options="this.contract.claims.options"
          />
        </v-tab-item>

        <!-- Tab Editiques -->
        <v-tab-item value="documents">
          <EditicsDataTable
            :data="this.contract.editiques.data"
            :headers="this.contract.editiques.headers"
            :options="this.contract.editiques.options"
            :isEstimation="false"
            :contract="this.contract"
            :getContractParent="submitGetContract"
          />
        </v-tab-item>

        <!-- Todo, Third tab item, Tasks/Taches -->
        <v-tab-item value="taches">
          <GenericDataTable
            :data="this.contract.tasks.data"
            :headers="this.contract.tasks.headers"
            :options="this.contract.tasks.options"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-dialog id="modalModifMail" v-model="modalModifMail" width="600">
      <UpdateMailTel
        :contract="this.contract"
        :getContractParent="submitGetContract"
        v-on:close-dialog="closeDialog"
      />
    </v-dialog>

    <v-dialog id="modalModifPayment" v-model="modalModifPayment" width="600">
      <UpdateBankAddress
        :contract="this.contract"
        :getContractParent="submitGetContract"
        :isDialog="true"
        v-on:close-dialog="closeDialog"
      />
    </v-dialog>
    
  </div>
</template>

<style scoped>
#contractFixedHeader {
  position: sticky;
  top: 80px;
  z-index: 1;
}

#fab-menu .v-btn {
  position: fixed;
  top: 130px;
  right: 25px;
  z-index: 1;
}

::v-deep tr:hover {
  background-color: transparent !important;
}

.row-pointer > tbody tr:hover {
  cursor: pointer;
  background-color: var(--v-list_background_light-base) !important;
}
</style>

<script>
import GenericDataTable from "../../components/GenericDatatable.vue";
import EditicsDataTable from "../../components/EditicsDataTable.vue";
import PiecesDataTable from "../../components/PiecesDataTable.vue";
import PrimesDataTable from "../../components/PrimesDataTable.vue";
import ClaimsDataTable from "../../components/ClaimsDataTable.vue";
import UpdateMailTel from "../../components/UpdateMailTel.vue";
import UpdateBankAddress from "../../components/UpdateBankAddress.vue";

import axios from "axios";

import Vue from 'vue';

export default {
  name: "Contract",

  components: {
    GenericDataTable,
    EditicsDataTable,
    PiecesDataTable,
    PrimesDataTable,
    ClaimsDataTable,
    UpdateMailTel,
    UpdateBankAddress,
  },

  data: () => ({
    firstLoad: true,
    loading: true,

    idContrat: 0,

    tab: null,

    contract: {
      guarantees: [],
      details: [],
      computed: [],
      primes: [],
      movements: [],
      claims: [],
      tasks: [],
      documents: [],
      editiques: [],
    },

    actionsInvoiceMenu: [
      { title: "Ajouter des pièces", route: "#pieces" },
      // Modification impossible pour les devis
      /* {
        title: "Modifier le mail / téléphone",
        route: "",
        action: "showDialogModifMail",
      }, */
      { title: "Autre demande", route: "/task/creation"},
    ],

    actionsContractMenu: [
      { title: "Ajouter des pièces", route: "#pieces" },
      {
        title: "Modifier le mail / téléphone",
        route: "",
        action: "showDialogModifMail",
      },
      { title: "Demande d'avenant", route: "/task/creation?code=AVENANT" },
      { title: "Autre demande", route: "/task/creation" },
      // @TODO attendre retour maëlle sur les mandats sepa
      /* { title: "Modifier l'IBAN", route: "", action: "showDialogModifPayment" }, */
      { title: "Payer en ligne", route: "#primes" },
    ],

    modalModifMail: false,
    modalModifPayment: false,
  }),
  created() {},
  mounted() {
    this.idContrat = this.$route.params.id;
   
    this.getContract().then((data) => {
      this.contract = data.contract;
      this.firstLoad = false;
      this.loading = false;
      console.log('mount');
      this.activateEstimateTransfo();
      this.activateEstimateDupli();
    });
  },
  computed: {
    tabs: function () {
      if (this.contract.details.isEstimate) {
        return [
          {
            title: "Informations",
            icon: "",
            number: "",
            href: "#informations",
          },
          { title: "Mouvements", icon: "", number: "", href: "#mouvements" },
          {
            title: "Pièces",
            icon:
              this.contract.computed.processingDocumentsNb == 0
                ? ""
                : "numbers",
            number:
              this.contract.computed.processingDocumentsNb == 0
                ? ""
                : this.contract.computed.processingDocumentsNb,
            href: "#pieces",
          },
          { title: "Documents", href: "#documents" },
          {
            title: "Taches",
            icon:
              this.contract.computed.processingTasksNb == 0 ? "" : "numbers",
            number:
              this.contract.computed.processingTasksNb == 0
                ? ""
                : this.contract.computed.processingTasksNb,
            href: "#taches",
          },
        ];
      } else {
        return [
          { title: "Informations", href: "#informations" },
          { title: "Mouvements", href: "#mouvements" },
          {
            title: "Primes",
            icon: this.contract.computed.unpaidPrimes ? "priority_high" : "",
            href: "#primes",
          },
          {
            title: "Pièces",
            icon:
              this.contract.computed.processingDocumentsNb == 0
                ? ""
                : "numbers",
            number:
              this.contract.computed.processingDocumentsNb == 0
                ? ""
                : this.contract.computed.processingDocumentsNb,
            href: "#pieces",
          },
          {
            title: "Sinistres",
            icon: this.contract.computed.claimsNb == 0 ? "" : "numbers",
            number:
              this.contract.computed.claimsNb == 0
                ? ""
                : this.contract.computed.claimsNb,
            href: "#sinistres",
          },
          { title: "Documents", href: "#documents" },
          {
            title: "Taches",
            icon:
              this.contract.computed.processingTasksNb == 0 ? "" : "numbers",
            number:
              this.contract.computed.processingTasksNb == 0
                ? ""
                : this.contract.computed.processingTasksNb,
            href: "#taches",
          },
        ];
      }
    },
  },
  methods: {
    activateEstimateTransfo() {
      console.log('activate')
      if (
        this.contract.details.status != "Sans-effet" &&
        this.contract.details.status != "Devis sans-suite" &&
        this.contract.details.status != "Devis transformé" &&
        this.contract.details.person.personType != 'MOR' &&
        (
          this.contract.details.product.code == Vue.prototype.$codeGav ||
          this.contract.details.product.code == Vue.prototype.$codePJ ||
          this.contract.details.product.code == Vue.prototype.$codeAuto
        )
      )
      
        this.actionsInvoiceMenu.push({
          title: "Transformer en contrat",
          route: "",
          action: "goToCreateContract",
        });
        
    },
    activateEstimateDupli() {
      console.log('activateDupli')
      if (
        (this.contract.details.product.code == Vue.prototype.$codeGav ||
          this.contract.details.product.code == Vue.prototype.$codeAuto/* ||
          this.contract.details.product.code == Vue.prototype.$codeMrhBudget */) &&
          this.contract.details.person.personType != 'MOR'
      )

        this.actionsInvoiceMenu.push({
          title: "Dupliquer le devis",
          route: "",
          action: "goToDuplicateEstimate",
        });
        
    },
    showDialogModifMail() {
      this.modalModifMail = true;
    },
    showDialogModifPayment() {
      this.modalModifPayment = true;
    },
    goToCreateContract() {
      this.$router.push({
        name: "Transformer un devis en contrat",
        params: { estimateId: this.idContrat },
      });
    },
    goToDuplicateEstimate() {
      this.$router.push({
        name: "Dupliquer un devis",
        params: { estimateId: this.idContrat },
      });
    },
    closeDialog: function () {
      this.modalModifMail = false;
      this.modalModifPayment = false;
    },
    functionCall(name) {
      this[name]();
    },
    submitGetContract() {
      this.firstLoad = true;
      this.loading = true;
      
      this.contract = {
        guarantees: [],
        //details: [],
        computed: [],
        primes: [],
        movements: [],
        claims: [],
        tasks: [],
        documents: [],
        editiques: [],
      };

      this.getContract().then((data) => {
        this.contract = data.contract;
        //this.$router.push("/contract/search/" + data.contract.details.id);
        this.firstLoad = false;
        this.loading = false;
      });
    },
    getContract() {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/contract/get", {
            idContrat: this.idContrat,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let contract = response.data.contract;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              contract,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
  },
  watch: {},
};
</script>
