var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Consulter et éditer mes informations")]),_c('v-card-subtitle',[_vm._v("Voir et éventuellement modifier mes informations personnelles")]),_c('validation-observer',{ref:"userObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('input',{attrs:{"type":"hidden","name":"user.id"}}),_c('validation-provider',{attrs:{"vid":"user.firstName","name":"prénom","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":35,"error-messages":errors,"label":"Prénom","required":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"user.lastName","name":"nom","rules":"required|max:35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":35,"error-messages":errors,"label":"Nom","required":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"user.email","name":"email","rules":"required|max:50|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"text-right"},[_c('v-btn',{staticClass:"button_color--text",attrs:{"loading":_vm.loading,"color":"button","disabled":invalid},on:{"click":_vm.updateMyProfile}},[_vm._v("enregistrer")])],1)]}}])})],1),_c('SnackBar',{attrs:{"show":_vm.showSnackBar,"message":_vm.message}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }