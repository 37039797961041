<template>

  <div>

    <v-card>
      <ValidationObserver ref="observerDriver" v-slot="{ valid }">
        <form @submit.prevent="addDriver">
          <v-row>
            <v-col cols="3" sm="3">
              <h2>Conducteur :</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="civilité" rules="required">
                <v-select :items="personItemsLists.civilities" item-text="libelleCourt" item-value="code"
                  v-model="driverModel.person.civility" return-object
                  label="Civilité *"
                  outlined required :error-messages="errors" :loading="selectsLoading"
                  :clearable="!driverModel.isSubscriber" clear-icon="clear" />
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="nom" rules="required">
                <v-text-field v-model="driverModel.person.lastname" label="Nom *" outlined
                 
                  :error-messages="errors" clearable clear-icon="clear" />
              </ValidationProvider>
            </v-col>

            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="prénom" rules="required">
                <v-text-field v-model="driverModel.person.firstname" label="Prénom *" outlined
                 
                  :error-messages="errors" clearable clear-icon="clear" />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="statut matrimonial" rules="required">
                <v-select :items="personItemsLists.maritalStatuses" :loading="selectsLoading"
                  v-model="driverModel.person.maritalStatus" return-object item-text="libelle" item-value="code"
                  label="Sit. Mat. *"
                  outlined :error-messages="errors" :clearable="!driverModel.isSubscriber" clear-icon="clear" />
              </ValidationProvider>
            </v-col>
            <v-col cols="3" sm="3">
              <v-menu ref="menuBirthDate" v-model="menuBirthDate" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" name="date de naissance" vid="birthDate" rules="required">
                    <v-text-field v-model="formatedBirthDate" label="Date de naissance *" v-mask="'##/##/####'" outlined
                     
                      prepend-icon="calendar" clearable clear-icon="clear" @click:clear="birthDate = ''" v-bind="attrs"
                      @blur="birthDate = parseDate(formatedBirthDate)" v-on="on"
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker v-model="birthDate" scrollable header-color="tertiary" color="tertiary_light"
                  first-day-of-week="1" no-title @input="menuBirthDate = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <!-- ----- ANTECEDENTS ----- -->
          <v-row>
            <v-col cols="3" sm="3">
              <h2>Antécédents d'assurance et permis :</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="CRM" rules="required|double:2|between:0.50,1.00" vid="crm">
                <v-text-field v-model="driverModel.risk.crm" label="CRM (de 0.50 à 1.00) *" outlined clearable
                  clear-icon="clear" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="nombre d'années à 0.50"
                rules="required_if:crm,0.50|integer|between:0,99">
                <v-text-field v-model="driverModel.risk.minCRMDuration" label="Nombre d'années à 0.5" outlined
                  :error-messages="errors" clearable clear-icon="clear" :disabled="driverModel.risk.crm != 0.50" />
              </ValidationProvider>
            </v-col>

            <v-col cols="3" sm="3">
              <!--<ValidationProvider claimResponsabilities v-slot="{ errors }" name="durée des antécédents" rules="required">
                <v-select :items="itemsLists.insurancedPeriods" v-model="driverModel.risk.seniority" return-object
                  item-text="libelle" item-value="code" label="Durée des antécédents *" outlined :error-messages="errors"
                  :loading="selectsLoading" clearable clear-icon="clear" />
              </ValidationProvider>-->
              <ValidationProvider v-slot="{ errors }" name="Nombre de mois d'assurance"
                rules="required|integer|between:0,36">
                <v-text-field v-model="driverModel.risk.seniority" label="Nombre de mois d'assurance de 0 à 36 *"
                  outlined :error-messages="errors" clearable clear-icon="clear" type="number" />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <ValidationProvider v-slot="{ errors }" name="numéro de permis" rules="alpha_num">
                <v-text-field v-model="driverModel.person.driverLicence.number" label="Numéro de permis" outlined
                  :error-messages="errors" clearable clear-icon="clear" />
              </ValidationProvider>
            </v-col>
            <v-col cols="3" sm="3">
              <v-menu ref="menuEffectDateLicence" v-model="menuEffectDateLicence" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" name="date d'effet permis B"
                    rules="required|effectDateLicenceRule:@birthDate">
                    <v-text-field v-model="formatedEffectDateLicence" label="Date d'obtention permis *" outlined
                      prepend-icon="calendar" clearable clear-icon="clear" @click:clear="effectDateLicence = ''"
                      v-bind="attrs" @blur="
        effectDateLicence = parseDate(formatedEffectDateLicence)
        " v-on="on" :error-messages="errors" v-mask="'##/##/####'"></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker v-model="effectDateLicence" scrollable header-color="tertiary" color="tertiary_light"
                  first-day-of-week="1" no-title @input="menuEffectDateLicence = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3" sm="3">
              <v-select :items="drivingTypes" v-model="driverModel.person.driverLicence.drivingType" return-object
                item-text="libelle" item-value="code" label="Type conduite" outlined
                :disabled="driverModel.isPrincipal || productRisk.drivers.length === 0" :loading="selectsLoading" clearable clear-icon="clear" />
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col cols="6" sm="3">
              <ValidationProvider vid="canceledByInsurer" name="résilié">
                <v-switch v-model="driverModel.risk.canceledByInsurer" class='ml-3' color='button_light'
                  label="Résilié par un précédent assureur dans les 3 dernières années ?" />
              </ValidationProvider>
            </v-col>
            <v-col cols="3" sm="3">
              <ValidationProvider v-slot="{ errors }" name="pour quelle raison ?"
                rules="required_if:canceledByInsurer,true">
                <v-select :items="itemsLists.canceledByInsurerReasons" v-model="driverModel.risk.canceledByInsurerReason"
                  return-object item-text="libelle" item-value="code" label="Pour quelle raison ?" outlined
                  :disabled="!driverModel.risk.canceledByInsurer" :error-messages="errors" :loading="selectsLoading" />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="3">
              <ValidationProvider vid="suspendedLicence" name="suspension">
                <v-switch v-model="driverModel.risk.suspendedLicence" class='ml-3' color='button_light'
                  label="Suspension ou annulation de permis dans les 3 dernières années ?" />
              </ValidationProvider>
            </v-col>
            <v-col cols="3" sm="3">
              <ValidationProvider v-slot="{ errors }" name="pour quelle durée ?"
                rules="required_if:suspendedLicence,true">
                <v-select :items="itemsLists.suspendedLicenceReasons" v-model="driverModel.risk.suspendedLicenceReason"
                  return-object item-text="libelle" item-value="code" label="Pour quelle durée ?" outlined
                  :disabled="!driverModel.risk.suspendedLicence" :error-messages="errors" :loading="selectsLoading" />
              </ValidationProvider>
            </v-col>
          </v-row>
          -->

          <!-- ----- ANTECEDENTS INFRACTIONS ----- -->
          <v-row>
            <v-col cols="4" sm="4">
              <h2>Antécédents infractions :</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" sm="3">
              <v-menu ref="menuInfractionDate1" v-model="menuInfractionDate1" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">

                  <v-text-field v-model="formatedInfractionDate1" label="Date infraction 1" v-mask="'##/##/####'"
                    outlined prepend-icon="calendar" clearable clear-icon="clear" v-bind="attrs"
                    @blur="infractionDate1 = parseDate(formatedInfractionDate1)" v-on="on">
                  </v-text-field>

                </template>
                <v-date-picker v-model="infractionDate1" scrollable header-color="tertiary" color="tertiary_light"
                  first-day-of-week="1" no-title @input="menuInfractionDate1 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" sm="3">

              <v-select :items="itemsLists.motifsInfraction" v-model="driverModel.risk.motifInfraction1" return-object
                item-text="libelle" item-value="code" label="Motif Infraction 1" outlined :loading="selectsLoading"
                clearable />

            </v-col>
            <v-col cols="3" sm="3">

              <v-select :items="itemsLists.sanctionsInfraction" v-model="driverModel.risk.sanctionInfraction1"
                return-object item-text="libelle" item-value="code" label="Sanction Infraction 1" outlined
                :loading="selectsLoading" clearable />

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <v-menu ref="menuInfractionDate2" v-model="menuInfractionDate2" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">

                  <v-text-field v-model="formatedInfractionDate2" label="Date infraction 2" v-mask="'##/##/####'"
                    outlined prepend-icon="calendar" clearable clear-icon="clear" @click:clear="infractionDate2 = ''"
                    v-bind="attrs" @blur="infractionDate2 = parseDate(formatedInfractionDate2)" v-on="on">
                  </v-text-field>

                </template>
                <v-date-picker v-model="infractionDate2" scrollable header-color="tertiary" color="tertiary_light"
                  first-day-of-week="1" no-title @input="menuInfractionDate2 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" sm="3">

              <v-select :items="itemsLists.motifsInfraction" v-model="driverModel.risk.motifInfraction2" return-object
                item-text="libelle" item-value="code" label="Motif Infraction 1" outlined :loading="selectsLoading"
                clearable />

            </v-col>
            <v-col cols="3" sm="3">

              <v-select :items="itemsLists.sanctionsInfraction" v-model="driverModel.risk.sanctionInfraction2"
                return-object item-text="libelle" item-value="code" label="Motif Infraction 1" outlined
                :loading="selectsLoading" clearable />

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3">
              <v-menu ref="menuInfractionDate3" v-model="menuInfractionDate3" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">

                  <v-text-field v-model="formatedInfractionDate3" label="Date infraction 3" v-mask="'##/##/####'"
                    outlined prepend-icon="calendar" clearable clear-icon="clear" @click:clear="infractionDate3 = ''"
                    v-bind="attrs" @blur="infractionDate3 = parseDate(formatedInfractionDate3)" v-on="on">
                  </v-text-field>

                </template>
                <v-date-picker v-model="infractionDate3" scrollable header-color="tertiary" color="tertiary_light"
                  first-day-of-week="1" no-title @input="menuInfractionDate3 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" sm="3">

              <v-select :items="itemsLists.motifsInfraction" v-model="driverModel.risk.motifInfraction3" return-object
                item-text="libelle" item-value="code" label="Motif Infraction 3" outlined :loading="selectsLoading"
                clearable />

            </v-col>
            <v-col cols="3" sm="3">

              <v-select :items="itemsLists.sanctionsInfraction" v-model="driverModel.risk.sanctionInfraction3"
                return-object item-text="libelle" item-value="code" label="Motif Infraction 3" outlined
                :loading="selectsLoading" clearable />

            </v-col>
          </v-row>


          <!-- ----- SINISTRES ET INFRACTIONS ----- -->
          <v-row>
            <v-col cols="4" sm="4">
              <h2>Sinistres et infractions :</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-data-table :headers="headers.claims" :items="driverModel.risk.claims" sort-by="" class="elevation-1"
                hide-default-footer>
                <template v-slot:item.occurenceDate="{ item }">
                  {{ formatDate(item.occurenceDate) }}
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-dialog v-model="addClaimDialog" max-width="500px" @click:outside="closeClaimDialog">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                          Ajouter un sinistre survenu dans les 3 dernières
                          années
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">{{ claimFormTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <ValidationObserver ref="observerClaim" v-slot="{ valid }" disabled>
                              <v-row>
                                <v-col cols="12">
                                  <v-menu ref="menuOccurrenceDate" v-model="menuOccurrenceDate"
                                    :close-on-content-click="false" transition="scale-transition" offset-y
                                    max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                      <ValidationProvider v-slot="{ errors }" name="date de survenance'"
                                        rules="required|occurenceDateRule">
                                        <v-text-field v-model="formatedOccurrenceDate" label="Date de survenance *"
                                          outlined prepend-icon="calendar" clearable clear-icon="clear"
                                          @click:clear="occurrenceDate = ''" v-bind="attrs" @blur="
        occurrenceDate = parseDate(
          formatedOccurrenceDate
        )
        " v-on="on" :error-messages="errors" v-mask="'##/##/####'"></v-text-field>
                                      </ValidationProvider>
                                    </template>
                                    <v-date-picker v-model="occurrenceDate" scrollable header-color="tertiary"
                                      color="tertiary_light" first-day-of-week="1" no-title
                                      @input="menuOccurrenceDate = false"></v-date-picker>
                                  </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-select :items="itemsLists.claimAutoNatures" v-model="claimModel.nature"
                                    return-object item-text="libelle" item-value="code" label="Nature" outlined
                                    :loading="selectsLoading" />
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <ValidationProvider v-slot="{ errors }" name="responsabilité" rules="required">
                                    <v-select :items="itemsLists.claimResponsabilities"
                                      v-model="claimModel.responsability" return-object item-text="libelle"
                                      item-value="code" label="Responsabilité *" outlined :error-messages="errors"
                                      :loading="selectsLoading" />
                                  </ValidationProvider>
                                </v-col>
                              </v-row>
                            </ValidationObserver>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeClaimDialog">
                            Annuler
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="addClaimConfirm">
                            Ajouter
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="deleteClaimDialog" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">Etes vous sûr de vouloir supprimer ce sinistre
                          ?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDeleteClaimDialog">Annuler</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteClaimConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.occurenceDate="{ item }">
                  {{ formatDate(item.occurenceDate) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editClaim(item)">
                    edit
                  </v-icon>
                  <v-icon small @click="deleteClaim(item)"> delete </v-icon>
                </template>
                <!--  <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize"> Reset </v-btn>
                    </template> -->
              </v-data-table>
            </v-col>
          </v-row>

          <!-- ----- AJOUTER CONDUCTEUR ----- -->
          <v-row>
            <v-spacer />
            <v-col cols="12">
              <v-btn block large color="button" class="button_color--text" @click="addDriver" type="submit">
                {{ driverButtonTitle }}
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-card>

    <v-card class="mt-10">
      <v-row>
        <v-col cols="3" sm="3">
          <h2>Liste des conducteurs</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="headers.drivers" :items="productRisk.drivers" class="elevation-1" hide-default-footer>
            <template v-slot:item.isSubscriber="{ item }">
              <v-chip v-if="item.isPrincipal == true || item.isPrincipal == 'true'" color="green" dark>
                Principal
              </v-chip>
              <v-chip v-else color="yellow" dark> Secondaire </v-chip>
            </template>
            <template v-slot:item.person.birthDate="{ item }">
              {{ formatDate(item.person.birthDate) }}
            </template>
            <template v-slot:item.person.driverLicence.effectDate="{ item }">
              {{ formatDate(item.person.driverLicence.effectDate) }}
            </template>
            <template v-slot:top>
              <v-dialog v-model="deleteDriverDialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Etes vous sûr de vouloir supprimer ce conducteur
                    ?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDeleteDriverDialog">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteDriverConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editDriver(item)">
                edit
              </v-icon>
              <v-icon v-show="item.isSubscriber == false || item.isSubscriber == 'false'" small
                @click="deleteDriver(item)">
                delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- ----- SUIVANT ----- -->
      <ValidationObserver ref="observerDrivers" v-slot="{ valid }">
        <v-row>
          <v-spacer />
          <v-col>
            <v-btn block large color="button" class="button_color--text" @click="nextStep"
              :disabled="isNextStepDisable">
              Suivant
            </v-btn>
            <!-- <ValidationProvider
              v-slot="{ errors }"
              rules="isTrue"
              name="Un conducteur principal est obligatoire"
            >
              <input v-model="isPrincipalDriverRegistred" hidden />
              <span>{{ errors[0] }}</span>
                <input
                v-model="isVehicleSelected"
                :error-messages="errors"
              /> 
            </ValidationProvider> -->
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card>
  </div>
</template>

<style scoped></style>

<script>
import axios from "axios";
import {
  required,
  integer,
  length,
  double,
  between,
  alpha_num,
  required_if,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapState, mapActions } from "vuex";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});
extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre",
});
extend("length", {
  ...length,
  message: "Le champ {_field_} doit contenir {length} chiffres",
});
extend("double", {
  ...double,
  message: "Le champ {_field_} doit être une valeur à {decimals} décimale ",
});
extend("between", {
  ...between,
  message: "Le champ {_field_} doit être compris entre {min} et {max}",
});
extend("alpha_num", {
  ...alpha_num,
  message: "Le champ {_field_} doit être alpha-numérique",
});
extend("required_if", {
  ...required_if,
  message: "",
});

extend("isTrue", {
  validate(value) {
    return value;
  },
  message: "{_field_}",
});
extend("btw18And77", {
  validate(value) {
    if (value != undefined) {
      var today = new Date();
      var parts = value.split("/");
      var birthDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18 && age <= 77;
    } else return false;
  },
  message: "L'age doit être compris entre 18 et 77 ans.",
});

extend("effectDateLicenceRule", {
  params: ["bDate"],
  validate(value, { bDate }) {
    if (value != undefined && bDate != undefined && bDate != null) {
      var parts = bDate.split("/");
      var birthDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var parts = value.split("/");
      var effectDateLicence = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var year = effectDateLicence.getFullYear() - birthDate.getFullYear();
      var m = effectDateLicence.getMonth() - birthDate.getMonth();
      // Gérer les jours Petites differences pour l instant (effectDateLicence.getDate() < birthDate.getDate())
      if (
        m < 0 ||
        (m === 0 && effectDateLicence.getDate() < birthDate.getDate())
      ) {
        year--;
        if (m < 0) m = 12 + m; // m is negative
      }

      return year > 15
        ? true
        /* : year == 16 && m >= 6
        ? true */
        : "La différence entre la date d'effet du permis et la date de naissance ne doit pas être inferieur à 17 ans et demi";
    } else return true;
  },
  /*  message: "La différence entre la date d'effet du permis et la date de naissance ne doit pas être inferieur à 17.5", */
});

extend("occurenceDateRule", {
  validate(value) {

    if (value != undefined) {
      var today = new Date();

      var parts = value.split("/");
      var occurrenceDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var year = today.getFullYear() - occurrenceDate.getFullYear();
      var m = today.getMonth() - occurrenceDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < occurrenceDate.getDate())) {
        year--;
        if (m < 0) m = 12 + m; // m is negative
      }


      if (year < 0)
        return "La date du sinistre ne peux être dans le futur";
      if (year > 3)
        return "La date de survenance ne doit pas excéder 3 ans et 3 mois";
      if (year == 3 && m > 3)
        return "La date de survenance ne doit pas excéder 3 ans et 3 mois";

      return true;

    }
  },
  /*  message: "La différence entre la date d'effet du permis et la date de naissance ne doit pas être inferieur à 17.5", */
});

export default {
  name: "MoralPersonDriversStep",
  components: { ValidationObserver, ValidationProvider },
  data: () => ({
    drivingTypes: [
      {
        code: "CONDACCOMP",
        libelle: "Conduite accompagné"
      }
    ],
    addClaimDialog: false,
    deleteClaimDialog: false,
    deleteDriverDialog: false,

    //A mettre dans un tableau
    menuBirthDate: false,
    formatedBirthDate: "",
    menuEffectDateLicence: false,
    formatedEffectDateLicence: "",
    menuOccurrenceDate: false,
    formatedOccurrenceDate: "",
    menuInfractionDate1: false,
    formatedInfractionDate1: "",
    menuInfractionDate2: false,
    formatedInfractionDate2: "",
    menuInfractionDate3: false,
    formatedInfractionDate3: "",

    //isNextStepDisable: true,
    editedDriverId: -1,
    driverModel: {
      person: {
        birthDate: "",
        driverLicence: {
          effectDate: "",
          drivingType: [],
          number: "",
        },
        personType: 'PHY'
      },
      isSubscriber: false,
      risk: {
        isSuscriberChild: false,
        canceledByInsurer: false,
        suspendedLicence: false,
        claims: [],
      },

    },
    editedClaimId: -1,
    claimModel: {
      occurrenceDate: "",
      nature: [],
      responsability: "",
    },
    selectsLoading: false,
    headers: {
      claims: [
        {
          text: "Date de survenance",
          align: "start",
          sortable: false,
          value: "occurrenceDate",
        },
        { text: "Nature", value: "nature.code" },
        { text: "Responsabilité", value: "responsability.code" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      drivers: [
        {
          text: "Civilité",
          align: "start",
          sortable: false,
          value: "person.civility.libelleCourt",
        },
        { text: "Nom", value: "person.lastname" },
        { text: "Prénom", value: "person.firstname" },
        { text: "Rôle", value: "isSubscriber" },
        { text: "Type de conduite", value: "person.driverLicence.drivingType.libelle" },
        { text: "Date de naissance", value: "person.birthDate" },
        { text: "CRM", value: "risk.crm" },
        {
          text: "Date d'effet du permis",
          value: "person.driverLicence.effectDate",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    },
    itemsLists: {},
  }),
  props: {
    productRisk: Object,
    subscriber: Object,
    personItemsLists: Object,
  },
  async created() {
    await this.getDriverItemsLists();
    console.log(this.productRisk.drivers);
    if (this.productRisk.drivers.length == 1 && this.productRisk.drivers[0].person.id == '-1') {
      this.productRisk.drivers = [];
      console.log(this.productRisk.drivers);
    } else {
      this.productRisk.drivers.forEach(driver => {
        if (driver.risk.suspendedLicence) {
          var result = this.itemsLists.suspendedLicenceReasons.filter(reason => {
            return reason.code == driver.risk.suspendedLicenceReason.code
          });
          driver.risk.suspendedLicenceReason = result[0];
        }
        if (driver.risk.canceledByInsurer) {
          var result = this.itemsLists.canceledByInsurerReasons.filter(reason => {
            return reason.code == driver.risk.canceledByInsurerReason.code
          });
          driver.risk.canceledByInsurerReason = result[0];
        }
      });
      console.log(this.productRisk.drivers);
    }
  },
  mounted() {

  },

  computed: {
    birthDate: {
      get: function () {
        return this.driverModel.person.birthDate;
      },
      set: function (v) {
        if (v != undefined) {
          this.driverModel.person.birthDate = v;
          //this.$set(this.driverModel.data, "birthDate", v);
        }
      },
    },
    infractionDate1: {
      get: function () {
        return this.driverModel.risk.infractionDate1;
      },
      set: function (v) {
        if (v != undefined) {
          this.driverModel.risk.infractionDate1 = v;
          this.formatedInfractionDate1 = this.formatDate(v);
          //this.$set(this.driverModel.data, "birthDate", v);
        } else {
          this.formatedInfractionDate1 = "";
        }
      },
    },
    infractionDate2: {
      get: function () {
        return this.driverModel.risk.infractionDate2;
      },
      set: function (v) {
        if (v != undefined) {
          this.driverModel.risk.infractionDate2 = v;
          this.formatedInfractionDate2 = this.formatDate(v);
          //this.$set(this.driverModel.data, "birthDate", v);
        } else {
          this.formatedInfractionDate2 = "";
        }
      },
    },
    infractionDate3: {
      get: function () {
        return this.driverModel.risk.infractionDate3;
      },
      set: function (v) {
        if (v != undefined) {
          this.driverModel.risk.infractionDate3 = v;
          this.formatedInfractionDate3 = this.formatDate(v);
          //this.$set(this.driverModel.data, "birthDate", v);
        } else {
          this.formatedInfractionDate3 = "";
        }
      },
    },
    birthDate: {
      get: function () {
        return this.driverModel.person.birthDate;
      },
      set: function (v) {
        if (v != undefined) {
          this.driverModel.person.birthDate = v;
          //this.$set(this.driverModel.data, "birthDate", v);
        }
      },
    },
    effectDateLicence: {
      get: function () {
        return this.driverModel.person.driverLicence.effectDate;
      },
      set: function (v) {
        if (v != undefined) {
          this.driverModel.person.driverLicence.effectDate = v;
          //this.$set(this.driverModel.data.driverLicence, "effectDate", v);
        }
      },
    },
    occurrenceDate: {
      get: function () {
        return this.claimModel.occurrenceDate;
      },
      set: function (v) {
        if (v != undefined) {
          this.claimModel.occurrenceDate = v;

          //this.$set(this.claimModel, "occurrenceDate", v);
        }
      },
    },
    claimFormTitle() {
      return this.editedClaimId === -1
        ? "Ajouter un sinistre"
        : "Modifier un sinistre";
    },
    isNextStepDisable() {
      console.log('compute next step')
      return (this.productRisk.drivers == 0 ? true : (this.editedDriverId == -1 ? false : true))
    },
    driverButtonTitle() {
      return this.editedDriverId === -1
        ? "Ajouter le conducteur au contrat"
        : "Modifier le conducteur";
    },
  },
  methods: {
    //...mapActions("estimation", ["getDriverItemsLists"]),
    isSubscriberChange() {
      if (!this.driverModel.isSubscriber) {
        this.driverModel.person = {
          birthDate: "",
          driverLicence: {
            effectDate: "",
            drivingType: [],
            number: "",
          },
          personType: 'PHY'
        };
      } else {
        console.log(this.subscriber);
        this.driverModel.person = this.subscriber
      }
    },
    async getDriverItemsLists() {

      const insurancedPeriodsReq = axios.get("/api/listItems/insurancedPeriods");
      const drivingTypesReq = axios.get("/api/listItems/drivingTypes");
      const suspendedLicenceReasonsReq = axios.get("/api/listItems/suspendedLicenceReasons");
      const motifsInfractionReq = axios.get("/api/listItems/MOTIF_INFRACTION");
      const sanctionsInfractionReq = axios.get("/api/listItems/SANCTION_INFRACTION");
      const canceledByInsurerReasonsReq = axios.get("/api/listItems/canceledByInsurerReasons");
      const claimResponsabilitiesReq = axios.get("/api/listItems/claimResponsabilities");
      const claimAutoNaturesReq = axios.get("/api/listItems/claimAutoNatures");

      try {
        this.selectsLoading = true;
        const responses = await axios
          .all([
            insurancedPeriodsReq,
            drivingTypesReq,
            suspendedLicenceReasonsReq,
            canceledByInsurerReasonsReq,
            claimResponsabilitiesReq,
            claimAutoNaturesReq,
            motifsInfractionReq,
            sanctionsInfractionReq
          ]);

        this.itemsLists['insurancedPeriods'] = responses[0].data.listItems,
          this.itemsLists['drivingTypes'] = responses[1].data.listItems,
          this.itemsLists['suspendedLicenceReasons'] = responses[2].data.listItems,
          this.itemsLists['canceledByInsurerReasons'] = responses[3].data.listItems,
          this.itemsLists['claimResponsabilities'] = responses[4].data.listItems,
          this.itemsLists['claimAutoNatures'] = responses[5].data.listItems,
          this.itemsLists['motifsInfraction'] = responses[6].data.listItems,
          this.itemsLists['sanctionsInfraction'] = responses[7].data.listItems,
          this.selectsLoading = false;
      } catch (error) {

        this.handleAxiosError(error);
        this.selectsLoading = false;
      }
    },

    addClaimConfirm() {
      this.$refs["observerClaim"].validate().then((valid) => {
        if (valid) {
          if (this.editedClaimId > -1) {
            Object.assign(
              this.driverModel.risk.claims[this.editedClaimId],
              this.claimModel
            );
          } else {
            this.driverModel.risk.claims.push(this.claimModel);
          }
          this.closeClaimDialog();
        }
      });
    },
    closeClaimDialog() {
      this.addClaimDialog = false;

      this.$refs["observerClaim"].reset();
      this.$nextTick(() => {
        this.claimModel = {};
        this.pickerOccurrenceDate = "";
        this.editedClaimId = -1;
      });
    },
    // Interdit nextStep si l'array drivers est vide && etat du formulaire == modification
    /*     isNextStepDisable() {console.log('this.risk.drivers.length == 0 ' + (this.risk.drivers.length == 0 ));console.log('isNextDisabled ' + (this.risk.drivers.length == 0 ? true : (this.editedDriverId == -1 ? false : true))); return (this.risk.drivers.length == 0 ? true : (this.editedDriverId == -1 ? false : true))},
     */ deleteClaimConfirm() {
      this.driverModel.risk.claims.splice(this.editedClaimId, 1);
      this.closeDeleteClaimDialog();
    },
    closeDeleteClaimDialog() {
      this.deleteClaimDialog = false;
      this.$nextTick(() => {
        this.claimModel = {};
        this.formatedOccurrenceDate = "";
        this.editedClaimId = -1;
      });
    },
    editClaim(item) {
      this.editedClaimId = this.driverModel.risk.claims.indexOf(item);
      this.claimModel = Object.assign({}, item);
      this.addClaimDialog = true;
    },
    deleteClaim(item) {
      this.editedClaimId = this.driverModel.risk.claims.indexOf(item);
      this.claimModel = Object.assign({}, item);
      this.deleteClaimDialog = true;
    },

    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', { timeZone: 'Pacific/Noumea' });
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    nextStep() {
      this.$refs["observerDrivers"].validate().then((valid) => {
        if (valid) {
          window.scrollTo(0, 0);
          /* console.log('valid -> next') */ this.$store.commit(
            "estimation/nextEstimationStep"
          );
        }
      });
    },

    addDriver() {
      this.$refs["observerDriver"].validate().then((valid) => {
        if (valid) {
          if (this.editedDriverId > -1) {
            console.log(this.productRisk.drivers[this.editedDriverId], this.driverModel);
            Object.assign(this.productRisk.drivers[this.editedDriverId], this.driverModel);
          } else {
            if (this.driverModel.isSubscriber) {
              this.driverModel.person = this.subscriber
            }
            if (this.productRisk.drivers.length == 0) {
              this.driverModel.isPrincipal = true;
            }
            this.productRisk.drivers.push(Object.assign({}, this.driverModel));
            console.log("this.productRisk.drivers", this.productRisk.drivers);
          }
          this.resetDriverForm();
        }
      });
    },
    editDriver(item) {
      this.editedDriverId = this.productRisk.drivers.indexOf(item);
      this.driverModel = Object.assign(
        {},
        this.productRisk.drivers[this.editedDriverId]
      ); /* JSON.parse(JSON.stringify(this.risk.drivers[this.editedDriverId])); */
      //this.isNextStepDisable = true;
      this.formatedInfractionDate1 = this.productRisk.drivers[this.editedDriverId].risk.infractionDate1 ? this.formatDate(this.productRisk.drivers[this.editedDriverId].risk.infractionDate1) : "";
      this.formatedInfractionDate2 = this.productRisk.drivers[this.editedDriverId].risk.infractionDate2 ? this.formatDate(this.productRisk.drivers[this.editedDriverId].risk.infractionDate2) : "";;
      this.formatedInfractionDate3 = this.productRisk.drivers[this.editedDriverId].risk.infractionDate3 ? this.formatDate(this.productRisk.drivers[this.editedDriverId].risk.infractionDate3) : "";;
    },
    deleteDriver(item) {
      this.editedDriverId = this.productRisk.drivers.indexOf(item);
      this.driverModel = JSON.parse(JSON.stringify(item));
      this.deleteDriverDialog = true;
    },
    deleteDriverConfirm() {
      //this.$store.commit("estimation/rmvDriver", this.editedDriverId);
      this.productRisk.drivers.splice(this.editedDriverId, 1)
      this.resetDriverForm();
      this.closeDeleteDriverDialog();
    },
    closeDeleteDriverDialog() {
      this.deleteDriverDialog = false;
    },
    resetDriverForm() {
      this.$nextTick(() => {
        this.driverModel = {
          person: {
            birthDate: "",
            driverLicence: {
              effectDate: "",
              drivingType: [],
              number: "",
            },
            personType: 'PHY'
          },
          isSubscriber: false,
          isPrincipal: this.productRisk.drivers.length == 0 ? true : false,
          risk: {
            isSuscriberChild: false,
            canceledByInsurer: false,
            suspendedLicence: false,
            claims: [],
          },
        };

        this.formatedBirthDate = "";
        this.formatedEffectDateLicence = "";
        this.formatedOccurrenceDate = "";
        this.formatedInfractionDate1 = "";
        this.formatedInfractionDate2 = "";
        this.formatedInfractionDate3 = "";
        console.log("reset editedDriverId :" + this.editedDriverId);
        this.editedDriverId = -1;
        console.log("reset editedDriverId (expect -1):" + this.editedDriverId);

        // Reset form errors
        requestAnimationFrame(() => {
          this.$refs.observerDriver.reset();
        });
      });
    },
  },
  watch: {
    formatedInfractionDate1(val) {
      if (val == "") {
        delete this.driverModel.risk.infractionDate1;
        this.driverModel.risk.motifInfraction1 = "";
        this.driverModel.risk.sanctionInfraction1 = "";
      }
    },
    birthDate(val) {
      this.formatedBirthDate = this.formatDate(val);
    },
    effectDateLicence(val) {
      this.formatedEffectDateLicence = this.formatDate(val);
    },
    occurrenceDate(val) {
      this.formatedOccurrenceDate = this.formatDate(val);
    },

    "driverModel.risk.crm": {
      handler(val, oldVal) {
        console.log(parseFloat(val).toFixed(2) != '0.50')
        console.log(parseFloat(val).toFixed(2))
        if (parseFloat(val).toFixed(2) != '0.50')
          this.driverModel.risk.minCRMDuration = "";
      },
      deep: true
    },
    "driverModel.risk.canceledByInsurer": function (val, oldVal) {
      if (!val && this.driverModel.risk.canceledByInsurerReason != null)
        delete this.driverModel.risk.canceledByInsurerReason;
    },
    "driverModel.risk.suspendedLicence": function (val, oldVal) {
      if (!val && this.driverModel.risk.suspendedLicenceReason != null)
        this.driverModel.risk.suspendedLicenceReason = [];
    },
    editedDriverId: (v) => console.log(v),
    subscriber: {
      handler(val, oldVal) {
        // Retourne un tableau contenant l'entré du conducteur principal,
        // si existant dans la liste des conducteurs. ( ! Conducteur principal = Souscripteur ! )
        var filtered = this.productRisk.drivers.filter(function (value) {
          return value.isSubscriber == true;
        });
        // Si le conducteur principal existe
        if (filtered.length > 0) {
          // Recupère son index
          var editedDriverId = this.productRisk.drivers.indexOf(filtered[0]);
          // Remplace les infos personnel du conducteur principal au niveau du risk,
          // par les nouvelles informations du souscripteur.
          /* console.log('change') */
          this.productRisk.drivers[this.editedDriverId][person] = Object.assign({}, val);
          /* this.$store.commit({
            type: "estimation/updDriverDetails",
            editedDriverId: editedDriverId,
            key: "data",
            value: Object.assign({}, val),
          }); */

          // Si le conducteur edité actuellement est le conducteur principal (AKA le souscripteur ici)
          if (this.editedDriverId == editedDriverId) {
            this.$set(this.driverModel, "person", Object.assign({}, val));
            if (!val.driverLicence)
              this.$set(this.driverModel.person, "driverLicence", {
                effectDate: "",
                drivingType: [],
                number: "",
              });
          }
          /*  this.$set(this.risk.drivers[editedDriverId], "data", Object.assign({}, val)) */

          // Cas par défaut à la création
          // Hydrate le formulaire du conducteur avec les infos personels du souscripteur

        } else {
          this.$set(this.driverModel, "person", Object.assign({}, val));
          this.$set(this.driverModel, "isSubscriber", true);
          // Ajoute les infos permis si inexistante
          if (!val.driverLicence)
            this.$set(this.driverModel.person, "driverLicence", {
              effectDate: "",
              drivingType: [],
              number: "",
            });
          /* console.log('subscriber -> driverModel :')
          console.log(this.driverModel) */
        }
      },
      deep: true,
    },
  },
};
</script>
