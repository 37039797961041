import axios from "axios";

const API_URL = "/api/admin/users";

class UserService {
  async create(user) {
    const res = await axios({
      method: "post",
      url: API_URL,
      data: user,
    });
    return res.data;
  }

  async get(idUser) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idUser,
    });
    return res.data;
  }

  async list(page, nbItemsPerPage, sortOption, search) {
    const res = await axios({
      method: "get",
      url: API_URL,
      params: { page, nbItemsPerPage, sortOption, search },
    });
    return res.data;
  }

  async remove(idUser) {
    const res = await axios({
      method: "delete",
      url: API_URL + "/" + idUser,
    });
    return res.data;
  }

  async update(idUser, user) {
    const res = await axios({
      method: "put",
      url: API_URL + "/" + idUser,
      data: user,
    });
    return res.data;
  }
}

export default new UserService();
