import axios from "axios";

const API_URL = "/api/profile";

class AccountService {
  async getMyProfile() {
    let res = await axios({
      method: "get",
      url: API_URL,
    });
    return res.data;
  }

  async updateMyProfile(user) {
    let res = await axios({
      method: "put",
      url: API_URL,
      data: user,
    });
    return res.data;
  }

  async updateMyPassword(password, confirmedPassword) {
    let res = await axios({
      method: "put",
      url: API_URL + "/password",
      data: { password, confirmedPassword },
    });
    return res.data;
  }
}

export default new AccountService();
