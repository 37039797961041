var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-3 row-pointer",attrs:{"headers":this.headers,"items":this.data,"item-key":"Effet","sort-by":this.options.sortBy,"sort-desc":this.options.sortDesc,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [20, 50, 100],
    },"search":_vm.search},scopedSlots:_vm._u([{key:"item.dueDateBrut",fn:function(ref){
    var item = ref.item;
return [_c('v-col',[_vm._v(_vm._s(item.dueDate))])]}},{key:"item.subject",fn:function(ref){
    var item = ref.item;
return [(item.comment)?_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.subject))])]}}],null,true)},[_c('span',{staticClass:"comment"},[_vm._v(_vm._s(item.comment))])])],1):_c('v-col',[_vm._v(_vm._s(item.subject))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }