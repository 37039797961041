<template>

  <div>

    <v-form
      v-model="valid"
      @submit.prevent="getDataFromApi"
    >

    <v-row>
      <v-col cols="12" sm="3">

        <v-text-field
          v-model="lastName"
          label="Nom"
          outlined
          hide-details
          clearable
          clear-icon="clear"
          :disabled="!!companyName"
        >
        </v-text-field>

      </v-col>

      <v-col cols="12" sm="3">

        <v-text-field
          v-model="firstName"
          label="Prénom"
          outlined
          hide-details
          clearable
          clear-icon="clear"
          :disabled="!!companyName"
        >
        </v-text-field>

      </v-col>

       <v-col cols="12" sm="3">

        <v-text-field
          v-model="companyName"
          label="Raison sociale"
          outlined
          hide-details
          clearable
          clear-icon="clear"
          :disabled="!!firstName || !!lastName || !!pickerBirthDate"
        >
        </v-text-field>

      </v-col>

       <v-col cols="12" sm="3">

        <v-dialog
          ref="dialogBirthDate"
          v-model="modalBirthDate"
          :return-value.sync="pickerBirthDate"
          width="290px"
           :disabled="!!companyName"
        >

        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(pickerBirthDate)"
            label="Date de naissance "
            readonly
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
            clearable
            clear-icon="clear"
            @click:clear="pickerBirthDate = ''"
            :disabled="!!companyName"
            ></v-text-field>
        </template>

        <v-date-picker
         v-model="pickerBirthDate"
         scrollable
         header-color="tertiary"
         color="tertiary_light"
         first-day-of-week="1"
          :disabled="!!companyName"
        >

          <v-spacer></v-spacer>

          <v-btn
            text
            @click="modalBirthDate = false"

          >
            Annuler
          </v-btn>
          <v-btn
            text
            @click="$refs.dialogBirthDate.save(pickerBirthDate)"
          >
            OK
          </v-btn>

        </v-date-picker>

        </v-dialog>

      </v-col>

    </v-row>

    <v-row>

       <v-col cols="12" sm="3">

        <v-text-field
          v-model="city"
          label="Ville"
          outlined
          hide-details
          clearable
          clear-icon="clear"
        >
        </v-text-field>

      </v-col>

       <v-col cols="12" sm="3">

        <v-text-field
          v-model="zipCode"
          label="Code Postal"
          outlined
          hide-details
          clearable
          clear-icon="clear"
        >
        </v-text-field>

      </v-col>

      <v-col cols="12" sm="3">
        <v-row>
          <v-col>
            <v-checkbox
              v-model="hasContract"
              hide-details
              :disabled="hasInvoice == 1"
            >
              <template v-slot:label>
                <div>
                  Client avec contrat
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="hasInvoice"
              hide-details
              :disabled="hasContract == 1"
            >
              <template v-slot:label>
                <div>
                  Client avec devis
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="3">

        <v-btn block large color="button" class="button_color--text" type="submit">
          <v-icon left>search</v-icon>Rechercher
        </v-btn>

      </v-col>

        <v-col cols="6" sm="3"><h1>{{total}} résultat(s)</h1></v-col>

    </v-row>

    </v-form>

    <v-row>

      <v-col class="pt-10">

        <v-skeleton-loader
          v-if="firstLoad"
          :loading="loading"
          type="table-thead, table-tbody, table-tfoot">
        </v-skeleton-loader>

        <v-data-table
          v-show="!firstLoad"
          :headers="clients.headers"
          :items="clients.data"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          item-key="id"
          @click:row="rowClick"


          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions:itemsPerPageOptions,
          }"
          class="elevation-3 row-pointer"
        >
        <template v-slot:item.phoneNumber="{ item }">
            <span>{{ item.phoneNumber !== "" ? item.phoneNumber.match(/.{1,2}/g).join(' ') : item.dateEffet }}</span>
          </template>
          </v-data-table>
      </v-col>

    </v-row>

  </div>

</template>

<style scoped>
::v-deep tbody tr :hover { cursor: pointer; }
</style>

<script>
import axios from "axios";
import { integer } from 'vee-validate/dist/rules';
export default {
  name: "ClientSearch",
  data: () => ({
    // filter results, only for non server side data injection
    // filter: '',

    valid: true,


    modalBirthDate: false,
    pickerBirthDate: '',
    lastName: '',
    firstName: '',
    companyName: '',
    city: '',
    zipCode: '',
    hasContract: false,
    hasInvoice: false,

    total: 0,

    clients: {
      data : [],
      headers: [],
    },

    firstLoad: true,
    loading: true,

    options: {},

    firstPage: 0,
    sortBy: 'dateEffet',
    sortDesc: true,


    /* Manage default fields values to post */
    sortField: 'id',
    sortOrder: 'DESCENDING',
  }),
  props: {
      isEstimate: {
        type: Boolean,
        default: false,
      },
      isModal: {
        type: Boolean,
        default: false,
      },
      personTypeToSearch: ''
  },
  created () {
  },
  mounted () {
  },
  computed: {
    itemsPerPageOptions:  function() {
      if(this.isEstimate)
        return [5,20,100];
      else
        return [20,50,100];
    },
  },
  methods: {
    formatDate(date) {
      if (date === '') return null
      const [year, month, day] = date.split('-')
      const d = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
      return d;
    },
    goToClient(row) {
      this.$router.push('/client/search/'+row.id)
    },
    async getDataFromApi() {
      this.loading = true
      this.clientSearchPromise().then(data => {
        this.clients = data.items
        this.total = data.total
      }).finally(()=> {
        if (this.firstLoad) this.firstLoad = false
        this.loading = false});
    },
    clientSearchPromise() {

      return new Promise((resolve, reject) => {

        const { sortBy, sortDesc, page, itemsPerPage } = this.options

// console.log('page = '+page+' itemsPerPage = '+itemsPerPage+' sortBy = '+sortBy+' sortDesc ='+sortDesc)

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
            this.firstPage = '0'
        } else {
            this.firstPage = (page - 1) * itemsPerPage +''
        }

        /* Manage order by value to post for okayo request */
        if (sortDesc[0]) {
            this.sortOrder = 'ASCENDING'
        } else {
            this.sortOrder = 'DESCENDING'
        }

        /* Manage sort by value to post for okayo request */
        if (sortBy[0]) {
            this.sortField = sortBy[0]
        } else {
            this.sortField = this.sortField
        }

        /* Change date format to AAAA-MM-DD for okayo request */
        if ( this.pickerBirthDate ) {
          this.birthDate = new Date(this.pickerBirthDate).toISOString().slice(0,10)
        } else {
          this.birthDate = ""
        }



        axios.post('/api/client/search', {

            pageSize: itemsPerPage + '',//limit

            firstPage: this.firstPage,//offset
            // pageSize: 1000,//limit
            // firstPage: 0,//offset
            sortField: this.sortField,
            sortOrder: this.sortOrder,

            birthDate: this.birthDate,

            lastname: this.lastName,
            firstname: this.firstName,
            companyName: this.companyName,
            city: this.city,
            zipCode: this.zipCode,
            hasContract: this.hasContract +'',
            hasInvoice: this.hasInvoice + '',
            personType: this.personTypeToSearch,

        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data)

          let items = response.data.clients
          const total = parseInt(response.data.totalCount, 10);//string to int mandatory

          // if (sortBy.length === 1 && sortDesc.length === 1) {
          //   items = items.sort((a, b) => {
          //     const sortA = a[sortBy[0]]
          //     const sortB = b[sortBy[0]]
          //
          //     if (sortDesc[0]) {
          //       if (sortA < sortB) return 1
          //       if (sortA > sortB) return -1
          //       return 0
          //     } else {
          //       if (sortA < sortB) return -1
          //       if (sortA > sortB) return 1
          //       return 0
          //     }
          //   })
          // }

          // if (itemsPerPage > 0) {
          //   items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
          // }

          resolve({
            items,
            total,
          })

        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error')
            console.log('Error', error.message)
          }
          console.log('error.config')
          console.log(error.config)

        });

      })
    },
    rowClick(row) {
      if(this.isEstimate) {
        this.$emit("update-client", row.id);
      } else if(this.isModal) {
        this.$emit("retrieveClient", row);
      } else
        this.goToClient(row);
    },
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi()
      },
      deep: true,
    },
  }
};
</script>
