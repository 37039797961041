<template lang="pug">
  v-app
    AppBar(@toggle-drawer='$refs.drawer.drawer = !$refs.drawer.drawer')
    AdminMenu(ref='drawer')
    v-main(app='')
      .white_background_2
        keep-alive(max='5')
          router-view(v-if='$route.meta.keepAlive')
        v-fade-transition(mode='out-in')
          router-view(v-if='!$route.meta.keepAlive')
    ScrollTop
</template>

<style></style>

<script>
import AppBar from "./common/AppBar";
import ScrollTop from "./common/ScrollTop";
import AdminMenu from "@/components/layout/common/AdminMenu";

export default {
  name: "AdminLayout",
  components: {
    AdminMenu,
    AppBar,
    ScrollTop,
  },
};
</script>
