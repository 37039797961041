<template lang="pug">
  v-snackbar(v-model='showMe' :color='message.type' :multi-line='true' timeout='3000') {{ message.message }}
    template(v-slot:action='{ attrs }')
      v-btn(color='button_color--text' text v-bind='attrs' @click='showMe = false') Fermer
</template>

<script>
export default {
  name: "SnackBar",
  props: ["show", "message"],
  data: () => ({
    showMe: false,
  }),
  mounted() {
    this.showMe = this.$props.show;
  },
  watch: {
    show: function (newVal) {
      // watch it
      this.showMe = newVal;
    },
  },
};
</script>
