var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('PrincipalAssured',{attrs:{"mainInsured":_vm.mainInsured,"itemsLists":_vm.itemsListsClient}}),_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('h2',[_vm._v("Logement :")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"addresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Adresse","outlined":"","hide-details":"","error-messages":errors,"clearable":"","clear-icon":"clear","required":"","rules":[function (v) { return !!v; }]},model:{value:(_vm.mainInsuredRisk.homeAddress.adress1),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk.homeAddress, "adress1", $$v)},expression:"mainInsuredRisk.homeAddress.adress1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"Code postal","rules":"required|integer|length:5","vid":"zipCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"CP *","outlined":"","error-messages":errors,"clearable":"","clear-icon":"clear"},model:{value:(_vm.mainInsuredRisk.homeAddress.zipCode),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk.homeAddress, "zipCode", $$v)},expression:"mainInsuredRisk.homeAddress.zipCode"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"name":"ville","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists['homeAddressCities'],"item-text":"libelle","item-value":"libelle","label":"Ville *","outlined":"","loading":_vm.citiesLoader,"disabled":_vm.itemsLists['homeAddressCities'] == 0,"error-messages":_vm.itemsLists['homeAddressCities'] == 0?'':errors},model:{value:(_vm.mainInsuredRisk.homeAddress.city),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk.homeAddress, "city", $$v)},expression:"mainInsuredRisk.homeAddress.city"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsListsClient['countries'],"return-object":"","item-text":"libelle","item-value":"code","label":"Pays","error-messages":errors,"outlined":"","required":"","rules":[function (v) { return !!v; }, function (v) { return v.length > 0 || Object.keys(v).length > 0; }]},model:{value:(_vm.mainInsuredRisk.homeAddress.country),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk.homeAddress, "country", $$v)},expression:"mainInsuredRisk.homeAddress.country"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"homeType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists['homeType'],"item-text":"libelle","item-value":"code","return-object":"","label":"Type d'habitation *","outlined":"","required":"","error-messages":errors,"loading":_vm.mrhListsLoader},model:{value:(_vm.mainInsuredRisk.homeType),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk, "homeType", $$v)},expression:"mainInsuredRisk.homeType"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"clientHomeStatus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists['clientHomeStatus'],"item-text":"libelle","item-value":"code","return-object":"","label":"Status souscripteur *","outlined":"","required":"","error-messages":errors,"loading":_vm.mrhListsLoader},model:{value:(_vm.mainInsuredRisk.clientHomeStatus),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk, "clientHomeStatus", $$v)},expression:"mainInsuredRisk.clientHomeStatus"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"nombre de pièces","rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de pièces *","outlined":"","error-messages":errors,"clearable":"","clear-icon":"clear"},model:{value:(_vm.mainInsuredRisk.roomsNumber),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk, "roomsNumber", $$v)},expression:"mainInsuredRisk.roomsNumber"}})]}}])})],1),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"nombre de pièces 30","rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre de pièces > 30 m2 *","outlined":"","error-messages":errors,"clearable":"","clear-icon":"clear"},model:{value:(_vm.mainInsuredRisk.moreThan30MSquareRoomsNb),callback:function ($$v) {_vm.$set(_vm.mainInsuredRisk, "moreThan30MSquareRoomsNb", $$v)},expression:"mainInsuredRisk.moreThan30MSquareRoomsNb"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',[_c('v-btn',{staticClass:"button_color--text",attrs:{"block":"","large":"","color":"button"},on:{"click":_vm.nextStep}},[_vm._v("Suivant")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }