<template>
  <v-card>
    <v-card-title>
      Franchises
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col>
            <ValidationProvider v-slot="{ errors }" rules="required" name="Modulation de franchise BDG">
              <v-select :items="itemsLists.franchiseModulationBDG" item-text="libelle" item-value="code" :loading="loading"  :disabled="loading || isTransformation"
                v-model="options.franchiseModulationBDG" label="Modulation de franchise BDG" return-object outlined
                required :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-text-field label="Franchise VOLI" :value="vehicule.VOLI" disabled outlined
                clear-icon="clear"  />
            <ValidationProvider v-slot="{ errors }" rules="required" name="Franchise modulation VOLI">
              <v-select :items="itemsLists.franchiseModulationVOLI" item-text="libelle" item-value="code"           :loading="loading" :disabled="loading || isTransformation"
                v-model="options.franchiseModulationVOLI" label="Modulation de franchise VOLI" return-object outlined
                required :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-text-field  label="Franchise DTA" :value="vehicule.DTA" disabled outlined
                clear-icon="clear" />
            <ValidationProvider v-slot="{ errors }" rules="required" name="Franchise Modulation DTA">
              <v-select :items="itemsLists.franchiseModulationDTA" item-text="libelle" item-value="code" :loading="loading" :disabled="loading || isTransformation"
                v-model="options.franchiseModulationDTA" label="Modulation de Franchise DTA" return-object outlined
                required :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped></style>

<script>

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState, mapActions } from "vuex";
import Vue from 'vue';
import axios from "axios";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});


export default {
  name: "Franchises",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ["isTransformation"],
  data: () => ({
    loading: true,
    franchiseVOLI: null,
    franchiseDTA: null,
    franchiseModulationDTA: null,
    modulationFranchiseBDG: null,
    franchiseModulationVOLI: null,
    itemsLists: {
      franchiseModulationDTA: [],
      franchiseModulationVOLI: [],
      franchiseModulationBDG: []
    },
  }),
  computed: {
    ...mapState({
      options: (state) => {
        if(state.transformation) {
          return state.transformation.estimate.productRisk.options;
        }
        return state.estimation.estimate.productRisk.options ?? {}
      },
      vehicule: (state) => {
        if(state.transformation) {
          return state.transformation.estimate.productRisk.vehicle ?? {};
        }
        return state.estimation.estimate.productRisk.vehicle ?? {}
      },
    })
  },
  methods: {},
  async mounted() {
    try {
      const responses = await axios
        .all([
          axios.get("/api/listItems/MODUL_FRANC_VOLI_DTA"),
          axios.get("/api/listItems/MODUL_FRANC_VOLI_DTA"),
          axios.get("/api/listItems/MODUL_FRANCH_BDG")
        ]);
      this.itemsLists.franchiseModulationDTA = responses[0].data.listItems;
      this.itemsLists.franchiseModulationVOLI = responses[1].data.listItems;
      this.itemsLists.franchiseModulationBDG = responses[2].data.listItems;

      this.options.franchiseVOLI = 30000;
      this.options.franchiseDTA = 30000;
    } catch (error) {
      this.handleAxiosError(error);
    } finally {
      this.loading = false;
    }
  }
};
</script>
