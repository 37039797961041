import axios from "axios";

const API_URL = "/api/admin/roles";

class RoleService {
  async create(role) {
    const res = await axios({
      method: "post",
      url: API_URL,
      data: role,
    });
    return res.data;
  }

  async get(idRole) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idRole,
    });
    return res.data;
  }

  async list(page, nbItemsPerPage, sortOption, search) {
    const res = await axios({
      method: "get",
      url: API_URL,
      params: { page, nbItemsPerPage, sortOption, search },
    });
    return res.data;
  }

  async remove(idRole) {
    const res = await axios({
      method: "delete",
      url: API_URL + "/" + idRole,
    });
    return res.data;
  }

  async update(idRole, role) {
    const res = await axios({
      method: "put",
      url: API_URL + "/" + idRole,
      data: role,
    });
    return res.data;
  }
  async listUsers(idRole) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idRole + "/" + "users",
    });
    return res.data;
  }

  async addUser(idRole, idUser) {
    const res = await axios({
      method: "post",
      url: API_URL + "/" + idRole + "/" + "users",
      data: { idUser },
    });
    return res.data;
  }

  async removeUser(idRole, idUser) {
    const res = await axios({
      method: "delete",
      url: API_URL + "/" + idRole + "/" + "users",
      data: { idUser },
    });
    return res.data;
  }
}

export default new RoleService();
