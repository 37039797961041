<template>
  <div>
    <!-- CHANGER LA CONDITION SUR LES EDITICS -->
    <ValidationObserver ref="autoOptsDisclaimer" v-slot="{ valid }">
      <v-form @submit.prevent="estimateHandler" style="width: 100%">
        <v-row>
          <v-col cols="3" sm="3">
            <v-menu ref="menuEffectDate" v-model="menuEffectDate" :close-on-content-click="false"
              transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider v-slot="{ errors }" rules="required|effectDateRule" name="date d'effet">
                  <v-text-field v-model="formatedEffectDate" label="Date d'effet" outlined :error-messages="errors"
                    prepend-icon="calendar" clearable clear-icon="clear" @click:clear="effectDate = ''" v-bind="attrs"
                    @blur="effectDate = parseDate(formatedEffectDate)" v-on="on" required readonly
                    :disabled="loading"></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker :min="estimate.currentDate"  v-model="effectDate" scrollable header-color="tertiary" color="tertiary_light"
                first-day-of-week="1" no-title @input="menuEffectDate = false"></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col >
                <v-icon>
                  add
                </v-icon>
            </v-col>-->
          <v-col cols="3" sm="3" style="color: grey">
            La date d'effet doit être comprise dans les 35 prochains jours à
            partir de ce jour.
          </v-col>
          <!-- <v-spacer /> -->
          <v-col cols="3" sm="3">
            <ValidationProvider v-slot="{ errors }" rules="required" name="Facilité de paiement">
              <v-select :loading="loading" :disabled="loading" :items="listFractionnements"
                item-text="libelle" item-value="code" v-model="estimate.periodicite" label="Facilité de paiement"
                outlined required :error-messages="errors" disabled/>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="estimate.product.code == $codeAuto" cols="12">
            <v-card>
              <v-card-title>Vos options :</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-checkbox :true-value="true" :false-value="false"
                      :input-value="estimate.productRisk.options.conduiteExclusive" hide-details disabled
                      color='button_light'>
                      <template v-slot:label>
                        <div>Conduite exclusive</div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <OptionalGuaranteesEligible ref="OptionalGuaranteesEligibleForm" isTransformation />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="pb-3">
                      <Franchises isTransformation />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="estimate.product.code == $codeMrhBudget">
            <OptsMrh />
          </v-col>

          <v-col cols="4" offset="8">
            <v-btn block large color="button" class="button_color--text" type="submit" :disabled="loading">
              <v-icon left>search</v-icon>
              Calculer
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Selectionner votre formule et votre fractionnement</v-card-title>
          <v-card-text>
            <v-skeleton-loader v-show="loading && Object.keys(estimation).length == 0" :loading="true"
              type="table-thead, table-tbody"></v-skeleton-loader>
            <v-data-table v-show="Object.keys(estimation).length > 0" :headers="estimation['headers']"
              :items="estimation['data']" hide-default-footer :loading="loading">
              <template v-slot:body="{ items }">
                <tr v-for="row in items" :key="row.splitting">
                  <td v-for="(cellData, index) in row" :key="index">
                    <v-radio-group v-if="index != 'splitting' && !index.includes('info_')" v-model="formulaSelection">
                      <v-radio :value="row.splitting + '_' + index" :disabled="true">
                        <template v-slot:label>
                          <div>
                            <h4>{{ cellData }}</h4>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <h3 v-else>{{ cellData }}</h3>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text>
            <p>
              Pour les fractionnements semestriels, trimestriels et mensuels,
              les frais seront appliqués sur le comptant. Les montants affichés
              dans le tableaux ci-dessus pour ces fractionnements sont les
              échéances secondaires.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Vos garanties et options incluses:
            {{ this.formulaSelection.replace("_", " - ") }}
          </v-card-title>
          <v-row>
            <v-col>
              <!-- GLA_ENF-6 -->
              <v-simple-table v-if="Object.keys(estimation).length > 0 &&
                estimation['formulas'][formulaSelection] != undefined
                ">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Garantie</th>
                      <th class="text-right">TTC</th>
                      <th class="text-right">Franchise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(guarantee, i) in estimation['formulas'][
                      formulaSelection
                    ].guarantees.filter(function (g) {
                      return g.isSuscribed
                    })" :key="i">
                      <td>{{ guarantee.libelle }}</td>
                      <td class="text-right">{{ guarantee.amount }} {{ $currency }}</td>
                      <td class="text-right" v-if="guarantee.franchiseValue > 0">
                        {{ guarantee.franchiseValue }}
                        {{ guarantee.franchiseCurrencylabel }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.v-slider__thumb-label,
.v- -label-container {
  position: fixed;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<script>
import axios from "axios";
import EditicsDataTable from "../../components/EditicsDataTable.vue";
import OptsMrh from "./OptsMrh.vue";
import { mapState, mapActions } from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

import Vue from 'vue';
import Franchises from "@/components/estimation/Franchises.vue";
import Fractionnement from "@/components/estimation/Fractionnement.vue";
import OptionalGuaranteesEligible from "@/components/estimation/OptionalGuaranteesEligible.vue";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone' ;
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);


setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});

const customEffectDateRule = function (value) {
  if (value != undefined) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var inRangedAllowedDate = Object.assign(new Date(), today);

    /* console.log(inRangedAllowedDate) */
    var parts = value.split("/");
    var occurrenceDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );

    inRangedAllowedDate.setDate(inRangedAllowedDate.getDate() + 35);

    return today.getTime() <= occurrenceDate.getTime()
      ? inRangedAllowedDate.getTime() >= occurrenceDate.getTime()
        ? true
        : ""
      : "";
  }
};

extend("effectDateRule", {
  validate(value) {
    return customEffectDateRule(value);
  },
});

extend("isValid", {
  params: ["drivers"],
  validate(value, { drivers }) {
    if (value != undefined) {
      return !(value == "OUI" && Object.keys(drivers).length > 1);
    }
  },
  message:
    "Il n'est pas possible de selectionner la conduite exclusive car plusieurs conducteurs sont déclarés.",
});

export default {
  name: "PricingValidation",
  components: {
    OptionalGuaranteesEligible,
    Franchises,
    Fractionnement,
    EditicsDataTable,
    ValidationObserver,
    ValidationProvider,
    OptsMrh
  },
  data: () => ({
    menuEffectDate: false,
    formatedEffectDate: "",
    guaranteesSelection: [],
    listFractionnements: [],
  }),
  props: {},
  created() {
    if (this.estimate.product.code == Vue.prototype.$codeAuto){
      this.getAutoOptsItemsLists(); 
    } 
  },
  async mounted() {

    const responses = await axios
        .all([
          axios.get("/api/listItems/FRACTIONNEMENT"),
        ]);
    this.listFractionnements = responses[0].data.listItems;
  },
  computed: {
    ...mapState({
      transformation: (state) => state.transformation,
      loading: (state) => state.transformation.loader["getEstimation"] ?? false,
      /* createLoading: (state) => state.transformation.loader["createEstimate"] ?? false, */
      /* listsLoading: (state) => state.estimation.loader["listAutoOpts"], */
      options: (state) => state.transformation.estimate.productRisk.options,
      estimate: (state) => state.transformation.estimate,
      /* itemsLists: (state) => state.estimation.itemsLists, */
      estimation: (state) => state.transformation.estimation,
      itemsLists: (state) => state.transformation.itemsLists,
      listsLoading: (state) => state.transformation.loader["listAutoOpts"],
      formulaSelection: (state) =>
        state.transformation.estimate.formula.splitting +
        "_" +
        state.transformation.estimate.formula.libelle,
    }),
    effectDate: {
      get: function () {
        const rawDate = new Date(this.estimate.effectDate);
        const date = dayjs(rawDate).tz('Pacific/Noumea');
        this.formatedEffectDate = date.format("DD/MM/YYYY");
        return date.toISOString();//date.format("YYYY-MM-DD");
      },
      set: function (v) {
        if (v != undefined) {
          let now = new Date().toISOString();
          if (this.isToday(new Date(v)))
            this.$store.commit("transformation/updEffectDate", now);
          else this.$store.commit("transformation/updEffectDate", v);
        }
      },
    },
    drivers: function () {
      return this.estimate.productRisk.drivers;
    },
  },
  methods: {
    ...mapActions("transformation", [
      "getEstimation",
      /* "createEstimate", */
      "getAutoOptsItemsLists",
    ]),
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
    formatDate(date) {
      if (!date) return null;

      return new Date(date).toLocaleDateString();
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    estimateHandler() {
      console.log(this.$refs["autoOptsDisclaimer"])
      this.$refs["autoOptsDisclaimer"].validate().then((valid) => {
        console.log("FORM IS VALID:", valid)
        //console.log(this.getEstimation())
        if (valid) {
          let options = {};
          if (this.$refs.fractionnementForm) {
            options = { ...options, ...this.$refs.fractionnementForm.options };
          }
          if (this.$refs.OptionalGuaranteesEligibleForm) {
            console.log("this.$refs.OptionalGuaranteesEligibleForm.options", this.$refs.OptionalGuaranteesEligibleForm)
            options = { ...options, ...this.$refs.OptionalGuaranteesEligibleForm.options };
          }
          console.log("OPTIONS", options)
          /* this.firstLoad = true; */
          this.getEstimation(options);
        }
      });
    },
    createEstimateHandler() {
      this.$refs["autoOptsDisclaimer"].validate().then((valid) => {
        if (valid) {
          this.createEstimate();
        }
      });
      
    }
  },
  watch: {
    effectDate(val) {
      console.log("EFFECT DATE CHANGE:", val);
      logger.debug(
        "Remise à zéro de la tarification - Modification de la date d'effet"
      );
      this.$store.commit("transformation/resetEstimation");
    },
    estimation: {
      handler: function (val, oldVal) {
        if (Object.keys(val).length > 0)
          logger.debug("Selection de la formule choisie");
        this.$store.commit("transformation/updFormula", this.formulaSelection);
      },
      deep: true,
    },
    // Reinit. la tarification quand le risk est modifié
    /*  /* "estimate.productRisk": {
      handler(val, oldVal) {
        this.$store.commit("transformation/resetEstimation");
      },
      deep: true,
    }, */
  },
};
</script>
