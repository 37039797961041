<template>
  <div>
    <ValidationObserver ref="form">
       <PrincipalAssured :mainInsured="mainInsured" :itemsLists="itemsListsClient" ></PrincipalAssured>
      <v-form>
       
        <v-row>
          <v-col cols="3" sm="3">
            <h2>Logement :</h2>
          </v-col>
        </v-row>

        <!-- Address -->
        <v-row>
          <v-col cols="12" sm="3">
            <ValidationProvider v-slot="{ errors }" name="addresse" rules="required">
              <v-text-field
                v-model="mainInsuredRisk.homeAddress.adress1"
                label="Adresse"
                outlined
                hide-details
                :error-messages="errors"
                clearable
                clear-icon="clear"
                required
                :rules="[(v) => !!v]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="Code postal"
              rules="required|integer|length:5"
              vid="zipCode"
            >
              <v-text-field
                v-model="mainInsuredRisk.homeAddress.zipCode"
                label="CP *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"              />
            </ValidationProvider>
          </v-col>

          <!-- Fix rule with zip code  -->
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" name="ville" rules="required">
              <v-select
                :items="itemsLists['homeAddressCities']"
                v-model="mainInsuredRisk.homeAddress.city"
                item-text="libelle"
                item-value="libelle"
                label="Ville *"
                outlined
                :loading="citiesLoader"
                :disabled="itemsLists['homeAddressCities'] == 0"
                :error-messages="itemsLists['homeAddressCities'] == 0?'':errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="4" sm="3">
            <ValidationProvider v-slot="{ errors }" name="pays" rules="required">
              <v-select
                :items="itemsListsClient['countries']"
                v-model="mainInsuredRisk.homeAddress.country"
                return-object
                item-text="libelle"
                item-value="code"
                label="Pays"
                :error-messages="errors"
                outlined
                required
                :rules="[(v) => !!v, (v) => v.length > 0 || Object.keys(v).length > 0]"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <!--  <v-spacer /> -->
        </v-row>
        <!-- Compléments -->
        <v-row>
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" rules="required" name="homeType">
              <v-select
                :items="itemsLists['homeType']"
                item-text="libelle"
                item-value="code"
                v-model="mainInsuredRisk.homeType"
                return-object
                label="Type d'habitation *"
                outlined
                required
                :error-messages="errors"
                :loading="mrhListsLoader"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="3">
            <ValidationProvider v-slot="{ errors }" rules="required" name="clientHomeStatus">
              <v-select
                :items="itemsLists['clientHomeStatus']"
                item-text="libelle"
                item-value="code"
                v-model="mainInsuredRisk.clientHomeStatus"
                return-object
                label="Status souscripteur *"
                outlined
                required
                :error-messages="errors"
                :loading="mrhListsLoader"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="nombre de pièces"
              rules="integer|required"
            >
              <v-text-field
                v-model="mainInsuredRisk.roomsNumber"
                label="Nombre de pièces *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="3" sm="3">
            <ValidationProvider
              v-slot="{ errors }"
              name="nombre de pièces 30"
              rules="integer|required"
            >
              <v-text-field
                v-model="mainInsuredRisk.moreThan30MSquareRoomsNb"
                label="Nombre de pièces > 30 m2 *"
                outlined
                :error-messages="errors"
                clearable
                clear-icon="clear"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-row>
      <v-spacer />
      <v-col>
        <v-btn block large color="button" class="button_color--text" @click="nextStep">Suivant</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
</style>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required, integer, required_if } from "vee-validate/dist/rules";
setInteractionMode("eager");

extend("required_if", {
  ...required_if,
  message: "Le champ {_field_} doit être renseigné.",
});

extend("required", {
  ...required,
  message: "Le champ {_field_} doit être renseigné.",
});

extend("integer", {
  ...integer,
  message: "Le champ {_field_} doit contenir un nombre entier.",
});

import { mapState, mapActions } from "vuex";
import mrhBudgetStoreModule from "../../store/estimation/mrhBudget.store";
import PrincipalAssured from '../../components/estimation/PrincipalAssuredComponent.vue';
export default {
  name: "RiskMrh",
  props: {},
  components: {
    ValidationObserver,
    ValidationProvider,
    PrincipalAssured,
  },
  data: () => ({
    menuBirthDate: false,
    formatedBirthDate: null,
    valid: false,
  }),
  beforeCreate() {
    this.$store.registerModule("productRisk", mrhBudgetStoreModule);
    
  },
  async  created() {
    await this.getMrhItemsLists();
    this.$store.commit('productRisk/setProductRisk', this.$store.state.estimation.estimate.productRisk);
  },
  beforeDestroy() {
      this.$store.unregisterModule("productRisk");
    },
  mounted() {},
  computed: {
    ...mapState({
      client: (state) => state.estimation.estimate.person,
      mainInsured: (state) => state.productRisk.principalAssured,
      mainInsuredRisk: (state) => state.productRisk.principalAssured.risk,
      itemsListsClient: (state) => state.estimation.itemsLists,
      itemsLists: (state) => state.productRisk.itemsLists,
      citiesLoader: (state) => state.productRisk.loaders["homeAddressCities"],
      mrhListsLoader: (state) => state.productRisk.loaders["mrhLists"],
    }),
  },
  methods: {
    ...mapActions("productRisk", [
      "getHomeAddressCitiesList",
      "getMrhItemsLists",
    ]),
    async nextStep() {
      await this.$refs["form"].validate().then((valid) => {
        if (valid) {
          this.$store.commit("estimation/nextEstimationStep");
        } else logger.debug("Formulaire de payement invalide");
      });
    },
    verifyAge(dob) {
      var today = new Date();
      var parts = dob.split("/");
      var birthDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18 && age <= 77;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    "client.address": {
      handler: async function (address) {
        if (this.mainInsured.isSubscriber == 'true') {
          logger.debug(
            "Mise à jour de l'adresse de l'assuré principal avec les informations du souscripteur"
          );
          await this.getHomeAddressCitiesList(address.zipCode);
          this.$store.commit(
            "productRisk/setAddress",
            Object.assign({}, address)
          );
        }
      },
      deep: true,
    },
    //Au changement du code postal, supprime la ville et le tableau de choix des villes
    /* "mainInsuredRisk.address.zipCode"(zipCode, oldVal) {
      logger.info("watch zipcode mrh");
      if (zipCode === null || zipCode === "") {
        this.mainInsuredRisk.address.city = "";
        this.itemsLists["homeAddressCities"] = [];
      }
    }, */
    //Au changement du code postal, supprime la ville et le tableau de choix des villes
    "mainInsuredRisk.address.zipCode": {
      handler(zipCode) {
        if (zipCode === null || zipCode === "") {
          this.mainInsuredRisk.address.city = "";
          this.itemsLists["homeAddressCities"] = [];
        }
      },
    },
    "mainInsured.isSubscriber"(isSubscriber, oldVal) {
      logger.info("watch isPrincipal mrh");
      if (isSubscriber == 'true') {
        logger.debug("Reset info assuré principal");
        this.$store.commit("productRisk/resetPersonState");
      }
    },
  "mainInsured.person.birthDate"(val) {
      console.log('birthdate mrh')
      this.formatedBirthDate = this.formatDate(val);
    },
     "mainInsuredRisk.homeAddress.zipCode": {
      handler(val) {
        //logger.info(this.$refs.clientValidator.validate() && this.$refs.clientValidator);
        if(val === null || val === ''){
          this.mainInsuredRisk.homeAddress.city = "";
          this.itemsLists['homeAddressCities'] = []
        } else  this.getHomeAddressCitiesList(val);
      },
      deep: true,
      immediate: true
    },
  },
};
</script>









 