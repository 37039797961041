<template>

    <v-container
        fluid
        class="container"
    >

            <v-card>

                <v-card-title v-if="isDialog">
                  Modifier l'IBAN
                </v-card-title>
                <v-card-title v-else>
                  Adresse bancaire
                </v-card-title>

                <validation-observer
                    ref="contractObs"
                    v-slot="{ invalid }"
                >

                <form @submit.prevent="modifyBankAddress">

                    <v-card-text class="pa-5">

                        <validation-provider
                        v-slot="{ errors }"
                        vid="selectType"
                        name="type d'adresse bancaire"
                        rules="required"
                        >
                        <v-select
                        v-model="selectType"
                        :items="typeAdresse"
                        item-text="libelle"
                        item-value="code"
                        placeholder="Type d'adresse bancaire *"
                        return-object
                        :error-messages="errors"
                        outlined
                        clearable
                        hide-selected
                        ></v-select>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="selectPays"
                        name="pays"
                        rules="required"
                        >
                        <v-select
                        v-model="selectPays"
                        :items="paysAdresse"
                        item-text="libelle"
                        item-value="code"
                        placeholder="Pays *"
                        return-object
                        :error-messages="errors"
                        outlined
                        clearable
                        hide-selected
                        ></v-select>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="selectDevise"
                        name="devise"
                        rules="required"
                        >
                        <v-select
                        v-model="selectDevise"
                        :items="deviseAdresse"
                        item-text="libelle"
                        item-value="code"
                        placeholder="Devise *"
                        return-object
                        :error-messages="errors"
                        outlined
                        clearable
                        hide-selected
                        ></v-select>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="titulaire"
                        name="titulaire"
                        rules="required|max:50"
                        >
                        <v-text-field
                        outlined
                        :error-messages="errors"
                        v-model="titulaire"
                        label="Titulaire *"
                        class="upperCaseInput"
                        ></v-text-field>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="codeIBAN"
                        name="code iban"
                        rules="required|validateIban"
                        >
                        <v-text-field
                        :error-messages="errors"
                        v-model="codeIBAN"
                        label="Code IBAN *"
                        outlined
                        class="upperCaseInput"
                        ></v-text-field>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="codeBIC"
                        name="code bic"
                        rules="required|validateBic"
                        >
                        <v-text-field
                        v-model="codeBIC"
                        :error-messages="errors"
                        :counter="50"
                        label="Code BIC *"
                        outlined
                        class="upperCaseInput"
                        ></v-text-field>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="domiciliation"
                        name="domiciliation"
                        rules="required|max:50"
                        >
                        <v-text-field
                        v-model="domiciliation"
                        :error-messages="errors"
                        :counter="50"
                        label="Domiciliation *"
                        outlined
                        class="upperCaseInput"
                        ></v-text-field>
                        </validation-provider>

                        <v-switch
                        v-if="isDialog"
                        v-model="allContractBank"
                        label="Modifier l'IBAN pour tous les contrats"
                        class="mt-0"
                        ></v-switch>

                        </v-card-text>

                        <v-card-actions v-if="isDialog">

                            <v-spacer></v-spacer>

                            <v-btn
                            color=""
                            text
                            @click="closeDialog()"
                            >
                            fermer
                            </v-btn>

                            <v-btn
                            color="button"
                            class="button_color--text"
                            type="submit"
                            :disabled="invalid"
                            :loading="loadingSubmitUpdateBank"
                            >
                                <v-icon left>done</v-icon>Valider
                            </v-btn>

                        </v-card-actions>

                    </form>

                </validation-observer>

            </v-card>

        <v-snackbar
          v-model="snackbar"
          :color="message.type"
          :multi-line="true"
          timeout="5000"
        >
            {{ message.message }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="button_color--text"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fermer
            </v-btn>
          </template>

        </v-snackbar>

    </v-container>

</template>

<style>
.container {
    padding: 0 !important;
}
.upperCaseInput input{
    text-transform: uppercase;
}
</style>

<script>
const ibantools = require('ibantools')
import Vue from 'vue';
import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
localize('fr', fr);

setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  // message: '{_field_} can not be empty',
})

extend("validateIban", {
  message: field => "Le format du code IBAN n'est pas correct",
  validate: value => {
    const isValidFormat = ibantools.isValidIBAN( value );
    const isValidCodeIban = Vue.prototype.$ibanStartsWith.includes(value.substring(0, 2));
    return isValidFormat && isValidCodeIban;
  }
});

extend("validateBic", {
  message: field => "Le format du code BIC n'est pas correct",
  validate: value => {
    return ibantools.isValidBIC( value );
  }
});

import axios from "axios";

export default {
  name: "UpdateBankAddress",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
      //snackbar
      snackbar: false,
      success: false,
      message: [],
      errors: [],

      //modif IBAN
      loadingSubmitUpdateBank: false,

      typeAdresse: [],
      paysAdresse: [],
      deviseAdresse: [],

      selectType: {
          libelle: "",
          code: ""
      },
      selectPays: {
          libelle: "",
          code: ""
      },
      selectDevise: {
          libelle: "",
          code: ""
      },

      codeIBAN: "",
      codeBIC: "",
      titulaire: "",
      domiciliation: "",
      allContractBank: false,

  }),
  props: {
      contract: Object,
      getContractParent: Function,
      isDialog: Boolean,
  },
  created () {
  },
  mounted () {
    this.getListTypes();
    this.getListCountries();
    this.getListDevise();

    this.selectType.code=this.contract.details.addresses.bankAccount.type.code
    this.selectPays.code=this.contract.details.addresses.bankAccount.country.code
    this.selectDevise.code=this.contract.details.addresses.bankAccount.currency.code

    this.titulaire=this.contract.details.addresses.bankAccount.accountOwner
    this.codeIBAN=this.contract.details.addresses.bankAccount.ibanCode
    this.codeBIC=this.contract.details.addresses.bankAccount.bicCode
    this.domiciliation=this.contract.details.addresses.bankAccount.domiciliation
  },
  computed: {
  },
  methods: {
    closeDialog: function(){
        this.$emit('close-dialog')
    },
    getListTypes(){
        axios.post("/api/listItems/types").then(countries=>{
            this.typeAdresse=countries.data.listItems;
        })
    },
    getListCountries(){
        axios.post("/api/listItems/countries").then(countries=>{
            this.paysAdresse=countries.data.listItems;
        })
    },
    getListDevise(){
        axios.post("/api/listItems/devises").then(countries=>{
            this.deviseAdresse=countries.data.listItems;
        })
    },
    modifyBankAddress(){

        if(
            this.selectType.code!=this.contract.details.addresses.bankAccount.type.code
            || this.selectPays.code!=this.contract.details.addresses.bankAccount.country.code
            || this.selectDevise.code!=this.contract.details.addresses.bankAccount.currency.code
            || this.titulaire != this.contract.details.addresses.bankAccount.accountOwner
            || this.codeIBAN!=this.contract.details.addresses.bankAccount.ibanCode
            || this.codeBIC!=this.contract.details.addresses.bankAccount.bicCode
            || this.domiciliation!=this.contract.details.addresses.bankAccount.domiciliation
            || this.allContractBank
        ){
            this.loadingSubmitUpdateBank = true

            this.$refs.contractObs.validate()

            //need to set code and libelle for post
            this.selectType=this.typeAdresse.find(o => o.code === this.selectType.code);
            this.selectPays=this.paysAdresse.find(o => o.code === this.selectPays.code);
            this.selectDevise=this.deviseAdresse.find(o => o.code === this.selectDevise.code);

            axios.post('/api/client/update/bank/address', {

                personId:''+this.contract.details.person.id+'',
                contractId: ''+this.contract.details.id+'',

                bankId:''+this.contract.details.addresses.bankAccount.id+'',
                type:this.selectType,
                pays:this.selectPays,
                devise:this.selectDevise,
                titulaireCompte:this.titulaire,
                codeIban:this.codeIBAN,
                codeBic:this.codeBIC,
                domiciliation:this.domiciliation,
                allContractBank:this.allContractBank,
                effectDate:this.contract.computed.maxStatusEffectDate,
            }).then((response)=>{

                if ( this.isDialog ) {

                    this.getContractParent()

                    setTimeout( () => this.closeDialog(), 3000)

                    if ( response.data.redirect ) {
                        this.$router.push( '#documents' )
                    }
                }
                this.message.type = response.data.type
                this.message.message = response.data.message

                this.loadingSubmitUpdateBank = false
                this.snackbar = true

            })
        } else {
            this.success = false
            this.message.type = 'warning'
            this.message.message = 'Aucune donnée n\'a été modifiée'
            this.snackbar = true
        }

    },
  },
  watch: {
  }
};
</script>
