<template lang="pug">
  v-container(fluid)
      v-card.ma-3
        v-card-text
          v-layout(row wrap)
            v-flex(lg3 md4 sm6 xs12)
              v-text-field.ma-2(v-model="search['role.title[LIKE]']" label="Titre" outlined hide-details clearable)
            v-flex(lg3 md4 sm6 xs12)
              v-switch.ma-5(v-model="search['role.isEnabled[=]']" label="Actif" hide-details)
        v-card-actions
          v-btn.button_color--text(@click="list()" color="button" large)
            v-icon search
            | Rechercher
          //v-spacer
          //h1.text-right  résultat(s)
      BasicCrud(
        title="Gestion des Roles",
        item-name="role",
        :headers="headers",
        :items="items.roles",
        :nbItemsTotal="items.nbItemsTotal",
        :default-item="defaultItem",
        :create="create",
        :remove="remove",
        :update="update",
        :loading="loading",
        :valid="valid",
        @list="list",
      )
        template(v-slot:item.title='{item}')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on='on') {{ item.title }}
            span {{ item.title }}
        template(v-slot:item.isEnabled='{item}')
          tr
            td(v-if='item.isEnabled')
              v-icon(small)
                | lock_open
            td(v-else)
              v-icon(small)
                | lock
        template(v-slot:form="{ editedItem }")
          v-container
            validation-observer(ref='roleObserver' v-slot='{ invalid }')
              v-row
                v-col(cols="12")
                  validation-provider(v-slot='{ errors }' name='title' rules='required|max:50')
                    VTextInput(v-model="editedItem.title" label="Titre *" :messages="errors" @formValidate='formValidate' v-bind:attr='{"outlined":"","clearable":"","hide-details":""}')
              v-row
                v-col(cols="12")
                    VSwitchInput(v-model="editedItem.isEnabled" label="Actif" :messages="errors" @formValidate='formValidate' v-bind:attr='{"class":"mt-0","hide-details":""}')
</template>

<style>
.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import BasicCrud from "@/components/common/BasicCrud";
import VTextInput from "@/components/common/form/VTextInput";
import VSwitchInput from "@/components/common/form/VSwitchInput";

import RoleService from "@/services/administration/role.service";

import { required, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("max", max);

export default {
  components: {
    BasicCrud,
    VTextInput,
    VSwitchInput,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: true,
    valid: false,
    search: {},
    errors: [],
    items: [],
    headers: [
      {
        text: "Titre",
        align: "start",
        value: "title",
        sortBy: "role.title",
      },
      {
        text: "Actif",
        align: "start",
        value: "isEnabled",
        sortBy: "role.isEnabled",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    defaultItem: {
      title: "",
      isEnabled: false,
    },
  }),
  mounted() {},
  methods: {
    async formValidate() {
      this.valid = await this.$refs.roleObserver.validate();
    },
    async list(page, nbItemsPerPage, sortOption) {
      this.loading = true;
      this.items = await RoleService.list(
        page,
        nbItemsPerPage,
        sortOption,
        this.search
      );
      this.loading = false;
    },
    async create(item) {
      await RoleService.create(item);
      await this.list();
    },
    async remove(id) {
      await RoleService.remove(id);
      await this.list();
    },
    async update(id, item) {
      await RoleService.update(id, item);
      await this.list();
    },
  },
};
</script>
