import store from "@/store";

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};
export default isAuthenticated;
