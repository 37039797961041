<template>
  <v-container fluid class="container">
    <v-data-table
      v-model="signEditiques"
      :headers="this.headers"
      :items="this.data"
      item-key="id"
      hide-default-footer
      class="elevation-3 row-pointer"
      :items-per-page="100"
      sort-by="creationDateBrut.date"
      :sort-desc="true"
      :loading="loadingDownloadEditique"
    >
      <template v-slot:[`item.creationDateBrut.date`]="{ item }">
        <v-col>{{ item.creationDate }}</v-col>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click="downloadEditique(item.id)">
          <v-icon>downloading</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row class="pt-4">
      <v-col class="text-right">
        <v-btn
          v-show="isEstimation == false"
          class="tertiary_light button_color--text"
          x-large
          :loading="loadingsubmitSignEditique"
          @click="submitSignEditique"
        >
          <v-icon> approval </v-icon>
          Signer vos documents
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="30000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}
</style>

<script>
import axios from "axios";
export default {
  name: "EditicsDataTable",
  data: () => ({
    snackbar: false,
    success: false,
    message: [],
    errors: [],

    loadingDownloadEditique: false,
    loadingsubmitSignEditique: false,
    signEditiques: [],
  }),
  props: {
    data: Array,
    headers: Array,
    options: Array | Object,
    isEstimation: {
      type: Boolean,
      default: false,
    },
    contract: Object,
    getContractParent: Function,
  },
  created() {},
  mounted() {},
  computed: {},
   beforeDestroy() {
    this.$store.unregisterModule("transformation");
  },
  methods: {
    downloadEditique(id) {
      this.loadingDownloadEditique = true;

      return new Promise((resolve, reject) => {
        axios
          .post("/api/editique/get", {
            editiqueId: id,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response)
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            // base64 string
            var base64str = response.data.content;

            // decode base64 string, remove space for IE compatibility
            var binary = atob(base64str.replace(/\s/g, ""));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
            }

            // create the blob object
            var blob = new Blob([view]);
            var fileURL = URL.createObjectURL(blob);

            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", response.data.name);
            document.body.appendChild(fileLink);

            fileLink.click();

            this.success = response.data.success;
            this.message = response.data.message;
            this.errors = response.data.errors;

            this.loadingDownloadEditique = false;
            this.snackbar = true;

            resolve({
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);

            this.success = false;
            this.message.type = "error";
            this.message.message = "Votre demande ne peut pas aboutir";

            this.loadingDownloadEditique = false;
            this.snackbar = true;
          });
      });
    },
    submitSignEditique() {
      this.loadingsubmitSignEditique = true;
      this.success = true;
      this.message.type = "success";
      this.message.message =
        "Votre demande de signature electronique est en cours, merci de patienter 30 secondes pendant la préparation de vos documents.";
      this.snackbar = true;

      var editiquesSignables = this.contract.editiques.data.filter(function (
        signables
      ) {
        return signables.signable == true;
      });

      if (this.contract.contract)
        return new Promise((resolve, reject) => {
          axios
            .post("/api/editique/sign", {
              contractId: this.contract.details.id,
              contractFirstName: this.contract.details.person.firstname,
              contractLastName: this.contract.details.person.lastname,
              contractEmail: this.contract.details.addresses.mail.mail,
              contractPhone: this.contract.details.addresses.phone.number,
              editiqueIdS: editiquesSignables,
            })
            .then((response) => {
              // Success 🎉
              // console.log(response)
              let success = response.data.success;
              let message = response.data.message;
              let errors = response.data.errors;

              if (response.data.success) {
                this.getContractParent();
              }

              this.success = response.data.success;
              this.message = response.data.message;
              this.errors = response.data.errors;

              this.loadingsubmitSignEditique = false;
              this.snackbar = true;

              resolve({
                success,
                message,
                errors,
              });
            })
            .catch((error) => {
              // Error 😨
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log("error.response");
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log("error.request");
                console.log(error.request);
              } else {
                // Something happened in setting up the request and triggered an Error
                console.log("Error");
                console.log("Error", error.message);
              }
              console.log("error.config");
              console.log(error.config);

              this.success = false;
              this.message.type = "error";
              this.message.message = "Votre demande ne peut pas aboutir";

              this.loadingsubmitSignEditique = false;
              this.snackbar = true;
            });
        });
      else
        return new Promise((resolve, reject) => {
          axios
            .post("/api/editique/sign", {
              contractId: this.contract.details.id,
              contractFirstName: this.contract.details.person.firstname,
              contractLastName: this.contract.details.person.lastname,
              contractEmail: this.contract.details.addresses.mail.mail,
              contractPhone: this.contract.details.addresses.phone.number,
              editiqueIdS: editiquesSignables,
            })
            .then((response) => {
              // Success 🎉
              // console.log(response)
              let success = response.data.success;
              let message = response.data.message;
              let errors = response.data.errors;

              if (response.data.success) {
                this.getContractParent();
              }

              this.success = response.data.success;
              this.message = response.data.message;
              this.errors = response.data.errors;

              this.loadingsubmitSignEditique = false;
              this.snackbar = true;

              resolve({
                success,
                message,
                errors,
              });
            })
            .catch((error) => {
              // Error 😨
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log("error.response");
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log("error.request");
                console.log(error.request);
              } else {
                // Something happened in setting up the request and triggered an Error
                console.log("Error");
                console.log("Error", error.message);
              }
              console.log("error.config");
              console.log(error.config);

              this.success = false;
              this.message.type = "error";
              this.message.message = "Votre demande ne peut pas aboutir";

              this.loadingsubmitSignEditique = false;
              this.snackbar = true;
            });
        });
    },
  },
  watch: {},
};
</script>
