<template>

  <div>

    <validation-observer
        ref="folderSearchObserver"
        v-slot="{ invalid }"
    >

    <v-form
      @submit.prevent="getDataFromApi"
    >

    <v-row>

      <v-col cols="12" sm="6">

        <validation-provider
            v-slot="{ errors }"
            vid="title"
            name="Titre"
            rules="max:50"
        >
            <v-text-field
              v-model="title"
              label="Titre"
              :error-messages="errors"
              outlined
              hide-details="auto"
              clearable
              clear-icon="clear"
            >
        </v-text-field>
        </validation-provider>

      </v-col>

      <v-col cols="12" sm="6">

          <v-switch
              v-model="isEnabled"
              :label="`Dossiers actifs ?`"
          ></v-switch>

      </v-col>

    </v-row>

    <v-row>

      <v-col cols="12" sm="4">

        <v-btn block large color="button" class="button_color--text" type="submit">
          <v-icon left>search</v-icon>Rechercher
        </v-btn>

      </v-col>

      <v-col cols="12" sm="4"><h1>{{total}} résultat(s)</h1></v-col>

      <v-col cols="12" sm="4">

        <v-btn block large color="button" class="button_color--text" to="/folder/search/add">
          <v-icon left>add</v-icon>Ajouter un dossier
        </v-btn>

      </v-col>

    </v-row>

    </v-form>

    </validation-observer>

    <v-row>

      <v-col class="pt-10">

        <v-skeleton-loader
          v-if="firstLoad"
          :loading="loading"
          type="table-thead, table-tbody, table-tfoot">
        </v-skeleton-loader>

        <v-data-table
          v-show="!firstLoad"
          :headers="headers"
          :items="folders"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          item-key="id"
          @click:row="goToPage"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions:[20,50,100],
          }"
          class="elevation-3 row-pointer"
        ></v-data-table>

      </v-col>

    </v-row>

  </div>

</template>

<style scoped>
::v-deep tbody tr :hover { cursor: pointer; }
</style>

<script>
import { max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
localize('fr', fr);

setInteractionMode('eager')

extend('max', {
  ...max,
  message: 'La saisie est limitée à {length} caractères',
})

import axios from "axios";
export default {
  name: "FolderSearch",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({

      title: '',
      order: 0,
      isExpanded: true,
      isEnabled: true,

    total: 0,

    folders: [],

    success: false,
    message: [],
    errors: [],

    firstLoad: true,
    loading: true,

    options: {},

    firstPage: 0,
    sortBy: 'title',
    sortDesc: true,

    /* Manage default fields values to post */
    sortField: 'title',
    sortOrder: 'ASC',

    headers: [
        { text: 'Titre', value: 'title', align: 'start', sortable: false, },
        // { text: 'Ordre', value: 'order', sortable: true, align: 'start', },
        // { text: 'Déplié par défaut', value: 'isExpanded', sortable: false },
        // { text: 'Actif', value: 'isEnabled', sortable: false },
    ],
  }),
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    goToPage( row ) {
      this.$router.push('/folder/search/'+row.id)
    },
    getDataFromApi() {
      this.loading = true
      this.folderSearchResults().then(data => {
          this.folders = data.folders
        this.total = data.total
        this.$refs.folderSearchObserver.setErrors(data.errors)
        if (this.firstLoad) this.firstLoad = false
        this.loading = false
      })
    },
    folderSearchResults() {

        this.$refs.folderSearchObserver.validate()

      return new Promise((resolve, reject) => {

        const { sortBy, sortDesc, page, itemsPerPage } = this.options

// console.log('page = '+page+' itemsPerPage = '+itemsPerPage+' sortBy = '+sortBy+' sortDesc ='+sortDesc)

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
            this.firstPage = 0
        } else {
            this.firstPage = (page - 1) * itemsPerPage
        }

        /* Manage order by value to post for okayo request */
        if (sortDesc[0]) {
            this.sortOrder = 'ASC'
        } else {
            this.sortOrder = 'DESC'
        }

        /* Manage sort by value to post for okayo request */
        if (sortBy[0]) {
            this.sortField = sortBy[0]
        } else {
            this.sortField = this.sortField
        }

        axios.post('/api/admin/folder/search', {

            pageSize: itemsPerPage,//limit
            firstPage: this.firstPage,//offset
            sortField: this.sortField,
            sortOrder: this.sortOrder,

            id: '',
            title: this.title,
            isEnabled: this.isEnabled,

        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data)

          let folders = response.data.folders

          const total = response.data.totalCount;

          let success = response.data.success
          let message = response.data.message
          let errors = response.data.errors

          resolve({
            folders,
            total,
            success,
            message,
            errors,
          })

        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error')
            console.log('Error', error.message)
          }
          console.log('error.config')
          console.log(error.config)

        });

      })
    },
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  }
};
</script>
