import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const pjStoreModule = {
  namespaced: true,
  // https://vuex.vuejs.org/guide/modules.html#module-reuse !!
  state: () => ({
    options: {},
    itemsLists: {
      relations: [],
    },
    loaders: {
      pjLists: false,
    },
  }),
  mutations: {
    setProductRisk(state, productRisk) {
      logger.info("set productRisk to PJ view");
      console.log(productRisk);
    },
  },
  actions: {
    async getPjItemsLists(context) {},
  },
};

export default pjStoreModule;
