var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"pageSearchObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getDataFromApi.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"vid":"title","name":"Titre","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Titre","error-messages":errors,"outlined":"","hide-details":"auto","clearable":"","clear-icon":"clear"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"label":"Pages actives ?"},model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{staticClass:"button_color--text",attrs:{"block":"","large":"","color":"button","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("search")]),_vm._v("Rechercher ")],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('h1',[_vm._v(_vm._s(_vm.total)+" résultat(s)")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{staticClass:"button_color--text",attrs:{"block":"","large":"","color":"button","to":"/page/search/add"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v("Ajouter une page ")],1)],1)],1)],1)]}}])}),_c('v-row',[_c('v-col',{staticClass:"pt-10"},[(_vm.firstLoad)?_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"type":"table-thead, table-tbody, table-tfoot"}}):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.firstLoad),expression:"!firstLoad"}],staticClass:"elevation-3 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.pages,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"item-key":"id","footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions:[20,50,100],
        }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToPage}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }