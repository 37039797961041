<template>
  <div>
    <v-skeleton-loader
      v-if="firstLoad"
      type="card-heading, list-item@5"
      height="380"
      class="mt-5"
    >
    </v-skeleton-loader>
    <v-card v-show="!firstLoad">
      <v-card-title>
        Consulter et éditer les informations utilisateur
      </v-card-title>

      <v-card-subtitle>
        Voir et éventuellement modifier ses informations personnelles
      </v-card-subtitle>

      <validation-observer ref="userObserver" v-slot="{ invalid }">
        <form @submit.prevent="submitMyProfile">
          <v-card-text>
            <input type="hidden" name="id" />

            <validation-provider
              v-slot="{ errors }"
              vid="firstName"
              name="prénom"
              rules="required|max:35"
            >
              <v-text-field
                v-model="firstName"
                :counter="35"
                :error-messages="errors"
                label="Prénom"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              vid="lastName"
              name="nom"
              rules="required|max:35"
            >
              <v-text-field
                v-model="lastName"
                :counter="35"
                :error-messages="errors"
                label="Nom"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              vid="email"
              name="email"
              rules="required|max:50|email"
            >
              <v-text-field
                v-model="email"
                :error-messages="errors"
                label="Email"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              vid="broker"
              name="courtier"
              rules="required"
            >
              <v-select
                :items="brokerList"
                v-model="broker"
                :error-messages="errors"
                label="Courtiers"
                outlined
                hide-details
                hide-selected
                clearable
                item-text="name"
                item-value="id"
                return-object
              ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              vid="role"
              name="rôle"
              rules="required"
            >
              <v-select
                :items="roles"
                v-model="role"
                :error-messages="errors"
                label="Rôle"
                outlined
                hide-details
                hide-selected
                clearable
                item-text="name"
                item-value="id"
                class="mt-5"
              ></v-select>
            </validation-provider>

            <v-switch
              v-model="isEnabled" 
              label="Actif"
              color="green"
            />
          </v-card-text>

          <v-card-actions class="text-right">
            <v-btn
              :loading="loadingUpdateMyprofile"
              color="button"
              class="button_color--text"
              type="submit"
              :disabled="invalid"
            >
              enregistrer
            </v-btn>
            &nbsp;&nbsp;
            <v-btn
              :loading="loadingRemoveUser"
              color="button"
              class="button_color--text"
              @click.stop="dialog = true"
            >
              supprimer
            </v-btn>
            <v-spacer />
            <v-btn color="button" class="button_color--text" to="/user/search">
              Annuler
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="text-h5">
          Suppression d'un utilisateur ?
        </v-card-title>

        <v-card-text>
          Vous êtes sur le point de supprimer un utilisateur.
          <strong>Cette action est irréversible</strong>. Etes-vous certain de
          vouloir continuer ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="button" text @click="dialog = false"> Non </v-btn>

          <v-btn color="button" text @click.stop="submitRemoveUser">
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
// import { required, confirmed, digits, email, max, min, regex } from 'vee-validate/dist/rules'
import { required, confirmed, email, max, min } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);

setInteractionMode("eager");

extend("required", {
  ...required,
  // message: '{_field_} can not be empty',
});

extend("confirmed", {
  ...confirmed,
  // message: '{_field_} not identical',
});

extend("email", {
  ...email,
  // message: 'Email must be valid',
});

extend("max", {
  ...max,
  // message: '{_field_} may not be greater than {length} characters',
});

extend("min", {
  ...min,
  // message: '{_field_} may not be smaller than {length} characters',
});

// create custom error message for custom rule
var errorMessage =
  "doit contenir au minimum 8 caractères. Avec au moins un caractère spécial (#, !, @, $, %, ^, *, -), une minuscule, une majuscule et un chiffre";
// create custom rule
extend("customPassword", {
  message: (field) => "Le mot de passe " + errorMessage,
  validate: (value) => {
    var notTheseChars = /["'?&/<>\s]/;
    var mustContainTheseChars =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^*-]).{8,}$/;
    var containsForbiddenChars = notTheseChars.test(value);
    var containsRequiredChars = mustContainTheseChars.test(value);
    if (containsRequiredChars && !containsForbiddenChars) {
      return true;
    } else {
      if (containsForbiddenChars) {
        errorMessage =
          'contient des caractères interdits:  " ' +
          " ' ? & / < > ou des espaces";
      } else {
        errorMessage =
          "doit contenir au minimum 8 caractères. Avec au moins un caractère spécial (#, !, @, $, %, ^, *, -), une minuscule, une majuscule et un chiffre";
      }
      return false;
    }
  },
});

import axios from "axios";

export default {
  name: "UserUpdate",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    dialog: false,
    firstLoad: true,

    loadingUpdateMyprofile: false,
    loadingUpdateMyPassword: false,
    loadingRemoveUser: false,

    snackbar: false,
    success: false,
    message: [],
    errors: [],

    id: "",
    isEnabled: false,
    firstName: "",
    lastName: "",
    email: "",
    roles: [],
    role: null,
    userBrokerId: "",
    userBroker: {},

    brokerList: [],
    broker: {},
  }),
  created() {},
  mounted() {
    if (this.firstLoad) this.firstLoad = false;
    this.id = this.$route.params.id;
    this.getUser();
    this.getBrokerList();
  },
  computed: {},
  methods: {
    getBrokerList() {
      axios
        .post("/api/broker/search", {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
          isEnabled: true,
          pageSize: 0,
          firstPage: 0,
          sortField: "firstName",
          sortOrder: "ASC",
        })
        .then((brokerList) => {
          this.brokerList = brokerList.data.brokerList;
          this.broker = this.userBroker;
        });
    },
    submitMyProfile() {
      this.loadingUpdateMyprofile = true;

      //TODO: comprendre ce qu'il se passe avec ce select
      this.userBrokerId = null;
      if (typeof this.broker === "object") {
        if (this.broker != null && this.broker != "undefined") {
          if (!Array.isArray(this.broker)) {
            this.userBrokerId = this.broker.id;
          }
        }
      } else {
        this.userBrokerId = null;
      }

      this.updateMyProfile().then((data) => {
        if (this.firstLoad) this.firstLoad = true;
        this.success = data.success;
        this.message = data.message;

        this.$refs.userObserver.setErrors(data.errors);

        this.loadingUpdateMyprofile = false;
        this.snackbar = true;

        setTimeout(() => this.$router.push("/user/search/"), 3000);
      });
    },
    getUser() {
      axios
        .post("/api/broker/user/get", {
          id: this.id,
        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data);
          let user = response.data.user;
          let success = response.data.success;
          let message = response.data.message;
          let errors = response.data.errors;
          this.id = user.id;
          this.isEnabled = user.isEnabled;
          this.firstName = user.firstName;
          this.lastName = user.lastName;
          this.email = user.email;
          this.roles = response.data.roles;
          this.role = user.role[0];
          this.userBrokerId = user.userBrokerId;
          this.userBroker = response.data.broker;
          resolve({
            user,
            success,
            message,
            errors,
          });
        })
        .catch((error) => {});
    },
    updateMyProfile() {
      this.$refs.userObserver.validate();

      return new Promise((resolve, reject) => {
        axios
          .post("/api/broker/user/update", {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            isEnabled: this.isEnabled,
            brokerId: this.userBrokerId,
            role: this.role,
          })
          .then((response) => {
            // Success 🎉
            //console.log(response.data);
            let user = response.data.user;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;
            resolve({
              user,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    submitRemoveUser() {
      this.dialog = false;

      this.loadingRemoveUser = true;

      this.removeUser().then((data) => {
        if (this.firstLoad) this.firstLoad = false;

        this.id = data.id;

        this.success = data.success;
        this.message = data.message;

        this.loadingRemoveUser = false;
        this.snackbar = true;

        setTimeout(() => this.$router.push("/user/search"), 3000);
      });
    },
    removeUser() {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/broker/user/remove", {
            id: this.id,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data);
            let id = response.data.id;

            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;
            resolve({
              id,

              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {},
};
</script>
