<template>
  <div>
    <!-- <ValidationObserver ref="form"> -->
      <v-form>
        <div>
          <v-col>
            <ValidationProvider rules vid="isSubscriber" name="assuré principal">
              <v-checkbox
                v-model="mainInsured.isSubscriber"
                title="Assuré principal"
                style="color: blue"
                label="Le souscripteur est-il l'assuré principal ?"
                true-value="true"
                false-value="false"
              />
            </ValidationProvider>
          </v-col>
        </div>
        <div v-show="mainInsured.isSubscriber == 'false'">
          <v-row>
            <v-col cols="2">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required_if:isSubscriber,false"
                name="civilité"
              >
                <v-select
                  :items="itemsLists['civilities']"
                  item-text="libelleCourt"
                  item-value="code"
                  v-model="mainInsured.person.civility"
                  return-object
                  label="Civilité"
                  outlined
                  :loading="loader"
                  required
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="5">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required_if:isSubscriber,false"
                name="nom"
              >
                <v-text-field
                  v-model="mainInsured.person.lastname"
                  label="Nom"
                  outlined
                  clearable
                  clear-icon="clear"
                  required
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="5">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required_if:isSubscriber,false"
                name="prénom"
              >
                <v-text-field
                  v-model="mainInsured.person.firstname"
                  label="Prénom"
                  outlined
                  clearable
                  clear-icon="clear"
                  required
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required_if:isSubscriber,false"
                name="situation matrimoniale"
              >
                <v-select
                  :items="itemsLists['maritalStatuses']"
                  v-model="mainInsured.person.maritalStatus"
                  return-object
                  item-text="libelle"
                  item-value="code"
                  label="Sit. Mat."
                  outlined
                  required
                  :loading="loader"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="4" sm="4">
              <v-menu
                ref="menuBirthDate"
                v-model="menuBirthDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required_if:isSubscriber,false"
                    name="date de naissance"
                  >
                    <v-text-field
                      v-model="formatedBirthDate"
                      label="Date de naissance "
                      outlined
                      persistent-hint
                      clearable
                      clear-icon="clear"
                      @click:clear="mainInsured.person.birthDate = ''"
                      v-bind="attrs"
                      @blur="$set(mainInsured.person,'birthDate', parseDate(formatedBirthDate))"
                      v-on="on"
                      required
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="mainInsured.person.birthDate"
                  scrollable
                  header-color="tertiary"
                  color="tertiary_light"
                  first-day-of-week="1"
                  no-title
                  @input="menuBirthDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" sm="4">
              <v-text-field
                v-model="mainInsured.person.profession"
                label="Profession"
                outlined
                clearable
                clear-icon="clear"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required_if:isSubscriber,false"
                name="catégorie socioprofessionnelle"
              >
                <v-select
                  :items="itemsLists['csps']"
                  v-model="mainInsured.person.socioProfessionalCategory"
                  return-object
                  item-text="libelle"
                  item-value="code"
                  label="Cat. Socioprofessionnelle"
                  outlined
                  required
                  :error-messages="errors"
                  :loading="loader"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>
      </v-form>
    <!-- </ValidationObserver> -->
  </div>
</template>

<style scoped>
</style>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required, required_if } from "vee-validate/dist/rules";
setInteractionMode("eager");

extend("required_if", {
  ...required_if,
  message: "Le champ {_field_} doit être renseigné.",
});

extend("required", {
  ...required,
  message: "Le champ {_field_} doit être renseigné.",
});

export default {
  name: "Principal Assured Component",
  props: {},
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    menuBirthDate: false,
    formatedBirthDate: null,
    valid: false,
  }),
  props: {
    mainInsured: Object,
    itemsLists: Object,
    loader: {
      type: Boolean,
      default: false
    },
  },
  mounted() {},
  computed: {
  },
  methods: {
    verifyAge(dob) {
      var today = new Date();
      var parts = dob.split("/");
      var birthDate = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      );

      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18 && age <= 77;
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    "mainInsured.person.birthDate"(val) {
      console.log('birthdate mrh')
      this.formatedBirthDate = this.formatDate(val);
    },
  },
};
</script>









 