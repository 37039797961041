import axios from "axios";
import store from "@/store";

function setupInterceptors() {
  axios.interceptors.request.use((request) => {
    const isApiUrl = request.url.startsWith("/api/");
    const isApiResetPasswordUrl = request.url.startsWith("/api/reset-password");
    const isPublicRoute = ["/api/token/refresh", "/api/login"].includes(
      request.url
    );

    // console.log("--------------#INTERCEPTOR--------------");
    // console.log("URL => ", request.url);
    // console.log("isPublicRoute => ", isPublicRoute);
    // console.log("isApiResetPasswordUrl => ", isApiResetPasswordUrl);
    // console.log("isAPI=>", isApiUrl);
    // console.log("isAuthenticated=>", store.state.auth.isAuthenticated);

    if (
      store.state.auth.isAuthenticated &&
      isApiUrl &&
      !isPublicRoute &&
      !isApiResetPasswordUrl
    ) {
      request.headers[
        "Authorization"
      ] = `Bearer ${store.state.auth.data.token}`;
      // console.log("SET TOKEN", store.state.auth.data.token);
    }
    // console.log("--------------/INTERCEPTOR--------------");
    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log(error.response.config.url);
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.config.url !== "/api/token/refresh"
      ) {
        const originalConfig = error.config;
        await store.dispatch("auth/refreshToken");
        return axios(originalConfig);
      } else if (
        error.response &&
        error.response.status === 401 &&
        error.response.config.url === "/api/token/refresh"
      ) {
        await store.dispatch("auth/logout");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
}

export default setupInterceptors;
