<template>
  <div>
    <validation-observer ref="userSearchObserver" v-slot="{ invalid }">
      <v-form @submit.prevent="getDataFromApi">
        <v-row>
          <v-col cols="12" sm="3">
            <validation-provider
              v-slot="{ errors }"
              vid="firstName"
              name="prénom"
              rules="max:35"
            >
              <v-text-field
                v-model="firstName"
                label="Prénom"
                :error-messages="errors"
                outlined
                hide-details="auto"
                clearable
                clear-icon="clear"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="3">
            <validation-provider
              v-slot="{ errors }"
              vid="lastName"
              name="nom"
              rules="max:35"
            >
              <v-text-field
                v-model="lastName"
                label="Nom"
                :error-messages="errors"
                outlined
                hide-details="auto"
                clearable
                clear-icon="clear"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="3">
            <validation-provider
              v-slot="{ errors }"
              vid="email"
              name="Email"
              rules="max:50"
            >
              <v-text-field
                v-model="email"
                label="Email"
                :error-messages="errors"
                outlined
                hide-details="auto"
                clearable
                clear-icon="clear"
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="3">
            <v-checkbox v-model="isEnabled">
              <template v-slot:label>
                <div>Actif</div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              color="button"
              class="button_color--text"
              type="submit"
            >
              <v-icon left>search</v-icon>Rechercher
            </v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <h1>{{ total }} résultat(s)</h1>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>

    <v-row>
      <v-col class="pt-10">
        <v-skeleton-loader
          v-if="firstLoad"
          :loading="loading"
          type="table-thead, table-tbody, table-tfoot"
        >
        </v-skeleton-loader>

        <v-data-table
          v-show="!firstLoad"
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          item-key="id"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100],
          }"
          class="elevation-3 row-pointer"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);

setInteractionMode("eager");

extend("max", {
  ...max,
  message: "La saisie est limitée à {length} caractères",
});

import axios from "axios";
export default {
  name: "MemberSearch",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    lastName: "",
    firstName: "",
    email: "",
    isEnabled: true,

    total: 0,

    users: [],

    success: false,
    message: [],
    errors: [],

    firstLoad: true,
    loading: true,

    options: {},

    firstPage: 0,
    sortBy: "firstname",
    sortDesc: false,

    /* Manage default fields values to post */
    sortField: "firstname",
    sortOrder: "ASC",

    headers: [
      { text: "Prénom", value: "firstname", sortable: true, align: "start" },
      { text: "Nom", value: "lastname", sortable: true },
      { text: "Email", value: "email", sortable: true },
      { text: "Actif", value: "isEnabled", sortable: true },
      { text: "Role", value: "role", sortable: false },
    ],
  }),
  created() {},
  mounted() {
  },
  computed: {},
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.userSearchResults().then((data) => {
        this.users = data.userList;
        this.total = data.total;
        this.$refs.userSearchObserver.setErrors(data.errors);
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      });
    },
    userSearchResults() {
      this.$refs.userSearchObserver.validate();

      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        // console.log('page = '+page+' itemsPerPage = '+itemsPerPage+' sortBy = '+sortBy+' sortDesc ='+sortDesc)

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
          this.firstPage = 0;
        } else {
          this.firstPage = (page - 1) * itemsPerPage;
        }

        /* Manage order by value to post for okayo request */
        if (sortDesc[0]) {
          this.sortOrder = "ASC";
        } else {
          this.sortOrder = "DESC";
        }

        /* Manage sort by value to post for okayo request */
        if (sortBy[0]) {
          this.sortField = sortBy[0];
        } else {
          this.sortField = this.sortField;
        }

        axios
          .post("/api/agent/member/search", {
            pageSize: itemsPerPage, //limit

            firstPage: this.firstPage, //offset
            // pageSize: 1000,//limit
            // firstPage: 0,//offset
            sortField: this.sortField,
            sortOrder: this.sortOrder,

            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            isEnabled: this.isEnabled,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)

            let userList = response.data.userList;
            const total = response.data.totalCount;

            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              userList,
              total,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>
