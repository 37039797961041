<template>
  <div>
    <validation-observer ref="documentSearchObserver" v-slot="{ invalid }">
      <v-form @submit.prevent="getDataFromApi">
        <v-row>
          <!-- <v-col cols="12" sm="4">

        <validation-provider
            v-slot="{ errors }"
            vid="title"
            name="Document"
            rules="max:50"
        >
            <v-text-field
              v-model="title"
              label="Document"
              :error-messages="errors"
              outlined
              hide-details
              clearable
              clear-icon="clear"
            >
        </v-text-field>
        </validation-provider>

      </v-col> -->

          <v-col cols="12" sm="6">
            <v-select
              v-model="folder"
              :items="folderList"
              item-value="folderId"
              item-text="folderTitle"
              label="Dossier"
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider
              v-slot="{ errors }"
              vid="name"
              name="Nom du fichier"
              rules="max:50"
            >
              <v-text-field
                v-model="name"
                label="Nom du fichier ou du document"
                :error-messages="errors"
                outlined
                hide-details
                clearable
                clear-icon="clear"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <v-switch
              v-model="isEnabled"
              :label="`Fichiers actifs ?`"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="3">
            <v-btn
              block
              large
              color="button"
              class="button_color--text"
              type="submit"
            >
              <v-icon left>search</v-icon>Rechercher
            </v-btn>
          </v-col>

          <v-col cols="12" sm="3"
            ><h1>{{ total }} résultat(s)</h1></v-col
          >

          <v-col cols="12" sm="3">
            <v-btn
              block
              large
              color="button"
              class="button_color--text"
              to="/file/search/add"
            >
              <v-icon left>add</v-icon>Ajouter un fichier
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>

    <v-row>
      <v-col class="pt-10">
        <v-skeleton-loader
          v-if="firstLoad"
          :loading="loading"
          type="table-thead, table-tbody, table-tfoot"
        >
        </v-skeleton-loader>

        <v-data-table
          v-show="!firstLoad"
          :headers="headers"
          :items="documents"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          item-key="id"
          @click:row="goToPage"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100],
          }"
          class="elevation-3 row-pointer"
        >
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon @click.stop="downloadFile(item)"
                    >file_download</v-icon
                  >
                </div>
              </template>
              Télécharger {{ item.name }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>

<script>
import { max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);

setInteractionMode("eager");

extend("max", {
  ...max,
  message: "La saisie est limitée à {length} caractères",
});

import axios from "axios";
export default {
  name: "DocumentSearch",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({
    folderList: [],

    folder: "",
    title: "",
    name: "",
    order: 0,
    isEnabled: true,

    total: 0,

    documents: [],

    success: false,
    message: [],
    errors: [],

    firstLoad: true,
    loading: true,

    options: {},

    firstPage: 0,
    sortBy: "title",
    sortDesc: true,

    /* Manage default fields values to post */
    sortField: "title",
    sortOrder: "ASC",

    headers: [
      { text: "Document", value: "title", align: "start", sortable: true },
      { text: "Dossier", value: "folder", sortable: true, align: "start" },
      { text: "Nom du fichier", value: "name", sortable: true, align: "start" },
      {
        text: "Télécharger le fichier",
        value: "action",
        sortable: false,
        align: "center",
      },

      // { text: 'Déplié par défaut', value: 'isExpanded', sortable: false },
      // { text: 'Actif', value: 'isEnabled', sortable: false },
    ],
  }),
  created() {},
  mounted() {},
  computed: {},
  methods: {
    goToPage(row) {
      this.$router.push("/file/search/" + row.id);
    },
    downloadFile(row) {
      this.downloadFileSubmit(row).then((data) => {
        this.folderList = data.folderList;
        this.id = data.id;
        this.folder = data.folder;
        this.title = data.title;
        this.name = data.name;
        this.content = data.content;
        this.order = data.order;
        this.isEnabled = data.isEnabled;

        // base64 string
        var base64str = this.content;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ""));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        // create the blob object
        var blob = new Blob([view]);
        var fileURL = URL.createObjectURL(blob);

        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.name);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    downloadFileSubmit(row) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/file/get", {
            id: row.id,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let folderList = response.data.folderList;

            let id = response.data.id;
            let folder = response.data.folder;
            let title = response.data.title;
            let name = response.data.name;
            let content = response.data.content;
            let order = response.data.order;
            let isEnabled = response.data.isEnabled;

            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              folderList,
              id,
              folder,
              title,
              name,
              content,
              order,
              isEnabled,

              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    getDataFromApi() {
      this.loading = true;
      this.documentSearchResults().then((data) => {
        this.folderList = data.folderList;
        this.documents = data.documents;
        this.total = data.total;
        this.$refs.documentSearchObserver.setErrors(data.errors);
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      });
    },
    documentSearchResults() {
      this.$refs.documentSearchObserver.validate();

      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        // console.log('page = '+page+' itemsPerPage = '+itemsPerPage+' sortBy = '+sortBy+' sortDesc ='+sortDesc)

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
          this.firstPage = 0;
        } else {
          this.firstPage = (page - 1) * itemsPerPage;
        }

        /* Manage order by value to post for okayo request */
        if (sortDesc[0]) {
          this.sortOrder = "ASC";
        } else {
          this.sortOrder = "DESC";
        }

        /* Manage sort by value to post for okayo request */
        if (sortBy[0]) {
          this.sortField = sortBy[0];
        } else {
          this.sortField = this.sortField;
        }

        axios
          .post("/api/file/search", {
            pageSize: itemsPerPage, //limit
            firstPage: this.firstPage, //offset
            sortField: this.sortField,
            sortOrder: this.sortOrder,

            id: "",
            folder: this.folder,
            // title: this.title,
            title: "",
            name: this.name,
            isEnabled: this.isEnabled,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)

            let folderList = response.data.folderList;

            let documents = response.data.documents;

            const total = response.data.totalCount;

            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              folderList,
              documents,
              total,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>
