import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
Vue.use(Vuex);
let authLogger = require('js-logger').get('Authentication')

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      firstName: "",
      lastName: "",
      userName: "",
      initials: "",
      email: "",
      roles: [],
      isEnabled: false,
      agency: {},
    },
  },
  mutations: {
    setUser(state, authData) {
      state.user = authData;
    },
  },
  actions: {
    async getAgency(context) {
      try {
        const response = await axios.post("/api/user/agency/get", {
          brokerCode: context.state.auth.data.user.userBrokerCode,
        });
        context.state.auth.data.user.agency = response.data.agency;
        if(response.data.success){
          authLogger.info('Code agence : ' + response.data.agency.code)
          return true
        }else {
          authLogger.warn('getAgency -> Failure')
          return false}
      } catch (error) {
        console.log(error);
        return false
      }
    },
  },
  modules: {
    auth,
  },
});
