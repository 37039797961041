<template lang="pug">
v-navigation-drawer.secondary(v-model='drawer' app clipped dark)
  v-list-item
    v-list-item-content
      v-list-item-title.text-h6 Administration
  v-divider
  v-list(dense)
    v-list-item(v-for="item in items", :key="item.title", :to="item.link", exact)
      v-list-item-icon
        v-icon {{ item.icon }}
      v-list-item-content
        v-list-item-title {{ item.title }}
</template>

<style scoped></style>

<script>
export default {
  data() {
    return {
      drawer: true,
      items: [
        //{ title: "Utilisateur", link: "/admin/users", icon: "manage_accounts" },
        { title: "Courtier", link: "/admin/brokers", icon: "business" },
        //{ title: "Roles", link: "/admin/roles", icon: "badge" },
      ],
      right: null,
    };
  },
};
</script>
