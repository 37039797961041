<template>
  <router-view />
</template>
<style></style>
<script>
import setupInterceptors from "@/helpers/interceptors";

export default {
  name: "App",
  components: {},
  created() {
    setupInterceptors();
  },
};
</script>
