<template>
  <v-card>
    <v-card-title>
      Garanties optionnelles éligible selon les formules
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <div class="text-h6 text-uppercase tertiary list_color--text text-center">
            Tiers simple | Tiers amélioré | Tous risque
          </div>
          
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col>
          <v-checkbox :true-value="true" :false-value="false" v-model="options.driverWarranty" hide-details
            color='button_light' :disabled="isTransformation">
            <template v-slot:label>
              <div>Garantie conducteur</div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col>
          <v-select :loading="loading" :disabled="loading || isTransformation" :items="itemsLists.assistances" item-text="libelle"
            item-value="code" v-model="options.assistance" label="Assistance" return-object outlined clearable
             />
        </v-col>
        <v-col>
          <v-checkbox :true-value="true" :false-value="false" v-model="options.trailer750kg" hide-details
            color='button_light' :disabled="isTransformation">
            <template v-slot:label>
              <div>Remorque +750 Kg</div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-h6 text-uppercase tertiary list_color--text text-center">
            Tiers amélioré | Tous risque
          </div>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col>
          <ValidationProvider v-slot="{ errors }" rules="required" name="Accessoires hors catalogue">
            <v-select :loading="loading" :disabled="loading || isTransformation" :items="itemsLists.accessoiresHorsCatalogue"
              item-text="libelle" item-value="code" v-model="options.accessoiresHorsCatalogue"
              label="Accessoires hors catalogue" return-object outlined required :error-messages="errors" />
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider v-slot="{ errors }" rules="required" name="Marchandises transportées">
            <v-select :loading="loading" :disabled="loading || isTransformation" :items="itemsLists.MarchandisesTransportees"
              item-text="libelle" item-value="code" v-model="options.marchandiseTransportees"
              label="Marchandises transportées" return-object outlined required :error-messages="errors" />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-h6 text-uppercase tertiary list_color--text text-center">
            Tous risques
          </div>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col>
          <v-checkbox v-model="options.isPerteFinanciere" :true-value="true" :false-value="false" hide-details
            color='button_light' :disabled="isTransformation">
            <template v-slot:label>
              <div>Perte financière *</div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<style scoped></style>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState, mapActions } from "vuex";
import Vue from 'vue';
import axios from "axios";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});


export default {
  name: "OptionalGuaranteesEligible",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ["isTransformation"],
  data: () => ({
    loading: true,
    marchandiseTransportees: 0,
    trailer750kg: false,
    assistance: 'ECO',
    driverWarranty: false,
    perteFinanciere: false,
    itemsLists: {
      accessoiresHorsCatalogue: [],
      MarchandisesTransportees: []
    },
  }),
  computed: {
    ...mapState({
      options: (state) => {
        if(state.transformation) {
          console.log("OPTIONS",state.transformation.estimate.productRisk.options);
          const options = state.transformation.estimate.productRisk.options;
          return   {
            ...options,
            driverWarranty:options.garantieConducteur,
            isPerteFinanciere: options.perteFinanciere,
            trailer750kg:options.remorquePlus750Kg,
          }
        }
        return state.estimation.estimate.productRisk.options ?? {}
      },
    })
  },
  methods: {},
  async mounted() {
    try {
      const responses = await axios
        .all([
          axios.get("/api/listItems/VALEUR_MTP_AHC"),
          axios.get("/api/listItems/VALEUR_MTP_AHC"),
          axios.get("/api/listItems/ASSISTANCES"),
        ]);
      this.itemsLists.accessoiresHorsCatalogue = responses[0].data.listItems;
      this.itemsLists.MarchandisesTransportees = responses[1].data.listItems;
      this.itemsLists.assistances = responses[2].data.listItems;
    } catch (error) {
      this.handleAxiosError(error);
    } finally {
      this.loading = false;
    }
  }
};
</script>
