<template>
    <v-container 
        fluid
        class="container"
    >
        <v-card
            class="gamutCard" 
            color="tertiary_light"
        >
            <v-card-text>
                <v-row>
                    <v-col v-show="this.$parent.deleteMode">
                        <v-checkbox
                            color="red"
                            v-model="$parent.selected.gamuts"
                            :value="gamut.id"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="gamut.title"
                            label="Nom de la Gamme"
                            outlined
                            hide-details
                        />
                    </v-col>
                    <v-col cols="2" >
                        <v-select
                            v-model="gamut.iconName"
                            :items="iconsList"
                            menu-props="auto"
                            label="Nom icone"
                            hide-details
                            :value="gamut.iconName"
                            :prepend-icon="gamut.iconName"
                            single-line
                            outlined
                            
                        ></v-select>
                       <!--  <v-text-field
                            v-model="gamut.iconName"
                            label="Nom icone"
                            
                        /> -->
                    </v-col >
                    <v-col offset="5" >
                        <v-btn
                            color="button"
                            x-large
                            icon
                            v-on:click="openGamutCard(gamut.id.toString())" 
                        >
                            <v-icon x-large>{{openedGamut ===  gamut.id.toString()  ? 'expand_less' : 'expand_more'}} </v-icon>
                        </v-btn>
                    </v-col> 
                </v-row>
            </v-card-text>
            <v-expand-transition> 
                <div class="cardExpansion" v-show="this.openedGamut === gamut.id.toString() ? true : false">
                    <v-row 
                        v-for="product in products"
                        :key="product.id"
                        style="margin:0;"
                    >
                        <Product :product="product" :openedProduct="openedProduct" :openProductCard="openProductCard" :filesData="filesData" :listItems="listItems"/>
                    </v-row>
                </div>
            </v-expand-transition>
        </v-card>
    </v-container>
</template>

<style scoped>
.cardExpansion {
    padding: 12px;
    padding-top: 0; 
}
.container {
    padding: 0 !important;
}

.gamutCard {
    margin: 5px;
}
</style>

<script>
import axios from "axios";
import Product from "./Product.vue";
export default {
  name: "Gamut",
  components: {
    Product
  },
  data: () => ({
        products: [],
        openedProduct: '',
        iconsList: [
            'directions_car',
            'maps_home_work',
            'verified_user',
            'monitor_heart',
            'local_library',
            'agriculture',
            'chair',
        ]
    }),
  props: {
      gamut: Object,
      openedGamut: String,
      openGamutCard: Function,
      filesData : Array,
      listItems : Array,
  },
    /** @todo add babel */
  created () {  
      
  },
  mounted () {
      this.products = this.gamut.products;
  },
  computed: {
  },
  methods: {
    openProductCard(productId) {
        this.openedProduct = this.openedProduct === productId.toString() ? '' : productId.toString();
    },
    addProduct() {
        let product = {
            'id' : "tmp_" + Math.floor(Math.random() * 5000),
            'gamutId' : this.gamut.id,
            'title' : '',
            'menuTitle' : '',
            'okayoEquivalent' : '',
            'unauthorizedBroker' : [],
            'productFiles' : [],
        }
        this.$set(this.products, this.products.length, product);
    },
  },
  watch: {
    products: function() {   
        if(this.products.length == 0) {
            this.addProduct();
        }
    },
    /* openedGamut: function (val, oldVal) {
        this.$refs['gamutCardExpand_' + oldVal].style = "display: none;"
    } */
  }
};
</script>
