<template>

  <div>

    <v-skeleton-loader
      v-if="firstLoad"
      type="card-heading, list-item@5"
      height="380"
      class="mt-5"
    >
    </v-skeleton-loader>
    <v-card
      v-show="!firstLoad"
    >

      <v-card-title v-html="pageTitle" />

      <v-card-subtitle>
        * Tous les champs sont obligatoires
      </v-card-subtitle>

      <validation-observer
        ref="pageObserver"
        v-slot="{ invalid }"
      >
        <v-form @submit.prevent="submitMyPage">

          <v-card-text>

            <input type="hidden" name="id" />

            <validation-provider
              v-slot="{ errors }"
              vid="category"
              name="Catégorie du menu"
              rules="required"
            >
            <v-select
                v-model="category"
                :items="menuCategory"
                item-value="categoryId"
                item-text="categoryTitle"
                label="Catégorie du menu *"
                outlined
                clearable

            ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              vid="title"
              name="Titre du menu et de la page"
              rules="required|max:50"
            >
              <v-text-field
                v-model="title"
                :counter="50"
                :error-messages="errors"
                label="Titre du menu et de la page *"
                required
              ></v-text-field>
            </validation-provider>

            <tiptap-vuetify
                v-model="content"
                :extensions="extensions"
                placeholder="Contenu de la page"
                class="pt-4"
            />

            <v-switch
                v-model="isEnabled"
                :label="`Activer la page ?`"
                :input-value="isEnabled"
                class="pt-4"
            ></v-switch>

          </v-card-text>

          <v-card-actions
            class="text-right"
          >
          <v-btn
            color="button"
            class="button_color--text"
            to="/page/search"
          >
            Annuler
          </v-btn>

            <v-btn
              :loading="loadingModifyPage"

              color="button"
              class="button_color--text"
              type="submit"
              :disabled="invalid"

            >
              enregistrer
            </v-btn>
          </v-card-actions>

      </v-form>

      </validation-observer>

    </v-card>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>

    </v-snackbar>

  </div>

</template>

<style scoped>
::v-deep .tiptap-vuetify-editor__content {
min-height: 500px;
}
</style>

<script>
import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
localize('fr', fr);

setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  // message: '{_field_} may not be greater than {length} characters',
})

// import tiptap component and the necessary extensions
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Image } from 'tiptap-vuetify'

import axios from "axios";

export default {

  name: "PageUpdate",

  components: {
    ValidationProvider,
    ValidationObserver,
    TiptapVuetify,
  },

  data: () => ({

    firstLoad: true,

    loadingModifyPage: false,

    snackbar: false,
    success: false,
    message: [],
    errors: [],

    // menuCategories: '',
    menuCategory: [],

    id: 0,
    category: '',
    title: '',
    content: '',
    isEnabled: false,

    todo: 'modify',

    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image
    ],

  }),
  created () {
  },
  mounted () {

    this.id = this.$route.params.id

    if (this.firstLoad) this.firstLoad = false

    this.getPage().then(data => {

        this.menuCategory = data.menuCategory

        this.id = data.id
        this.category = data.category
        this.title = data.title
        this.content = data.content
        this.isEnabled = data.isEnabled

        this.firstLoad = false
    })
  },
  computed: {
      pageTitle: function(){
          if ( this.id == 0 ) {
              return 'Ajouter une page'
          } else {
              return 'Modifier une page'
          }
      }
  },
  methods: {
      getPage() {

        return new Promise((resolve, reject) => {

          axios.post('/api/page/get', {

              id: this.id,

          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let menuCategory = response.data.menuCategory

            let id = response.data.id
            let category = response.data.category
            let title = response.data.title
            let content = response.data.content
            let isEnabled = response.data.isEnabled

            let success = response.data.success
            let message = response.data.message
            let errors = response.data.errors

            resolve({
                menuCategory,

                id,
                category,
                title,
                content,
                isEnabled,

              success,
              message,
              errors,
            })

          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log('error.response')
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log('error.request')
              console.log(error.request)
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error')
              console.log('Error', error.message)
            }
            console.log('error.config')
            console.log(error.config)

          });

        })
      },
    submitMyPage() {

      this.loadingModifyPage = true

      this.modifyMyPage().then(data => {

        if (this.firstLoad) this.firstLoad = false

        this.id = data.id
        this.category = data.category
        this.title = data.title
        this.content = data.content
        this.isEnabled = data.isEnabled

        this.success = data.success
        this.message = data.message

        // this.errors = data.errors
        this.$refs.pageObserver.setErrors( data.errors )

        this.todo = 'modify'
        if ( this.$route.params.id != this.id ) {
            this.$router.push('/page/search/'+this.id)
        }

        this.$root.$emit('NavigationDrawer')

        this.loadingModifyPage = false
        this.snackbar = true

      })
    },
    modifyMyPage() {

      this.$refs.pageObserver.validate()

      if ( this.id == 0 ) {
          this.todo = 'add'
      }
      if ( !this.isEnabled ) {
          this.isEnabled = false
      }

      return new Promise((resolve, reject) => {

        axios.post('/api/admin/page/'+this.todo, {
            id: this.id,
            category: this.category,
            title: this.title,
            content: this.content,
            isEnabled: this.isEnabled,
        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data);
          let id = response.data.id
          let category = response.data.category
          let title = response.data.title
          let content = response.data.content
          let isEnabled = response.data.isEnabled

          let success = response.data.success
          let message = response.data.message
          let errors = response.data.errors
          resolve({

              id,
              category,
              title,
              content,
              isEnabled,

            success,
            message,
            errors,
          })
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config')
          console.log(error.config)

        });

      })

    },
  },
  watch: {
  }
}
</script>
