<template>
  <div>
    <!-- Card Header content -->
    <v-skeleton-loader
      v-if="firstLoad"
      type="card-heading, list-item@2"
      class="mb-8"
    >
    </v-skeleton-loader>

    <v-card v-show="!firstLoad" v-model="client">
      <v-card-title class="text-h6 text-uppercase tertiary list_color--text">
        <v-icon class="list_color--text" left> contact_page </v-icon>
        {{ client.fullname }}
      </v-card-title>
    </v-card>

    <v-simple-table v-show="!firstLoad" class="mt-5">
      <template v-slot:default>
        <tbody>
          <template v-if="client.personType == 'PHY'">
            <tr>
              <td class="text-right">Civilité</td>
              <td
                class="font-weight-bold"
                v-text="client.civility.libelleLong"
              ></td>
              <td colspan="2" rowspan="5">
                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="36">
                    location_on
                  </v-icon>
                  <div class="text-h6">
                    {{ client.address.adress1 }}<br />
                    {{ client.address.zipCode }} {{ client.address.city }}
                  </div>
                </div>

                <br /><br />

                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="36"> email </v-icon>
                  <div class="text-h6">
                    {{ client.mail.mail }}
                  </div>
                </div>
                <br /><br />

                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="36"> call </v-icon>
                  <div class="text-h6">
                    {{ client.phone.number.match(/.{1,2}/g).join(' ') }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right">Date de naissance</td>
              <td class="font-weight-bold" v-text="formatDate(client.birthDate)"></td>
            </tr>
            <tr>
              <td class="text-right">Situation maritale</td>
              <td
                class="font-weight-bold"
                v-if="client.maritalStatus"
                v-text="client.maritalStatus.libelle"
              ></td>
            </tr>
            <tr>
              <td class="text-right">Catégorie socioprofessionnelle</td>
              <td
                class="font-weight-bold"
                v-if="client.socioProfessionalCategory"
                v-text="client.socioProfessionalCategory.libelle"
              ></td>
            </tr>
            <tr>
              <td class="text-right">Profession</td>
              <td class="font-weight-bold" v-text="client.profession"></td>
            </tr>
            <tr>
              <td class="text-right">Solde client TTC</td>
              <td class="font-weight-bold">{{ soldeTotalTTC }} {{ $currency }}</td>
            </tr>
          </template>
          <!-- Not phys personType -->
          <template v-else>
            <tr>
              <td class="text-right">Nom commercial</td>
              <td class="font-weight-bold" v-text="client.tradeName"></td>
              <td colspan="2" rowspan="5">
                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="36">
                    location_on
                  </v-icon>
                  <div class="text-h6">
                    {{ client.address.adress1 }}<br />
                    {{ client.address.zipCode }} {{ client.address.city }}
                  </div>
                </div>

                <br /><br />

                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="36"> email </v-icon>
                  <div class="text-h6">
                    {{ client.mail.mail }}
                  </div>
                </div>
                <br /><br />

                <div class="d-flex">
                  <v-icon left color="tertiary_light" size="36"> call </v-icon>
                  <div class="text-h6">
                    {{ client.phone.number.match(/.{1,2}/g).join(' ') }}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right">Forme juridique</td>
              <td class="font-weight-bold" v-text="client.legalStatus.libelle"></td>
            </tr>
            <tr>
              <td class="text-right">Code RIDET</td>
              <td class="font-weight-bold" v-text="client.siret"></td>
            </tr>
            <tr>
              <td class="text-right">Effectif</td>
              <td class="font-weight-bold" v-text="client.effectif"></td>
            </tr>
            <tr>
              <td class="text-right">Site web</td>
              <td class="font-weight-bold" v-text="client.webSite"></td>
            </tr>
            <tr>
              <td class="text-right">Solde client TTC</td>
              <td class="font-weight-bold">{{ soldeTotalTTC }} {{ $currency }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col class="pt-10">
        <v-skeleton-loader
          v-if="firstLoad"
          :loading="loading"
          type="table-thead, table-tbody, table-tfoot"
        >
        </v-skeleton-loader>

        <v-data-table
          v-show="!firstLoad"
          :headers="headers"
          :items="contracts"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          item-key="id"
          @click:row="rowClick"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100],
          }"
          class="elevation-3 row-pointer"
        >
        <template v-slot:item.dateEffet="{ item }">
            <span>{{ item.dateEffet !== "" ? formatDate(item.dateEffet) : item.dateEffet }}</span>
          </template>
        
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
::v-deep table tbody tr:hover {
  background-color: transparent !important;
}
::v-deep tbody tr :hover {
  cursor: pointer;
}
::v-deep td {
  /* border-bottom: none !important; */
}
</style>

<script>
import axios from "axios";
export default {
  name: "Client",
  data: () => ({
    firstLoad: true,

    idClient: 0,
    client: {
      civility: {},
      country: {},
    },

    total: 0,
    soldeTotalTTC: 0,
    contracts: [],

    loading: true,

    options: {},

    firstPage: 0,
    sortBy: "dateEffet",
    sortDesc: true,

    /* Manage default fields values to post */
    sortField: "dateEffet",
    sortOrder: "DESCENDING",

    headers: [
      {
        text: "Numéro de contrat ou devis",
        align: "start",
        sortable: false,
        value: "numero",
      },
      { text: "Produit", value: "productName", sortable: false },
      { text: "Statut", value: "status", sortable: false },
      { text: "Date d'effet", value: "dateEffet", sortable: false },
      // { text: "Echéance", value: "nextDate", sortable: false },
      // { text: "Tarif annuel", value: "annualPrime", sortable: false },
      { text: "Solde TTC", value: "solde", sortable: false },
      // { text: "Pièces en attente", value: "pendingParts", sortable: false },
    ],
  }),
  created() {},
  mounted() {
    this.idClient = this.$route.params.id;

    this.getClient().then((data) => {
      this.client = data.client;

      this.firstLoad = false;
    });

    this.customerContractSearch().then((data) => {
      this.contracts = data.items;
      this.total = data.total;
      this.soldeTotalTTC = data.soldeTotalTTC;
      this.loading = false;
    });
  },
  computed: {},
  methods: {
    rowClick(row) {
      this.goToContract(row);
    },
    goToContract(row) {
      this.$router.push("/contract/search/" + row.id);
    },
    getClient() {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/client/get", {
            idClient: this.idClient,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let client = response.data.person;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              client,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    customerContractSearch() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        // console.log('page = '+page+' itemsPerPage = '+itemsPerPage+' sortBy = '+sortBy+' sortDesc ='+sortDesc)

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
          this.firstPage = 0;
        } else {
          this.firstPage = (page - 1) * itemsPerPage;
        }

        /* Manage order by value to post for okayo request */
        if (sortDesc[0]) {
          this.sortOrder = "ASCENDING";
        } else {
          this.sortOrder = "DESCENDING";
        }

        /* Manage sort by value to post for okayo request */
        if (sortBy[0]) {
          this.sortField = sortBy[0];
        } else {
          this.sortField = this.sortField;
        }

        axios
          .post("/api/customer/contract/search", {
            pageSize: itemsPerPage, //limit

            firstPage: this.firstPage, //offset
            sortField: this.sortField,
            sortOrder: this.sortOrder,

            idPersonnes: this.idClient,
            numero: "",
            productCode: "",
            etatGlobal: "",
            getOnlyImpayes: false,

            dateEffetDu: "",
            dateEffetAu: "",
            dateCreationDu: "",
            dateCreationAu: "",

            nom: "",
            prenom: "",
            raisonSociale: "",
            getSolde: true,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let items = response.data.contractList;
            const total = parseInt(response.data.totalCount, 10); //string to int mandatory
            const soldeTotalTTC = response.data.soldeTotalTTC;

            resolve({
              items,
              total,
              soldeTotalTTC,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
  },
  watch: {},
};
</script>
