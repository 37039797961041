<template>

    <v-container
        fluid
        class="container"
    >

            <v-card>

                <v-card-title>
                  Modifier l'adresse email et le téléphone
                </v-card-title>

                <validation-observer
                    ref="mailTelobs"
                    v-slot="{ invalid }"
                >

                <form @submit.prevent="modifyEmailAndTel">

                    <v-card-text class="pa-5">

                        <validation-provider
                        v-slot="{ errors }"
                        vid="selectMail"
                        name="type d'email"
                        rules="required"
                        >
                        <v-select
                        v-model="selectMail"
                        :items="mailType"
                        item-text="libelle"
                        item-value="code"
                        placeholder="Type d'adresse email *"
                        return-object
                        :error-messages="errors"
                        outlined
                        clearable
                        hide-selected
                        ></v-select>
                        </validation-provider>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="email"
                        name="adresse email"
                        rules="required|email"
                        >
                        <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="Adresse email *"
                        outlined
                        ></v-text-field>
                        </validation-provider>

                        <v-switch
                        v-model="allContractEmail"
                        label="Modifier l'adresse email pour tous les contrats"
                        class="mt-0"
                        ></v-switch>

                        <validation-provider
                        v-slot="{ errors }"
                        vid="selectTel"
                        name="type de téléphone"
                        rules="required"
                        >
                        <v-select
                        v-model="selectTel"
                        :items="telType"
                        item-text="libelle"
                        item-value="code"
                        placeholder="Type de téléphone *"
                        return-object
                        :error-messages="errors"
                        outlined
                        clearable
                        hide-selected
                        ></v-select>
                        </validation-provider>

                        <p><vue-tel-input v-model="tel" v-bind="bindProps" @validate="telValidate"></vue-tel-input></p>

                        <!-- <validation-provider
                        v-slot="{ errors }"
                        vid="tel"
                        name="téléphone"
                        rules="required|isPhoneNumber"
                        >
                        <v-text-field
                        v-model="tel"
                        :error-messages="errors"
                        label="Téléphone *"
                        outlined
                        ></v-text-field>
                        </validation-provider> -->

                        <v-switch
                        v-model="allContractTel"
                        label="Modifier le téléphone pour tous les contrats"
                        class="mt-0"
                        ></v-switch>

                        <v-switch
                        v-model="smsAccepted"
                        label="J'accepte de recevoir les SMS"
                        class="mt-0"
                        ></v-switch>

                        </v-card-text>

                        <v-card-actions>

                            <v-spacer></v-spacer>

                            <v-btn
                            color=""
                            text
                            @click="closeDialog()"
                            >
                            fermer
                            </v-btn>

                            <v-btn
                            color="button"
                            class="button_color--text"
                            type="submit"
                            :disabled="invalid || !validNumber"
                            :loading="loadingUpdateMailTel"
                            >
                                <v-icon left>done</v-icon>Valider
                            </v-btn>

                        </v-card-actions>

                    </form>

                </validation-observer>

            </v-card>

        <v-snackbar
          v-model="snackbar"
          :color="message.type"
          :multi-line="true"
          timeout="3000"
        >
          {{ message.message }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="button_color--text"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Fermer
            </v-btn>
          </template>

        </v-snackbar>

    </v-container>

</template>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style>
.container {
    padding: 0 !important;
}
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
</style>

<script>
import { required, email } from 'vee-validate/dist/rules'

import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
localize('fr', fr);

setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
})

extend('email', {
  ...email,
   // message: '{_field_} n\'est pas un email',
})

extend('isPhoneNumber', {
    validate: (value) => {
        const regexFrance = /^[0-9]{10}$/;//+33
        const regexCal = /^[0-9]{6}$/;//+687
        const regexTahiti = /^[0-9]{8}$/;//+689
        if(value.match(regexCal) || value.match(regexFrance) || value.match(regexTahiti)) {
            return true;
        }
        return '{_field_} n\'est pas un numero de téléphone valide';
    }
});

import axios from "axios";
import { VueTelInput } from 'vue-tel-input';
// import 'vue-tel-input/vue-tel-input.css';

export default {
  name: "UpdateMailTel",

  components: {
    ValidationProvider,
    ValidationObserver,
    VueTelInput,
  },

  data: () => ({
    //snackbar
    snackbar: false,
    success: false,
    message: [],
    errors: [],

    //modif mail and tel
    loadingUpdateMailTel: false,

    mailType: [],
    selectMail: {
        libelle: "",
        code: ""
    },
    emailId: '',
    email: "",
    allContractEmail: false,

    telType: [],
    selectTel: {
        libelle: "",
        code: ""
    },
    telId: '',
    tel:"",
    validNumber: false,
    number: '',
    countryCallingCode: '',
    allContractTel:false,
    smsAccepted:false,
    bindProps: {
        required: true,
        autoFormat: true,
        onlyCountries: ["NC", "PF", "FR"],
        defaultCountry: "NC",
        placeholder: "Votre numéro de téléphone",
        mode: "international",
        inputOptions: {
            showDialCode: true
        },
        wrapperClasses: "country-phone-input",
        validCharactersOnly: true,
      }
  }),
  props: {
    contract: Object,
    getContractParent: Function,
  },
  created () {
  },
  mounted () {
    this.getMailTypes();
    this.getTelTypes();

    this.selectMail.code=this.contract.details.addresses.mail.type.code
    this.selectTel.code=this.contract.details.person.phone.type.code

    this.emailId=this.contract.details.addresses.mail.id
    this.email=this.contract.details.addresses.mail.mail

    this.telId=this.contract.details.addresses.phone.id
    this.tel=this.contract.details.addresses.phone.prefix+this.contract.details.addresses.phone.number
    this.smsAccepted = (this.contract.details.person.phone.acceptSms === 'true')
  },
  computed: {
  },
  methods: {
    telValidate(telnumber) {
        if (telnumber.valid) {
            this.validNumber = telnumber.valid
            this.number = telnumber.nationalNumber
            this.countryCallingCode = telnumber.countryCallingCode
        } else {
            this.validNumber = false;
            this.number = '';
            this.countryCallingCode = '';
        }
    },
    closeDialog: function(){
        this.$emit('close-dialog')
    },
    modifyEmailAndTel(){

        if(
            this.selectMail.code!=this.contract.details.person.mail.type.code
            || this.email != this.contract.details.addresses.mail.mail
            || this.selectTel.code!=this.contract.details.person.phone.type.code
            || this.tel.replaceAll(/\s/g,'')!=this.contract.details.addresses.phone.number.replaceAll(/\s/g,'')
            || this.smsAccepted!=(this.contract.details.person.phone.acceptSms === 'true')
            || this.allContractEmail
            || this.allContractTel
        ){

            this.$refs.mailTelobs.validate()

            this.loadingUpdateMailTel = true;

            //need to set code and libelle for post
            this.selectMail=this.mailType.find(o => o.code === this.selectMail.code);
            this.selectTel=this.telType.find(o => o.code === this.selectTel.code);

            var emailId = '';
            var emailType = [];
            var email = '';
            var allContractEmail = false;
            if (
                this.selectMail.code!=this.contract.details.addresses.mail.type.code
                || this.email != this.contract.details.addresses.mail.mail
                || this.allContractEmail
            ) {
                emailId = ''+this.contract.details.addresses.mail.id+'';
                emailType =this.selectMail;
                email = this.email;
                allContractEmail = this.allContractEmail;
            }

            var telId = '';
            var telType = [];
            var tel = '';
            var smsAccepted = false;
            var allContractTel = false;
            if (
                this.selectTel.code!=this.contract.details.person.phone.type.code
                || this.tel.replaceAll(/\s/g,'')!=this.contract.details.addresses.phone.number.replaceAll(/\s/g,'')
                || this.smsAccepted!=(this.contract.details.person.phone.acceptSms === 'true')
                || this.allContractTel
            ) {
                telId = ''+this.contract.details.addresses.phone.id+'';
                telType = this.selectTel;
                tel = this.tel;
                smsAccepted = this.smsAccepted;
                allContractTel = this.allContractTel;
            }

            axios.post("/api/client/update/email", {

                personId: ''+this.contract.details.person.id+'',
                contractId: ''+this.contract.details.id+'',

                emailId: emailId,
                emailType: emailType,
                email: email,
                allContractEmail: allContractEmail,

                telId: telId,
                telType: telType,
                tel: this.number,
                countryCallingCode: this.countryCallingCode,
                allContractTel: allContractTel,

                smsAccepted: smsAccepted,

                effectDate: this.contract.computed.maxStatusEffectDate,
            }).then((response)=>{

                this.getContractParent()

                this.message.type = response.data.type
                this.message.message = response.data.message

                this.loadingUpdateMailTel = false
                this.snackbar = true

                setTimeout( () => this.closeDialog(), 3500)
            })
        } else {
            this.success = false
            this.message.type = 'warning'
            this.message.message = 'Aucune donnée n\'a été modifiée'
            this.snackbar = true
        }
   },
    getMailTypes(){
        axios.post("/api/listItems/types/mail").then(countries=>{

        this.mailType=countries.data.listItems;

        }).catch((err) => { console.log('Axios Error:', err); })
    },
    getTelTypes(){
        axios.post("/api/listItems/types/tel").then(countries=>{

        this.telType=countries.data.listItems;
        }).catch((err) => { console.log('Axios Error:', err); })
    },
  },
  watch: {
  }
};
</script>
