<template>
<!--   <div>
    <v-row>
      <v-col cols="12"> -->
        <v-card>
          <v-card-title>Vos options :</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="niveau Franchise IDV"
                >
                  <v-select
                    :items="itemsLists.franchiseIDV"
                    return-object
                    v-model="options.franchiseIDV"
                    item-text="libelle"
                    item-value="code"
                    label="Franchise IDV"
                    outlined
                    :loading="loader"
                    :error-messages="errors"
                    :disabled="readonly"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="bris de glace"
                >
                  <v-select
                    :items="itemsLists.yesNo"
                    v-model="options.brisGlace"
                    return-object
                    item-text="libelle"
                    item-value="code"
                    label="Bris de glace"
                    outlined
                    :loading="loader"
                    :error-messages="errors"
                    :disabled="readonly"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider v-slot="{ errors }" rules="required" name="dezonage">
                  <v-select
                    :items="itemsLists.yesNo"
                    v-model="options.dezonage"
                    return-object
                    item-text="libelle"
                    item-value="code"
                    label="Dezonage"
                    outlined
                    :loading="loader"
                    :error-messages="errors"
                    :disabled="readonly"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <ValidationProvider v-slot="{ errors }" rules="required" name="protection juridique">
                  <v-select
                    :items="itemsLists.yesNo"
                    v-model="options.juridicProtection"
                    return-object
                    item-text="libelle"
                    item-value="code"
                    label="Protection Juridique"
                    outlined
                    :loading="loader"
                    :error-messages="errors"
                    :disabled="readonly"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider v-slot="{ errors }" rules="required" name="RC. Vie Privee">
                  <v-select
                    :items="itemsLists.yesNo"
                    v-model="options.rcViePrivee"
                    return-object
                    item-text="libelle"
                    item-value="code"
                    label="RC. Vie Privee"
                    outlined
                    :loading="loader"
                    :error-messages="errors"
                    :disabled="readonly"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="vol"
                >
                  <v-select
                    :items="itemsLists.yesNo"
                    v-model="options.vol"
                    label="Vol"
                    outlined
                    :loading="listsLoading"
                    :error-messages="errors"
                    :disabled="readonly"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
     <!--  </v-col>
    </v-row>
  </div> -->
</template>

<style scoped>

</style>

<script>
import { mapState, mapActions } from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});

export default {
  name: "Options MRH",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
  }),
  props: {
    readonly: {type: Boolean, default: false}
  },
  created() {
    this.getMrhOptsItemsLists();
  },
  mounted() {},
  computed: {
    ...mapState({
      options: (state) => state.productRisk.options,
      itemsLists: (state) => state.productRisk.itemsLists,
      loader: (state) => state.productRisk.loaders.mrhOptsLists,
    }),
  },
  methods: {
    ...mapActions("productRisk", [
      "getMrhOptsItemsLists",
    ]),
  },
};
</script>