<template>
  <v-container fluid class="container">
    <v-data-table
      :headers="this.headers"
      :items="this.data"
      item-key="number"
      :sort-by="this.options.sortBy"
      :sort-desc="this.options.sortDesc"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100],
      }"
      :search="search"
      single-expand
      :expanded.sync="expanded"
      show-expand
      class="elevation-3"
      @item-expanded="getClaim"
    >
      <!-- Décommenter pour remettre la feature détail d'un sinistre -->
      <!-- <template v-slot:item="{item, isExpanded, expand}">
        <tr :class="{'custom-highlight-row' : isExpanded}" class="claims-row-pointer" @click="expand(!isExpanded)">
          <td>
            <v-btn
              v-if="item.category!='Corporel'"
              icon 
              class="v-data-table__expand-icon"
              :class="{'v-data-table__expand-icon--active' : isExpanded}"
              :loading="loadingDetails[item.id]"
            >
              <v-icon>expand_more</v-icon>
            </v-btn>
          </td>
          <td>{{item.number}}</td>
          <td>{{item.occurenceDate}}</td>
          <td>{{item.openingDate}}</td>
          <td>{{item.state}}</td>
          <td>{{item.subState}}</td>
          <td>{{item.type}}</td>
          <td>{{item.category}}</td>
          <td>{{item.respConvention}}</td>
        </tr>
      </template> -->
      <template v-slot:item="{item, isExpanded, expand}">
        <tr :class="{'custom-highlight-row' : isExpanded}" class="claims-row-pointer">
          <td>&nbsp;</td>
          <td>{{item.number}}</td>
          <td>{{item.occurenceDate}}</td>
          <td>{{item.openingDate}}</td>
          <td>{{item.state}}</td>
          <td>{{item.subState}}</td>
          <td>{{item.type}}</td>
          <td>{{item.category}}</td>
          <td>{{item.respConvention}}</td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="elevation-10" :colspan="headers.length" v-if="!loadingDetails[item.id] && claim.afficher===true">
          <v-simple-table>
            <tbody>
              <tr>
                <td colspan="2" class="pa-0 pt-5">
                  <v-subheader class="text-h5">Informations générales</v-subheader>
                </td>
              </tr>
              <tr>
                <td class="text-right">Date et heure de survenance</td>
                <td class="font-weight-bold" v-text="item.occurenceDateTime"></td>
              </tr>
              <tr>
                <td class="text-right">Date et heure de déclaration</td>
                <td class="font-weight-bold" v-text="item.declarationDateTime"></td>
              </tr>
              <tr>
                <td class="text-right">Date et heure d'ouverture</td>
                <td class="font-weight-bold" v-text="item.openingDateTime"></td>
              </tr>
              <tr>
                <td class="text-right">Type</td>
                <td class="font-weight-bold" v-text="item.type"></td>
              </tr>
              <tr>
                <td class="text-right">Nature</td>
                <td class="font-weight-bold" v-text="claim.natureSinistre"></td>
              </tr>
              <tr>
                <td class="text-right">Circonstance du sinistre</td>
                <td class="font-weight-bold" v-text="claim.circonstanceSinistre"></td>
              </tr>
              <tr>
                <td class="text-right">Position</td>
                <td class="font-weight-bold" v-text="claim.position"></td>
              </tr>
              <tr>
                <td class="text-right">Classification</td>
                <td class="font-weight-bold" v-text="item.category"></td>
              </tr>
              <tr>
                <td class="text-right">Convention</td>
                <td class="font-weight-bold" v-text="claim.convention"></td>
              </tr>
              <tr>
                <td class="text-right">Cas de convention</td>
                <td class="font-weight-bold" v-text="claim.casDeConvention"></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table v-if="claim.garanties!=''">
            <tbody>
              <tr>
                <td colspan="4" class="pa-0 pt-5"><v-subheader class="text-h5">Garanties</v-subheader></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Code de la garantie</td>
                <td class="font-weight-bold">Garantie</td>
                <td class="font-weight-bold">Franchise</td>
              </tr>
              <tr v-for="item in claim.garanties">
                <td>{{ item.garantieCode }}</td>
                <td>{{ item.garantieLibelle }}</td>
                <td>{{ claim.garantieFranchise ? claim.garantieFranchise+" "+$currency : ""}}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table v-if="claim.intervenants!=''">
            <tbody>
              <tr>
                <td colspan="4" class="pa-0 pt-5"><v-subheader class="text-h5">Intervenants</v-subheader></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Nom</td>
                <td class="font-weight-bold">Rôle</td>
                <td class="font-weight-bold">Email</td>
                <td class="font-weight-bold">Tel</td>
              </tr>
              <tr v-for="item in claim.intervenants">
                <td>{{ item.nom }}</td>
                <td>{{ item.role }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.tel }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table v-if="claim.missionnements!=''">
            <tbody>
              <tr>
                <td colspan="5" class="pa-0 pt-5"><v-subheader class="text-h5">Missionnements</v-subheader></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Nom expert</td>
                <td class="font-weight-bold">Numéro de mission</td>
                <td class="font-weight-bold">Date de missionnement</td>
                <td class="font-weight-bold">Objet</td>
                <td class="font-weight-bold">Date de réception du rapport</td>
              </tr>
              <tr v-for="item in claim.missionnements">
                <td>{{ item.missionnementNom }}</td>
                <td>{{ item.missionnementNum }}</td>
                <td>{{ item.missionnementDate }}</td>
                <td>{{ item.missionnementObjet }}</td>
                <td>{{ item.missionnementDateReception }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table v-if="claim.reglements!=''">
            <tbody>
              <tr>
                <td colspan="3" class="pa-0 pt-5"><v-subheader class="text-h5">Règlements</v-subheader></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Destinataire</td>
                <!-- <td class="font-weight-bold">Rôle</td> -->
                <td class="font-weight-bold">Montant</td>
                <td class="font-weight-bold">Solde</td>
                <td class="font-weight-bold">Date de paiement prévue</td>
              </tr>
              <tr v-for="item in claim.reglements">
                <td>{{ item.destinataire }}</td>
                <!-- <td>{{ item.role }}</td> -->
                <td>{{ item.montant }} {{ $currency }}</td>
                <td>{{ item.solde }} {{ $currency }}</td>
                <td>{{ item.datePaiementPrevue }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <!-- <v-simple-table>
            <tbody>
              <tr>
                <td colspan="3" class="pa-0 pt-5"><v-subheader class="text-h5">Documents sortants</v-subheader></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Document</td>
                <td class="font-weight-bold">Date de création</td>
                <td class="font-weight-bold">&nbsp;</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table>
            <tbody>
              <tr>
                <td colspan="5" class="pa-0 pt-5"><v-subheader class="text-h5">Pièces</v-subheader></td>
              </tr>
              <tr>
                <td class="font-weight-bold">Nature</td>
                <td class="font-weight-bold">Etat</td>
                <td class="font-weight-bold">Sous-état</td>
                <td class="font-weight-bold">Date prévue</td>
                <td class="font-weight-bold">Fichiers</td>
              </tr>
            </tbody>
          </v-simple-table> -->
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
.container {
  padding: 0 !important;
}
.claims-row-pointer {
  display: table-row;
}
.claims-row-pointer:hover {
  cursor: pointer;
  background: #e0e0e0 !important; 
}
.custom-highlight-row {
  background-color: #e0e0e0 !important; 
}
</style>

<script>
import ClaimService from "@/services/claim.service";

export default {
  name: "claimsDatatable",
  data: () => ({
    expanded: [],
    singleExpand: true,
    loadingDetails: {},
    items: [],
    claim: {},
  }),
  props: {
    data: Array,
    headers: Array,
    search: String,
    options: {
        type: Object | Array,
        default: function () {
          return { sortBy: 'occurenceDateBrut', sortDesc: true }
        }
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    async getClaim({ item, value }) {
      this.loadingDetails[item.id] = true;
      if (value) {
        try {
          this.items = await ClaimService.get(item.id);
        } catch (error) {
          this.items.data = {};
          // alert(error.response.data.exception.message);
        }
      }
      this.claim = this.items.data;
      this.loadingDetails = {};
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'});
    },
  },
  watch: {},
};
</script>
