<template>
  <div>
    <v-stepper v-model="transformationStep" non-linear>
      <v-stepper-header>
        <v-stepper-step
          :complete="transformationStep > 1"
          step="1"
          :editable="transformationStep < 3 /*&& !createContractLoading && $store.state.transformation.contractId == ''*/"
        >
          Tarification du contrat
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="transformationStep > 2" step="2">
          Mode de paiement
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="transformationStep > 3"
          step="3"
          :editable="transformationStep > 2"
        >
          Primes
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="transformationStep > 4"
          :editable="transformationStep > 2"
          step="4"
        >
          Documents
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="transformationStep > 5"
          :editable="transformationStep > 2"
          step="5"
        >
          Pièces
        </v-stepper-step>
      </v-stepper-header>
     <div id="loading-wrapper" v-show="loading">
      <div id="loading-text">LOADING</div>
 <!--       <v-img
            id="loading-img"
            lazy-src=""
            max-height="48"
            max-width="48"
            :src="`/build/${$appName}/images/logo_transparent.png`"
          ></v-img> -->
      <div id="loading-content"></div>
    </div>
    <div v-show="!$store.state.transformation.hasBroker">
      <p class="ma-5">Merci de lier votre compte à un courtier afin de tarifer.</p>
    </div>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12">
              <PricingValidation
                v-if="!loading && $store.state.transformation.hasBroker "
                class="pt-5"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                block
                large
                color="button"
                class="button_color--text"
                @click="nextStep"
                :disabled="Object.keys(estimation).length == 0"
              >
                <v-icon left>right</v-icon> Suivant
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <PayementMethod v-if="!loading && $store.state.transformation.hasBroker" />
            </v-col>
          </v-row>
          <!-- <v-btn
          block
          large
          color="button"
          class="button_color--text"
          @click="nextStep"
        >
          <v-icon left>right</v-icon> Suivant
        </v-btn> -->
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12">
              <b class="ml-5" v-if="isContractCreated"
                >{{ contract.details.product.libelle }}: votre contrat n°<a
                  @click="goToCreatedContract"
                >
                  {{ contract.details.number }}
                </a>
                à bien été créé.</b
              >
            </v-col></v-row
          >
          <v-row>
            <v-col cols="12">
              <PrimesDataTable
                v-if="isContractCreated"
                :data="this.contract.primes.data"
                :headers="this.contract.primes.headers"
                :options="this.contract.primes.options"
                :getContractParent="getContractDetails"
                :contract="this.contract"
              /> </v-col
          ></v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                block
                large
                color="button"
                class="button_color--text"
                @click="nextStep"
              >
                <v-icon left>right</v-icon> Suivant
              </v-btn>
            </v-col></v-row
          >
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-row>
            <v-col cols="12">
              <EditicsDataTable
                v-if="isContractCreated"
                :data="this.contract.editiques.data"
                :headers="this.contract.editiques.headers"
                :options="this.contract.editiques.options"
                :getContractParent="getContractDetails"
                :contract="this.contract"
              /> </v-col
          ></v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                block
                large
                color="button"
                class="button_color--text"
                @click="nextStep"
              >
                <v-icon left>right</v-icon> Suivant
              </v-btn>
            </v-col></v-row
          >
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-row>
            <v-col cols="12" class="ma-3">
              Si vous avez procédé à une signature électronique, les pièces signées ne sont pas à ajouter manuellement, elles le seront automatiquement par le système dans quelques minutes.</v-col
          ></v-row>
          <v-row>
            <v-col cols="12">
              <PiecesDataTable
                v-if="isContractCreated"
                :data="this.contract.documents.data"
                :headers="this.contract.documents.headers"
                :options="this.contract.documents.options"
                :getContractParent="getContractDetails"
                :contract="this.contract"
              /> </v-col
          ></v-row>
          <v-row>
            <v-col cols="4" offset="8">
              <v-btn
                block
                large
                color="button"
                class="button_color--text"
                @click="$router.push('/')"
              >
                <v-icon left>home</v-icon> Retourner à l'accueil
              </v-btn>
            </v-col></v-row
          >
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-snackbar
      v-model="snackbar.state"
      :color="snackbar.content.type"
      :multi-line="true"
      :timeout="snackbar.content.type == 'succes' ? 1000 : 5000"
    >
      {{ snackbar.content.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}
/* #loading-img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  
  width: 100px;
  height: 100px;
  margin: -7px 0 0 -45px;
   text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px; 
} */

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #F00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
    -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>


<script>
import PricingValidation from "./PricingValidation.vue";
import PayementMethod from "../../components/PayementMethod.vue";
import PrimesDataTable from "../../components/PrimesDataTable.vue";
import EditicsDataTable from "../../components/EditicsDataTable.vue";
import PiecesDataTable from "../../components/PiecesDataTable.vue";
import transformationStoreModule from "../../plugins/vuex/transformation/transformationStoreModule";
import { mapState, mapActions } from "vuex";

const ibantools = require("ibantools");

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);

setInteractionMode("eager");

export default {
  name: "CreateContract",
  components: {
    PricingValidation,
    PayementMethod,
    PrimesDataTable,
    EditicsDataTable,
    PiecesDataTable,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    isContractCreated: false,
  }),
  props: {
    /* contract: Object, */
  },
  async created() {
    logger.info('Création du parcours de création contrat')
    logger.debug('Création de la vue CreateContract')
    if (this.$route.params.fromEstimation) {
      logger.debug('Depuis la création d\'un devis -> Etape 2')
      this.transformationStep = 2;
      this.getEstimate(this.$route.params.estimateId);
    } else {
      logger.debug('Depuis le détail d\'un devis')
      await this.getEstimate(this.$route.params.estimateId);
      this.getEstimation();
    }
  },

  beforeCreate() {
    this.$store.registerModule("transformation", transformationStoreModule);
    logger.debug('Module transformation ajouté au store VueX !')
  },
  beforeDestroy() {
    this.$store.unregisterModule("transformation");
    logger.debug('Module transformation supprimer du store VueX !')
    
  },
  mounted() {},
  computed: {
    ...mapState({
      step: (state) => state.transformation.transformationStep,
      loading: (state) => state.transformation.loader["getEstimate"] ?? true,
      snackbar: (state) => state.transformation.snackbar["transformation"],
      estimation: (state) => state.transformation.estimation,
      contract: (state) => state.transformation.contract,
      /* createContractLoading: (state) =>
        state.transformation.loader["createContract"], */
    }),
    transformationStep: {
      get: function () {
        return this.step;
      },
      set: function (v) {
        this.$store.commit("transformation/updTransformationStep", v);
      },
    },
  },
  methods: {
    ...mapActions("transformation", ["getEstimate", "getEstimation","getContractDetails"]),

    nextStep() {
      this.$store.commit("transformation/nextTransformationStep");
    },
    goToCreatedContract() {
      this.$router.push("/contract/search/" + this.contract.details.id);
    },
  },
  watch: {
    contract: {
      handler: function () {
        this.isContractCreated = true;
      },
      deep: true,
    },
  },
};
</script>
