<template lang="pug">
  div
    v-card.mt-8
      v-card-title Changer mon mot de passe
      v-card-subtitle
        | Modifier mon mot de passe actuel pour un nouveau. Au minimum 8 caractères. Avec au moins un caractère spécial (!,%,&,@,#,$,^,*,?,_,~),
        | une minuscule, une majuscule et un chiffre
      validation-observer(ref='passwordObserver' v-slot='{ invalid }')
        v-card-text
          input(type='hidden' name='user.id')
          validation-provider(v-slot='{ errors }'
            vid='password'
            name='nouveau mot de passe'
            rules='required|min:8|max:30|customPassword|notContainsForbiddenChars|confirmed:confirmedPassword')
            v-text-field(
              v-model='password'
              :error-messages='errors'
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append='showPassword = !showPassword'
              :counter='8'
              label='Nouveau mot de passe')
          validation-provider(v-slot='{ errors }' vid='confirmedPassword' name='confirmez votre nouveau mot de passe')
            v-text-field(
              v-model='confirmedPassword'
              :error-messages='errors'
              :append-icon="showConfirmedPassword ? 'visibility' : 'visibility_off'"
              :type="showConfirmedPassword ? 'text' : 'password'"
              @click:append='showConfirmedPassword = !showConfirmedPassword'
              label='Confirmez votre nouveau mot de passe'
            )
        v-card-actions.text-right
          v-btn.button_color--text(:loading='loading' color='button' @click='updateMyPassword' :disabled='invalid') modifier mon mot de passe actuel
    SnackBar(v-bind:show='showSnackBar' v-bind:message='message')
</template>

<script>
import { required, confirmed, max, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";

localize("fr", fr);

setInteractionMode("eager");

extend("required", required);

extend("confirmed", confirmed);

extend("max", max);

extend("min", min);

/// create custom error message for custom rule
extend("customPassword", {
  message: () =>
    `Le mot de passe doit contenir au minimum 8 caractères. Avec au moins un caractère spécial (!, %, &, @, #, $, ^, *, ?, _, ~), une minuscule, une majuscule et un chiffre`,
  validate: (value) => {
    const notTheseChars = /["'?&/<>\s]/;
    const mustContainTheseChars =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$%^*-]).{8,}$/;
    const containsForbiddenChars = notTheseChars.test(value);
    const containsRequiredChars = mustContainTheseChars.test(value);
    return containsRequiredChars && !containsForbiddenChars;
  },
});

extend("notContainsForbiddenChars", {
  message: () =>
    `Le mot de passe contient des caractères interdits:  " ' ? & / < > ou des espaces.`,
  validate: (value) => {
    const notTheseChars = /["'?&/<>\s]/;
    const containsForbiddenChars = notTheseChars.test(value);
    return !containsForbiddenChars;
  },
});

import UserService from "@/services/account.service";
import SnackBar from "@/components/common/SnackBar";

export default {
  name: "PasswordForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar,
  },
  data: () => ({
    showSnackBar: false,
    message: {
      type: "error",
      message: "test",
    },
    loading: false,
    user: {},
    showPassword: false,
    showConfirmedPassword: false,
    password: "",
    confirmedPassword: "",
  }),
  methods: {
    async updateMyPassword() {
      this.showSnackBar = false;
      try {
        this.loading = true;
        let response = await UserService.updateMyPassword(
          this.password,
          this.confirmedPassword
        );
        this.message = {
          type: response.success ? "success" : "error",
          message: response.message,
        };
        this.loading = false;
      } catch (error) {
        this.message = {
          type: "error",
          message: error.response.data.message,
        };
        console.error(error);
      } finally {
        this.loading = false;
        this.showSnackBar = true;
      }
    },
  },
};
</script>
