import NakedLayout from "@/components/layout/NakedLayout";
import Login from "@/views/authentification/Login";
import ResetPassword from "@/views/reset-password/ResetPassword";
import RequestResetPassword from "@/views/reset-password/RequestResetPassword";
import Thankyou from "@/views/Thankyou";

import store from "@/store";

const PublicRoute = {
  path: "/",
  component: NakedLayout,
  children: [
    { path: "/login", name: "Authentification", component: Login },
    {
      path: "/logout",
      name: "Logout",
      component: {
        beforeRouteEnter(to, from, next) {
          store.dispatch("auth/logout");
          next("/login");
        },
      },
    },
    {
      path: "/reset/password",
      name: "RequestResetPassword",
      component: RequestResetPassword,
    },
    {
      path: "/reset/password/:token",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
      path: "/thankyou",
      name: "Thank you",
      component: Thankyou,
    },
  ],
};
export default PublicRoute;
