import axios from "axios";

const API_URL = "/api/reset-password";

class ResetPasswordService {
  async createRequest(email) {
    let res = await axios({
      method: "post",
      url: API_URL,
      data: { email },
    });
    return res.data;
  }

  async checkToken(token) {
    let res = await axios({
      method: "get",
      url: API_URL + `/check-token/${token}`,
      data: { token },
    });
    return res.data;
  }

  async resetMyPassword(token, password, confirmedPassword) {
    let res = await axios({
      method: "post",
      url: API_URL + `/reset/${token}`,
      data: { token, password, confirmedPassword },
    });
    return res.data;
  }
}

export default new ResetPasswordService();
