const Logger = require('js-logger');

Logger.useDefaults({
    defaultLevel: Logger.TRACE,
});

if(process.env.NODE_ENV == 'production') {
    Logger.setLevel(Logger.OFF);
   
} else {
    /** @todo Réussir aà passer un log level par l'env */
    //Logger.setLevel(Logger[process.env.JS_LOGGER_LEVEL]);
}