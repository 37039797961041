var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.firstLoad)?_c('v-skeleton-loader',{staticClass:"mt-5",attrs:{"type":"card-heading, list-item@5","height":"380"}}):_vm._e(),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.firstLoad),expression:"!firstLoad"}]},[_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.pageTitle)}}),_c('v-card-subtitle',[_vm._v(" * Tous les champs sont obligatoires ")]),_c('validation-observer',{ref:"pageObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitMyPage.apply(null, arguments)}}},[_c('v-card-text',[_c('input',{attrs:{"type":"hidden","name":"id"}}),_c('validation-provider',{attrs:{"vid":"category","name":"Catégorie du menu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.menuCategory,"item-value":"categoryId","item-text":"categoryTitle","label":"Catégorie du menu *","outlined":"","clearable":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":"title","name":"Titre du menu et de la page","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":50,"error-messages":errors,"label":"Titre du menu et de la page *","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)}),_c('tiptap-vuetify',{staticClass:"pt-4",attrs:{"extensions":_vm.extensions,"placeholder":"Contenu de la page"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('v-switch',{staticClass:"pt-4",attrs:{"label":"Activer la page ?","input-value":_vm.isEnabled},model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}})],1),_c('v-card-actions',{staticClass:"text-right"},[_c('v-btn',{staticClass:"button_color--text",attrs:{"color":"button","to":"/page/search"}},[_vm._v(" Annuler ")]),_c('v-btn',{staticClass:"button_color--text",attrs:{"loading":_vm.loadingModifyPage,"color":"button","type":"submit","disabled":invalid}},[_vm._v(" enregistrer ")])],1)],1)]}}])})],1),_c('v-snackbar',{attrs:{"color":_vm.message.type,"multi-line":true,"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"button_color--text","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }