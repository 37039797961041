<template>

  <div>

    <v-skeleton-loader
      v-if="firstLoad"
      type="card-heading, list-item@5"
      height="380"
      class="mt-5"
    >
    </v-skeleton-loader>
    <v-card
      v-show="!firstLoad"
    >

      <v-card-title v-html="documentTitle" />

      <v-card-subtitle>
        * Tous les champs sont obligatoires
      </v-card-subtitle>

      <validation-observer
        ref="documentObserver"
        v-slot="{ invalid }"
      >
        <v-form @submit.prevent="submitMyDocument">

          <v-card-text>

            <input type="hidden" name="id" />

            <validation-provider
              v-slot="{ errors }"
              vid="folder"
              name="Dossier du fichier"
              rules="required"
            >
            <v-select
                v-model="folder"
                :items="folderList"
                item-value="folderId"
                item-text="folderTitle"
                label="Dossier du fichier *"
                outlined
                clearable

            ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              vid="title"
              name="Document"
              rules="required|max:50"
            >
              <v-text-field
                v-model="title"
                :counter="50"
                :error-messages="errors"
                label="Document *"
                required
                clearable
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-if="name"
              v-slot="{ errors }"
              vid="fileInput"
              name="Fichier"
            >
            <v-file-input
            v-model="fileInput"
            @change="checkInput"
            label="Fichier *"
            accept="image/*, .pdf"
            prepend-icon=""
            prepend-inner-icon="attach_file"
            small-chips
            clearable
            counter
            show-size
            truncate-length="15"
            required
            >
            <template v-slot:label>
            {{ name }}
            </template>
            </v-file-input>
            </validation-provider>

            <validation-provider
              v-else
              v-slot="{ errors }"
              vid="fileInput"
              name="Fichier"
              rules="required"
            >
            <v-file-input
            v-model="fileInput"
            @change="checkInput"
            :rules="rules"
            :error-messages="errors"
            label="Fichier *"
            accept="image/*, .pdf"
            prepend-icon=""
            prepend-inner-icon="attach_file"
            small-chips
            clearable
            counter
            show-size
            truncate-length="15"
            required
            >
            </v-file-input>
            </validation-provider>

            <v-switch
                v-model="isEnabled"
                :label="`Activer le fichier ?`"
                :input-value="isEnabled"
                class="pt-4"
            ></v-switch>

          </v-card-text>

          <v-card-actions
            class="text-right"
          >
          <v-btn
            color="button"
            class="button_color--text"
            to="/file/search"
          >
            Annuler
          </v-btn>

            <v-btn
              :loading="loadingModifyDocument"

              color="button"
              class="button_color--text"
              type="submit"
              :disabled="invalid"

            >
              enregistrer
            </v-btn>

            <v-btn
              :loading="loadingRemoveDocument"
              color="button"
              class="button_color--text"
              @click.stop="dialog = true"
              :disabled="id == 0"
            >
              supprimer
            </v-btn>

          </v-card-actions>

      </v-form>

      </validation-observer>

    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          Suppression de fichier ?
        </v-card-title>

        <v-card-text>
            Vous êtes sur le point de supprimer un fichier. Cette action est irréversible. Etes-vous certain de vouloir le faire ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
          color="button"

            text
            @click="dialog = false"
          >
            Non
          </v-btn>

          <v-btn
            color="button"
            text
            @click.stop="submitRemoveMyDocument"
          >
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>

    </v-snackbar>

  </div>

</template>

<style scoped>
</style>

<script>
import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
localize('fr', fr);

setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  // message: '{_field_} may not be greater than {length} characters',
})

import axios from "axios";

export default {

  name: "DocumentUpdate",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({

    firstLoad: true,

    loadingModifyDocument: false,
    loadingRemoveDocument: false,

    snackbar: false,
    success: false,
    message: [],
    errors: [],

    folderList: [],

    id: 0,
    folder: '',
    title: '',
    name: '',
    content: '',
    fileInput: [],
    order: 0,
    isEnabled: false,

    todo: 'modify',

    rules: [
        (value) =>
        !value ||
        value.size < 20_971_520 ||
        "La taille du fichier ne peut pas dépasser 20Mo",
    ],

    dialog: false,

  }),
  created () {
  },
  mounted () {

    this.id = this.$route.params.id

    if (this.firstLoad) this.firstLoad = false

    this.getDocument().then(data => {

        this.folderList = data.folderList

        this.id = data.id
        this.folder = data.folder
        this.title = data.title
        this.name = data.name
        this.order = data.order
        this.isEnabled = data.isEnabled

        this.firstLoad = false
    })
  },
  computed: {
      documentTitle: function(){
          if ( this.id == 0 ) {
              return 'Ajouter un fichier'
          } else {
              return 'Modifier un fichier'
          }
      }
  },
  methods: {
      getDocument() {

        return new Promise((resolve, reject) => {

          axios.post('/api/file/get', {

              id: this.id,

          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let folderList = response.data.folderList

            let id = response.data.id
            let folder = response.data.folder
            let title = response.data.title
            let name = response.data.name
            let order = response.data.order
            let isEnabled = response.data.isEnabled

            let success = response.data.success
            let message = response.data.message
            let errors = response.data.errors

            resolve({

                folderList,

                id,
                folder,
                title,
                name,
                order,
                isEnabled,

              success,
              message,
              errors,
            })

          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log('error.response')
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log('error.request')
              console.log(error.request)
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error')
              console.log('Error', error.message)
            }
            console.log('error.config')
            console.log(error.config)

          });

        })
      },
    getBase64( file ) {
        return new Promise(( resolve, reject ) => {
        const reader = new FileReader()
        reader.readAsDataURL( file )
        reader.onload = () => resolve( reader.result.split(',')[1] )
        reader.onerror = error => reject( error )
        })
    },
    checkInput() {
        var file = document.querySelector('input[type=file]').files[0]
        if ( file ) {
            this.name = file.name
            this.getBase64( file ).then(( value ) => {
                this.content = value
            })
        }
    },
    submitMyDocument() {

      this.loadingModifyDocument = true

      this.modifyMyDocument().then(data => {

        if (this.firstLoad) this.firstLoad = false

        this.id = data.id
        // this.folder = data.folder
        this.title = data.title
        this.name = data.name
        this.order = data.order
        this.isEnabled = data.isEnabled

        this.success = data.success
        this.message = data.message

        // this.errors = data.errors
        this.$refs.documentObserver.setErrors( data.errors )

        this.todo = 'modify'
        if ( this.$route.params.id != this.id ) {
            this.$router.push('/file/search/'+this.id)
        }

        this.loadingModifyDocument = false
        this.snackbar = true

        setTimeout( () => this.$router.push('/file/search'), 3000);

      })
    },
    modifyMyDocument() {

      this.$refs.documentObserver.validate()

      if ( this.id == 0 ) {
          this.todo = 'add'
      }
      if ( !this.isEnabled ) {
          this.isEnabled = false
      }

      let formData = new FormData();

      formData.append( 'id', this.id )
      formData.append( 'folder', this.folder )
      formData.append( 'title', this.title )
      formData.append( 'name', this.name )
      formData.append( 'file', this.content )
      formData.append( 'order', 0 )
      formData.append( 'isEnabled', this.isEnabled )

      return new Promise((resolve, reject) => {

        axios.post('/api/admin/file/'+this.todo, formData)
        .then((response) => {
          // Success 🎉
          // console.log(response.data);
          let id = response.data.id
          let folder = response.data.folder
          let title = response.data.title
          let name = response.data.name
          let order = response.data.order
          let isEnabled = response.data.isEnabled

          let success = response.data.success
          let message = response.data.message
          let errors = response.data.errors
          resolve({

              id,
              folder,
              title,
              name,
              order,
              isEnabled,

            success,
            message,
            errors,
          })
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config')
          console.log(error.config)

        });

      })

    },
    submitRemoveMyDocument() {

        this.dialog = false

      this.loadingRemoveDocument = true

      this.removeMyDocument().then(data => {

        if (this.firstLoad) this.firstLoad = false

        this.id = data.id

        this.success = data.success
        this.message = data.message

        // this.errors = data.errors
        this.$refs.documentObserver.setErrors( data.errors )

        this.loadingRemoveDocument = false
        this.snackbar = true

        setTimeout( () => this.$router.push('/file/search'), 3000);

      })
    },
    removeMyDocument() {

      this.$refs.documentObserver.validate()

      return new Promise((resolve, reject) => {

        axios.post('/api/admin/file/remove', {
            id: this.id,
        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data);
          let id = response.data.id

          let success = response.data.success
          let message = response.data.message
          let errors = response.data.errors
          resolve({

              id,

            success,
            message,
            errors,
          })
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config')
          console.log(error.config)

        });

      })

    },
  },
  watch: {
  }
}
</script>
