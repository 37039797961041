import Vue from "vue";
import VueRouter from "vue-router";

import DefaultLayout from "@/components/layout/DefaultLayout";
import PublicRoute from "@/router/public.route";
import AdminRoute from "@/router/administration.route";
import AppRoute from "@/router/app.route";

Vue.use(VueRouter);

const routes = [
  AppRoute,
  AdminRoute,
  PublicRoute,
  { path: "*", component: DefaultLayout },
];

export default new VueRouter({
  mode: "history",
  routes,
});
