import axios from "axios";

const API_URL = "/api/admin/brokers";

class BrokerService {
  async create(broker) {
    const res = await axios({
      method: "post",
      url: API_URL,
      data: broker,
    });
    return res.data;
  }

  async get(idBroker) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idBroker,
    });
    return res.data;
  }

  async list(page, nbItemsPerPage, sortOption, search) {
    const res = await axios({
      method: "get",
      url: API_URL,
      params: { page, nbItemsPerPage, sortOption, search },
    });
    return res.data;
  }

  async remove(idBroker) {
    const res = await axios({
      method: "delete",
      url: API_URL + "/" + idBroker,
    });
    return res.data;
  }

  async update(idBroker, broker) {
    const res = await axios({
      method: "put",
      url: API_URL + "/" + idBroker,
      data: broker,
    });
    return res.data;
  }
  async listUsers(idBroker) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idBroker + "/" + "users",
    });
    return res.data;
  }

  async addUser(idBroker, idUser) {
    const res = await axios({
      method: "post",
      url: API_URL + "/" + idBroker + "/" + "users",
      data: { idUser },
    });
    return res.data;
  }

  async removeUser(idBroker, idUser) {
    const res = await axios({
      method: "delete",
      url: API_URL + "/" + idBroker + "/" + "users",
      data: { idUser },
    });
    return res.data;
  }
}

export default new BrokerService();
