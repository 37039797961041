<template>

  <div>

    <v-skeleton-loader
      v-if="firstLoad"
      type="card-heading, list-item@5"
      height="380"
      class="mt-5"
    >
    </v-skeleton-loader>
    <v-card
      v-show="!firstLoad"
    >

      <v-card-title v-html="folderTitle" />

      <v-card-subtitle>
        * Tous les champs sont obligatoires
      </v-card-subtitle>

      <validation-observer
        ref="folderObserver"
        v-slot="{ invalid }"
      >
        <v-form @submit.prevent="submitMyFolder">

          <v-card-text>

            <input type="hidden" name="id" />

            <validation-provider
              v-slot="{ errors }"
              vid="title"
              name="Titre du dossier"
              rules="required|max:50"
            >
              <v-text-field
                v-model="title"
                :counter="50"
                :error-messages="errors"
                label="Titre du dossier *"
                required
              ></v-text-field>
            </validation-provider>

            <v-switch
                v-model="isEnabled"
                :label="`Activer le dossier ?`"
                :input-value="isEnabled"
                class="pt-4"
            ></v-switch>

          </v-card-text>

          <v-card-actions
            class="text-right"
          >
          <v-btn
            color="button"
            class="button_color--text"
            to="/folder/search"
          >
            Annuler
          </v-btn>

            <v-btn
              :loading="loadingModifyFolder"

              color="button"
              class="button_color--text"
              type="submit"
              :disabled="invalid"

            >
              enregistrer
            </v-btn>

            <v-btn
              :loading="loadingRemoveFolder"
              color="button"
              class="button_color--text"
              @click.stop="dialog = true"
              :disabled="id == 0"
            >
              supprimer
            </v-btn>

          </v-card-actions>

      </v-form>

      </validation-observer>

    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5">
          Suppression de dossier ?
        </v-card-title>

        <v-card-text>
            Vous êtes sur le point de supprimer un dossier <strong>ainsi que tous les fichiers qui y sont rattachés</strong>. Cette action est irréversible. Etes-vous certain de vouloir le faire ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
          color="button"

            text
            @click="dialog = false"
          >
            Non
          </v-btn>

          <v-btn
            color="button"
            text
            @click.stop="submitRemoveMyFolder"
          >
            Oui
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>

    </v-snackbar>

  </div>

</template>

<style scoped>
</style>

<script>
import { required, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

import { localize } from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';
localize('fr', fr);

setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  // message: '{_field_} may not be greater than {length} characters',
})

import axios from "axios";

export default {

  name: "FolderUpdate",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({

    firstLoad: true,

    loadingModifyFolder: false,
    loadingRemoveFolder: false,

    snackbar: false,
    success: false,
    message: [],
    errors: [],

    id: 0,
    title: '',
    order: 0,
    isExpanded: false,
    isEnabled: false,

    todo: 'modify',

    dialog: false,

  }),
  created () {
  },
  mounted () {

    this.id = this.$route.params.id

    if (this.firstLoad) this.firstLoad = false

    this.getFolder().then(data => {

        this.id = data.id
        this.title = data.title
        this.order = data.order
        this.isExpanded = data.isExpanded
        this.isEnabled = data.isEnabled

        this.firstLoad = false
    })
  },
  computed: {
      folderTitle: function(){
          if ( this.id == 0 ) {
              return 'Ajouter un dossier'
          } else {
              return 'Modifier un dossier'
          }
      }
  },
  methods: {
      getFolder() {

        return new Promise((resolve, reject) => {

          axios.post('/api/admin/folder/get', {

              id: this.id,

          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let menuCategory = response.data.menuCategory

            let id = response.data.id
            let title = response.data.title
            let order = response.data.order
            let isExpanded = response.data.isExpanded
            let isEnabled = response.data.isEnabled

            let success = response.data.success
            let message = response.data.message
            let errors = response.data.errors

            resolve({
                menuCategory,

                id,
                title,
                order,
                isExpanded,
                isEnabled,

              success,
              message,
              errors,
            })

          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log('error.response')
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log('error.request')
              console.log(error.request)
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error')
              console.log('Error', error.message)
            }
            console.log('error.config')
            console.log(error.config)

          });

        })
      },
    submitMyFolder() {

      this.loadingModifyFolder = true

      this.modifyMyFolder().then(data => {

        if (this.firstLoad) this.firstLoad = false

        this.id = data.id
        this.title = data.title
        this.order = data.order
        this.isExpanded = data.isExpanded
        this.isEnabled = data.isEnabled

        this.success = data.success
        this.message = data.message

        // this.errors = data.errors
        this.$refs.folderObserver.setErrors( data.errors )

        this.todo = 'modify'
        if ( this.$route.params.id != this.id ) {
            this.$router.push('/folder/search/'+this.id)
        }

        this.loadingModifyFolder = false
        this.snackbar = true
        setTimeout( () => this.$router.push('/folder/search'), 3000);

      })
    },
    modifyMyFolder() {

      this.$refs.folderObserver.validate()

      if ( this.id == 0 ) {
          this.todo = 'add'
      }
      if ( !this.isEnabled ) {
          this.isEnabled = false
      }

      return new Promise((resolve, reject) => {

        axios.post('/api/admin/folder/'+this.todo, {
            id: this.id,
            title: this.title,
            order: 0,
            isExpanded: true,
            isEnabled: this.isEnabled,
        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data);
          let id = response.data.id
          let title = response.data.title
          let order = response.data.order
          let isExpanded = response.data.isExpanded
          let isEnabled = response.data.isEnabled

          let success = response.data.success
          let message = response.data.message
          let errors = response.data.errors
          resolve({

              id,
              title,
              order,
              isExpanded,
              isEnabled,

            success,
            message,
            errors,
          })
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config')
          console.log(error.config)

        });

      })

    },
    submitRemoveMyFolder() {

        this.dialog = false

      this.loadingRemoveFolder = true

      this.removeMyFolder().then(data => {

        if (this.firstLoad) this.firstLoad = false

        this.id = data.id

        this.success = data.success
        this.message = data.message

        // this.errors = data.errors
        this.$refs.folderObserver.setErrors( data.errors )

        this.loadingRemoveFolder = false
        this.snackbar = true

        setTimeout( () => this.$router.push('/folder/search'), 3000);

      })
    },
    removeMyFolder() {

      this.$refs.folderObserver.validate()

      return new Promise((resolve, reject) => {

        axios.post('/api/admin/folder/remove', {
            id: this.id,
        })
        .then((response) => {
          // Success 🎉
          // console.log(response.data);
          let id = response.data.id

          let success = response.data.success
          let message = response.data.message
          let errors = response.data.errors
          resolve({

              id,

            success,
            message,
            errors,
          })
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config')
          console.log(error.config)

        });

      })

    },
  },
  watch: {
  }
}
</script>
