<template>

  <div>

    <v-skeleton-loader
      v-if="firstLoad"
      type="card-heading, list-item@5"
      height="380"
      class="mt-5"
      elevation="0"
    >
    </v-skeleton-loader>

    <h1 v-html="title"></h1><br />
    <v-sheet
    v-show="!firstLoad"
    elevation="0"
    >
        <div v-html="content"></div>
    </v-sheet>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>

    </v-snackbar>

  </div>

</template>

<style scoped>
::v-deep hr { margin-bottom: 15px; }
</style>

<script>
import axios from "axios";

export default {

  name: "PageView",

  data: () => ({

    firstLoad: true,

    loadingModifyPage: false,

    snackbar: false,
    success: false,
    message: [],
    errors: [],

    // menuCategories: '',
    menuCategory: [],

    id: 0,
    category: '',
    title: '',
    content: '',
    isEnabled: false,

  }),
  created () {

      this.id = this.$route.params.id

      if (this.firstLoad) this.firstLoad = false

      this.submitGetPage()

  },
  mounted () {
  },
  computed: {
  },
  methods: {
      submitGetPage(){

          this.getPage().then(data => {

              this.menuCategory = data.menuCategory

              this.id = data.id
              this.category = data.category
              this.title = data.title
              this.content = data.content
              this.isEnabled = data.isEnabled

              this.firstLoad = false
          })
      },
      getPage() {

        return new Promise((resolve, reject) => {

          axios.post('/api/page/get', {

              id: this.id,

          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let menuCategory = response.data.menuCategory

            let id = response.data.id
            let category = response.data.category
            let title = response.data.title
            let content = response.data.content
            let isEnabled = response.data.isEnabled

            let success = response.data.success
            let message = response.data.message
            let errors = response.data.errors

            resolve({
                menuCategory,

                id,
                category,
                title,
                content,
                isEnabled,

              success,
              message,
              errors,
            })

          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log('error.response')
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log('error.request')
              console.log(error.request)
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error')
              console.log('Error', error.message)
            }
            console.log('error.config')
            console.log(error.config)

          });

        })
      },

  },
  watch: {
      '$route.params.id': {
          handler: function( val ) {
           this.id = val
           this.submitGetPage()
        },
        deep: true,
        // immediate: true
      }
  }
}
</script>
