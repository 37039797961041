<template>
    <v-container
        fluid
        class="container"
    >
        <v-data-table
            :headers="headers"
            :items="data"
            item-key="Effet"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="updateOptions"
            @click:row="clickHandler"
            :hide-default-footer="hideFooter"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions:[20,50,100],
            }"
            class="elevation-3 row-pointer"
        ></v-data-table>
    </v-container>

</template>

<style>
.container {
    padding: 0 !important;
}</style>

<script>


export default {
  name: 'vehicleDatatable',
  data: () => ({
  }),
  props: {
      data: Array,
      headers: Array,
      options: Array|Object,
      totalItems: {
        type: Number,
        default: null
      },
      hideFooter: {
        type: Boolean,
        default: false
      },
      clickHandler: {
        type: Function,
        default: ()=> {}
      },
  },
  created () {
  },
  mounted () {
   
  },
  computed: {
  },
  methods: {
      updateOptions() {
        this.$emit('update-options', this.options)
      }
  },
  watch: {}
}
</script>
