<template>

  <div v-if="!this.$store.state.auth.data.user.userBrokerId">
    <p class="ma-5">Votre compte doit être lié à un courtier afin de lister les demandes.</p>
  </div>

  <div v-else>

    <!--
    <v-form
      v-model="valid"
      @submit.prevent="getDataFromApi"
    >

    <v-row>
      <v-col cols="12" sm="3">

        <v-text-field
          v-model="idContrat"
          label="Numéro de contrat / devis"
          outlined
          hide-details
          clearable
          clear-icon="clear"
        >
        </v-text-field>

      </v-col>

      <v-col cols="12" sm="3">

        <v-text-field
          v-model="codeTypeList"
          label="Type de demande"
          outlined
          hide-details
          clearable
          clear-icon="clear"
        >
        </v-text-field>

      </v-col>

      <v-col cols="12" sm="3">

        <v-text-field
          v-model="codeEtatList"
          label="Etat"
          outlined
          hide-details
          clearable
          clear-icon="clear"
        >
        </v-text-field>

      </v-col>

      <v-col cols="12" sm="3">

        <v-text-field
          v-model="idPersonne"
          label="Nom / Raison sociale"
          outlined
          hide-details
          clearable
          clear-icon="clear"
        >
        </v-text-field>

      </v-col>

    </v-row>

    <v-row>

      <v-col cols="12" sm="6">

        <v-btn block large color="button" class="button_color--text" type="submit">
          <v-icon left>search</v-icon>Rechercher
        </v-btn>

      </v-col>

        <v-col cols="12" sm="6"><h1>{{total}} résultat(s)</h1></v-col>

    </v-row>

    </v-form>
    -->
    
    <v-row>

      <v-col class="pt-5">

        <v-skeleton-loader
          v-if="loading"
          :loading="loading"
          type="table-thead, table-tbody, table-tfoot">
        </v-skeleton-loader>
        <v-text-field
          v-show="!loading"
          v-model="search"
          append-icon="search"
          label="Rechercher dans les résultats"
          single-line
          hide-details
          clearable
          clear-icon="clear"
        ></v-text-field>
        <GenericDataTable
          v-show="!loading"
          :data="this.tasks.data"
          :headers="this.tasks.headers"
          :options="this.tasks.options"
          :search="search"
        />

      </v-col>

    </v-row>

  </div>

</template>

<style scoped>
::v-deep tbody tr :hover { cursor: pointer; }
</style>

<script>
import axios from "axios";
import GenericDataTable from "../../components/GenericDatatable.vue";

export default {
  name: "TaskSearch",
  components: {
    GenericDataTable,
  },
  data: () => ({
    tasks: [],

    firstLoad: true,
    loading: true,

    search : '',
    valid: true,
    idContrat: null,
    codeTypeList: null,
    codeEtatList: null,
    idPersonne: null,
    total: 0,

  }),
  created () {
    this.getDataFromApi();
  },
  computed: {},
  methods: {
    async getDataFromApi() {
      this.loading = true
      this.taskSearchPromise().then(data => {
        this.tasks = data.items
        this.total = 0;
        if (this.tasks.data) {
          this.total = this.tasks.data.length;
        }
      }).finally(()=> {
        if (this.firstLoad) this.firstLoad = false
        this.loading = false});
    },
    taskSearchPromise() {

      return new Promise((resolve, reject) => {axios.post('/api/task/search', {
        idContrat: this.idContrat,
        codeTypeList: this.codeTypeList,
        codeEtatList: this.codeEtatList,
        idPersonne: this.idPersonne,
      })
        .then((response) => {
          // Success 🎉
          //console.log(response.data.tasks)
          let items = response.data.tasks
          resolve({
            items,
          })

        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response')
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request')
            console.log(error.request)
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error')
            console.log('Error', error.message)
          }
          console.log('error.config')
          console.log(error.config)

        });

      })
    },
  },
};
</script>
