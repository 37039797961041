<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ valid }">
      <v-card class="my-5">
        <v-card-title >
          <div>
            PJ DU PARTICULIER
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="text-h6 text-uppercase tertiary list_color--text text-center">
                Depuis les 36 derniers mois
              </div>
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col cols="9">
              <div>
                Avez-vous fait l'objet d'une résiliation d'un contrat d'assurance protection juridique par votre précédent assureur pour votre sinistre
              </div>
            </v-col>
            <v-col  size="3">
              <v-select :items="[{libelle: 'Oui', code: 'OUI'}, {libelle: 'Non', code: 'NON'}]"
                item-text="libelle" item-value="code" v-model="resiliationSinistre" return-value="code"
                outlined clearable />
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col cols="9">
              <div>
                Avez-vous fait l'objet d'une résiliation d'un contrat d'assurance protection juridique par votre précédent assureur pour fausses déclarations
              </div>
            </v-col>
           <v-col  size="3">
              <v-select :items="[{libelle: 'Oui', code: 'OUI'}, {libelle: 'Non', code: 'NON'}]"
                item-text="libelle" item-value="code" v-model="resiliationFausseDeclaration" return-value="code"
                outlined clearable />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-h6 text-uppercase tertiary list_color--text text-center">
                A aujourd'hui
              </div>
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col cols="9">
              <div>
                Avez-vous connaissance de faits,d'événements ou situations sources de litige pouvant entrainer une situation conflictuelle et susceptibles de mettre en jeu les garanties du présent contrat ?
              </div>
            </v-col>
            <v-col cols="3">
              <v-select :items="[{libelle: 'Oui', code: 'OUI'}, {libelle: 'Non', code: 'NON'}]"
                item-text="libelle" item-value="code" v-model="connaisanceLitige"  return-value="code"
                outlined clearable />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </ValidationObserver>
    <v-row>
      <v-col>
        <v-alert type="warning" :value="true" v-if="resiliationSinistre === 'OUI' ||  resiliationFausseDeclaration === 'OUI' || connaisanceLitige === 'OUI'">
          La situation du client ne permet de souscrire le contrat PJ
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col>
   
        <v-btn block :disabled="!(resiliationSinistre === 'NON' && resiliationFausseDeclaration === 'NON' && connaisanceLitige === 'NON')" large color="button" class="button_color--text" @click="nextStep">Suivant</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>

<script>
import {
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

import { mapActions, mapState } from "vuex";
import pjStoreModule from "../../store/estimation/pj.store";

export default {
  name: "PJRisk",
  props: {

  },
  components: {
    ValidationObserver
  },
  data: () => ({
    resiliationSinistre: null,
    resiliationFausseDeclaration: null,
    connaisanceLitige: null,
    valid: false,
  }),
  beforeCreate() {
    this.$store.registerModule("productRisk", pjStoreModule);
  },
  async created() {
    await this.getPjItemsLists();
    this.$store.commit('productRisk/setProductRisk', this.$store.state.estimation.estimate.productRisk);
  },
  beforeDestroy() {
    this.$store.unregisterModule("productRisk");
  },
  mounted() {

  },
  computed: {
    ...mapState({
      itemsListsClient: (state) => state.estimation.itemsLists,
      itemsListsProduct: (state) => state.productRisk.itemsLists,
      listLoader: (state) => state.productRisk.loaders.pjLists,
    }),
    itemsLists() { return { 'itemsListsClient': this.itemsListsClient, 'itemsListsProduct': this.itemsListsProduct }; }
  },
  methods: {
    ...mapActions("productRisk", [
      "getPjItemsLists",
    ]),
    async nextStep() {
      await this.$refs["form"].validate().then((valid) => {
        if (valid) {
          this.$store.commit('estimation/nextEstimationStep')
        } else
          logger.debug('Formulaire de payement invalide')
      });
    },
  }
};
</script>