var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',_vm._l((_vm.images),function(image){return _c('v-col',{key:image.title,attrs:{"cols":"12","sm":"4"}},[(image.target=='_self')?_c('router-link',{attrs:{"to":image.route,"target":image.target}},[_c('v-img',{attrs:{"lazy-src":"","aspect-ratio":image.ratio,"src":require(("@/assets/images/" + (image.src)))}})],1):_c('a',{attrs:{"href":image.route,"target":image.target}},[_c('v-img',{attrs:{"lazy-src":"","aspect-ratio":image.ratio,"src":require(("@/assets/images/" + (image.src)))}})],1)],1)}),1),_c('v-row',[_c('v-col',[_c('v-list',{attrs:{"subheader":true,"color":"list_background_lighter"}},[_c('v-subheader',{staticClass:"rounded-t-lg text-h6 tertiary list_color--text"},[_vm._v("Nos gammes")]),_vm._l((_vm.gamuts),function(gamut){return _c('v-list-group',{key:gamut.title,attrs:{"color":"tertiary","active-class":"list_background_light"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(gamut.title)}})],1)]},proxy:true}],null,true),model:{value:(gamut.active),callback:function ($$v) {_vm.$set(gamut, "active", $$v)},expression:"gamut.active"}},[_c('v-icon',{attrs:{"slot":"prependIcon","color":"tertiary_light"},domProps:{"textContent":_vm._s(gamut.icon)},slot:"prependIcon"}),_c('v-icon',{attrs:{"slot":"appendIcon","color":"tertiary_light"},slot:"appendIcon"},[_vm._v("expand_more")]),_vm._l((gamut.products),function(product){return _c('v-list-item',{key:product.title,attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(product.title)}})],1),_vm._l((product.actions),function(action,index){return _c('v-list-item-action',{key:action.route},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":action.hasFile != null && !action.hasFile,"loading":_vm.loading[action.productFileId]},on:{"click":function($event){index == 0
                        ? _vm.goToEstimate(action.route)
                        : _vm.getDataFromApi(action.productFileId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"tertiary_light"},domProps:{"textContent":_vm._s(action.icon)}})],1),(index !== product.actions.length - 1)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(action.tooltip)}})])],1)})],2)})],2)})],2)],1),_c('v-col',[_c('v-list',{attrs:{"two-line":"","subheader":true,"color":"list_background_lighter"}},[_c('v-subheader',{staticClass:"rounded-t-lg text-h6 tertiary list_color--text"},[_vm._v("Mon tableau de bord")]),(_vm.firstLoad)?_c('v-skeleton-loader',{staticClass:"mt-5",attrs:{"type":"list-item@4"}}):_vm._e(),_c('v-list-item-group',_vm._l((_vm.dashboard),function(item){return _c('v-list-item',{key:item.title,attrs:{"prepend-icon":item.icon,"inactive":""}},[_c('v-list-item-avatar',{staticClass:"tertiary_light list_color--text"},[_c('div',{staticClass:"numberCircle",domProps:{"textContent":_vm._s(item.count)}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.text)}})],1),(item.tooltip && item.count)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-list-item-action',[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":item.route}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"tertiary_light"},domProps:{"textContent":_vm._s(item.icon)}})],1)],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.tooltip)}})]):_vm._e()],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }