<template>
  <v-container fluid class="container">
    <p class="text-right mt-3" v-if="this.contract.documents.data">
      <v-btn
        @click="showDialogUploadFile"
        class="tertiary_light button_color--text"
      >
        Ajouter des pièces
      </v-btn>
    </p>

    <v-data-table
      :headers="this.headers"
      :items="this.data"
      item-key="number"
      :loading="dialogFileLoading"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100],
      }"
      class="elevation-3 row-pointer"
      @click:row="goToContractDocumentFile"
    >
      <template v-slot:[`item.dueDateBrut`]="{ item }">
        <v-col>{{ item.dueDate }}</v-col>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogFiles" width="600px" max-width="600px">
      <v-card>
        <div v-if="filesLoading">
          <v-card-title
            class="text-h6 text-uppercase tertiary list_color--text"
          >
            {{ dialogFilesTitle }}
          </v-card-title>

          <v-card-text class="pt-3">
            <v-list color="list_background_lighter">
              <v-list-item v-for="(file, i) in files.data" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="file.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="file.creationDate"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-action>
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="downloadFile(file.id)"
                        :loading="loadingDownloadFile[file.id]"
                      >
                        <v-icon>downloading</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                  <span>Télécharger</span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogFiles = false">
              Fermer
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUploadFile" width="600px" max-width="600px">
      <form @submit.prevent="uploadFile">
        <v-card>
          <v-card-title
            class="text-h6 text-uppercase tertiary list_color--text"
          >
            Ajouter des pièces
          </v-card-title>

          <v-card-subtitle class="mt-3">
            * Tous les champs sont obligatoires. La taille maximum autorisée par
            fichier est de 20 MB
          </v-card-subtitle>

          <v-card-text>
            <v-file-input
              v-model="filesInput"
              :rules="rules"
              :error-messages="errors"
              label="Ajouter un ou plusieurs fichiers *"
              accept="image/*, .pdf"
              prepend-icon=""
              prepend-inner-icon="attach_file"
              small-chips
              clearable
              counter
              multiple
              show-size
              truncate-length="15"
              required
            ></v-file-input>

            <v-autocomplete
              v-model="piecesInput"
              :items="this.contract.documents.data"
              :error-messages="errors"
              item-text="type"
              item-value="id"
              label="Sélectionner une ou plusieurs pièces *"
              clearable
              deletable-chips
              small-chips
              multiple
              open-on-clear
              hide-selected
              return-object
            ></v-autocomplete>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" text @click="dialogUploadFile = false">
              fermer
            </v-btn>
            <!-- @click="uploadFile" -->
            <v-btn
              :loading="loadingUploadFile"
              class="tertiary_light button_color--text"
              type="submit"
            >
              enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="message.type"
      :multi-line="true"
      timeout="3000"
    >
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="button_color--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}
</style>

<script>
import axios from "axios";
export default {
  name: "PiecesDataTable",
  data: () => ({
    snackbar: false,
    success: false,
    message: [],
    errors: [],

    /* files */
    files: [],
    dialogFiles: false,
    dialogFilesTitle: "",
    dialogFileLoading: false,
    filesLoading: false,
    loadingDownloadFile: {},

    /* upload files */
    dialogUploadFile: false,
    rules: [
      (files) =>
        !files ||
        !files.some((file) => file.size > 20_971_520) ||
        "La taille de chaque fichier ne doit pas dépasser 20 MB",
    ],
    filesInput: [],
    piecesInput: [],

    loadingUploadFile: false,
  }),
  props: {
    data: Array,
    headers: Array,
    options: Array | Object,
    contract: Object,
    getContractParent: Function,
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    showDialogUploadFile() {
      if (this.contract.documents.data) {
        this.dialogUploadFile = true;
      } else {
        this.success = false;
        this.message.type = "error";
        this.message.message =
          "Aucune pièce. Votre demande ne peut pas aboutir";

        this.snackbar = true;
      }
    },
    goToContractDocumentFile(row) {
      if (row.linkedFilesNb > 0) {
        this.dialogFiles = true;
        this.dialogFileLoading = true;
        this.filesLoading = false;

        return new Promise((resolve, reject) => {
          axios
            .post("/api/contract/document/file/list", {
              documentId: row.number,
            })
            .then((response) => {
              // Success 🎉
              // console.log(response.data.files.data)

              let files = response.data.files;
              let success = response.data.success;
              let message = response.data.message;
              let errors = response.data.errors;

              this.dialogFilesTitle = row.type;
              this.files = response.data.files;

              this.filesLoading = true;
              this.dialogFileLoading = false;

              this.success = response.data.success;
              this.message = response.data.message;
              this.errors = response.data.errors;
              if (!this.success) {
                this.snackbar = true;
                setTimeout( () => this.dialogFiles = false, 1000)
              }
              
              resolve({
                files,
                success,
                message,
                errors,
              });
            })
            .catch((error) => {
              // Error 😨
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log("error.response");
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log("error.request");
                console.log(error.request);
              } else {
                // Something happened in setting up the request and triggered an Error
                console.log("Error");
                console.log("Error", error.message);
              }
              console.log("error.config");
              console.log(error.config);
            });
        });
      }
    },
    downloadFile(id) {
      this.loadingDownloadFile[id] = true;

      return new Promise((resolve, reject) => {
        axios
          .post("/api/contract/document/file/get", {
            // responseType: 'blob',
            documentId: id,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response)
            // console.log(response.data.file.data.content);
            // let file = response.data.file
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;
            // console.log( atob(response.data.file.data.content) );

            // base64 string
            var base64str = response.data.file.data.content;

            // decode base64 string, remove space for IE compatibility
            var binary = atob(base64str.replace(/\s/g, ""));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
            }

            // create the blob object
            var blob = new Blob([view]);
            var fileURL = URL.createObjectURL(blob);

            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", response.data.file.data.name);
            document.body.appendChild(fileLink);

            fileLink.click();

            this.success = response.data.success;
            this.message = response.data.message;
            this.errors = response.data.errors;

            this.loadingDownloadFile[id] = false;
            this.snackbar = true;

            resolve({
              // files,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);

            this.success = false;
            this.message.type = "error";
            this.message.message = "Votre demande ne peut pas aboutir";

            this.loadingDownloadFile[id] = false;
            this.snackbar = true;
          });
      });
    },
    uploadFile() {
      // console.log(JSON.stringify(this.filesInput))
      // console.log(this.piecesInput)
      // console.log( this.contract.id );

      if (typeof this.piecesInput == "undefined" || this.piecesInput == 0) {
        return;
      }

      this.loadingUploadFile = true;

      let formData = new FormData();

      for (let file of this.filesInput) {
        formData.append("files[]", file);
      }

      formData.append("pieces", JSON.stringify(this.piecesInput));
      formData.append("contractId", this.contract.details.id); // 2x contract -> nouveau model

      return new Promise((resolve, reject) => {
        axios
          .post("/api/contract/document/file/add", formData)
          .then((response) => {
            // Success 🎉
            // console.log(response)
            // console.log(response.data.contract.documents);
            // let file = response.data.file
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            this.contract.documents = response.data.contract.documents;

            this.success = response.data.success;
            this.message = response.data.message;
            this.errors = response.data.errors;

            this.loadingUploadFile = false;
            this.dialogUploadFile = false;
            this.snackbar = true;

            this.getContractParent();
            
            resolve({
              // files,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);

            this.success = false;
            this.message.type = "error";
            this.message.message = "Votre demande ne peut pas aboutir";

            this.loadingUploadFile = false;
            this.dialogUploadFile = false;
            this.snackbar = true;
          });
      });
    },
  },
  watch: {},
};
</script>
