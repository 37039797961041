import axios from "axios";

const API_URL = "/api/claims";

class ClaimService {
  async get(claimId) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + claimId,
    });
    return res.data;
  }
}

export default new ClaimService();
