<template lang="pug">
v-select(
    v-model="select",
    :items="items",
    :item-text="itemText",
    :item-value="itemValue",
    :label="label",
    :error-messages="messages",
    @change='formValidate',
    v-bind="{...$props.attr}"
)
</template>

<script>
export default {
  props: {
    value: [String, Object, Array],
    items: [Object, Array],
    itemText: String,
    itemValue: String,
    label: String,
    messages: Array,
    attr: {
      type: Object,
      default: function () {
        return {
          outlined: "",
          "hide-selected": "",
          "hide-details": "",
          clearable: "",
        };
      },
    },
  },
  data: () => ({}),
  computed: {
    select: {
      get() {
        this.$emit("input", this.$props.value);
        return this.$props.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async formValidate() {
      await this.$emit("formValidate");
    },
  },
};
</script>
