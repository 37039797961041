<template lang="pug">
v-text-field(
    v-model="text"
    :label="label"
    :error-messages="messages"
    @change='formValidate'
    v-bind="{...$props.attr}"
)
</template>

<script>
export default {
  props: {
    value: [String, Number],
    label: String,
    messages: Array,
    attr: {
      type: Object,
      default: function () {
        return { outlined: "", clearable: "", "hide-details": "" };
      },
    },
  },
  data: () => ({}),
  computed: {
    text: {
      get() {
        this.$emit("input", this.$props.value);
        return this.$props.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async formValidate() {
      await this.$emit("formValidate");
    },
  },
};
</script>
