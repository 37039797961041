import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const estimationStoreModule = {
  namespaced: true,
  // https://vuex.vuejs.org/guide/modules.html#module-reuse !!
  state: () => ({
    estimateLoading: true,
    // Devis
    estimate: {
      productRisk: {
        options: {
          niveauAssistance: { code: "ECO", libelle: "ECO" },
          marchandiseTransportees: { code: "0", libelle: "0" },
          accessoiresHorsCatalogue: { code: "0", libelle: "0" },
          franchiseModulationBDG: "20",
          franchiseModulationVOLI: "20",
          franchiseModulationDTA: "20",
        },
      },
    },
    // La tarification
    estimation: {},
    estimationStep: 1,
    riskStep: 1,
    hasBroker: true,
    itemsLists: {
      cities: [],
      citiesForPerson: [],
      claimNatures: [],
      isConduiteExclusive: ["OUI", "NON"],
    },
    snackbars: {
      pricing: {
        state: false,
        content: {
          type: "",
          message: "",
        },
      },
    },
    loader: {
      listClient: false,
      listVehicle: false,
      listCities: false,
      listCitiesForPerson: false,
      listDriver: false,
      listAutoOpts: false,
      getClient: false,
      getEstimateModel: true,
      getEstimation: false,
      createEstimate: false,
    },
  }),
  mutations: {
    updBirthDate(state, birthDate) {
      state.estimate.person.birthDate = birthDate;
    },
    updFirstUseDate(state, firstUseDate) {
      state.estimate.productRisk.vehicleInfos.firstUseDate = firstUseDate;
    },
    updAcquisitionDate(state, acquisitionDate) {
      state.estimate.productRisk.vehicleInfos.acquisitionDate = acquisitionDate;
    },
    updEffectDate(state, effectDate) {
      state.estimate.effectDate = effectDate;
    },
    nextEstimationStep(state) {
      state.estimationStep++;
      logger.debug("Etape tarification n°" + state.estimationStep);
      localStorage.setItem("estimation-store", JSON.stringify(state));
    },
    nextRiskStep(state) {
      state.riskStep++;
      logger.debug("Etape risque n°" + state.riskStep);
    },
    updEstimationStep(state, step) {
      state.estimationStep = step;
      logger.debug("Etape tarification n°" + state.estimationStep);
    },
    updRiskStep(state, step) {
      state.riskStep = step;
      logger.debug("Etape risque n°" + state.riskStep);
    },
    setPrincipalAssured(state, principalAssured) {
      state.estimate.productRisk.principalAssured = principalAssured;
    },
    setSecondariesAssured(state, secondariesAssured) {
      state.estimate.productRisk.secondariesAssured = secondariesAssured;
    },
    setRisk(state, risk) {
      state.estimate.productRisk = risk;
    },
    updFormula(state, index) {
      state.estimate.formula = state.estimation.formulas[index];
    },
    resetEstimation(state) {
      state.estimation = {};
    },
  },
  actions: {
    async getEstimateModel({ state, rootState, dispatch }, payload) {
      try {
        logger.info("Récupération du model de devis..");
        logger.time("getEstimateModel()");
        console.log("l" + payload.isForLegalPerson);
        state.loader["getEstimateModel"] = true;
        const response = await axios.post("/api/estimate/getModel", {
          productId: payload.productId,
          isForLegalPerson: payload.isForLegalPerson,
        });
        state.estimate = response.data;
        if (!state.estimate.product.options) {
          state.estimate.product.options = {};
        }
        console.log("state.estimate", state.estimate);
        logger.info(
          "Model Devis -> produit: " + state.estimate.product.libelle
        );
        logger.debug("Récupération du code agence..");
        await dispatch("getAgency", null, {
          root: true,
        })
          .then((hasBroker) => {
            if (hasBroker) {
              logger.info(
                "Ajout du code agence de l'utilisateur au model de devis"
              );
              state.estimate.agencyCode = rootState.auth.data.user.agency.code;

              state.loader["getEstimateModel"] = false;
              state.hasBroker = hasBroker;
            } else {
              logger.error("Pas de code agence pour le courtier corespondant");
              state.loader["getEstimateModel"] = false;
              state.hasBroker = hasBroker;
            }
          })
          .catch(() => {
            logger.error("Récupération du code agence : " + error.message);
            state.loader["getEstimateModel"] = false;
            state.hasBroker = hasBroker;
          });
      } catch (error) {
        logger.error("Récupération du model de devis : " + error.message);
        state.loader["getEstimateModel"] = false;
      }
      logger.timeEnd("getEstimateModel()");
    },
    async getEstimateCopy({ state, rootState, dispatch }, estimateId) {
      try {
        logger.info("Récupération du devis..");
        logger.time("getEstimateCopy()");

        const response = await axios.post("/api/estimate/getCopy", {
          idContrat: estimateId,
        });
        state.estimate = response.data.estimate;

        logger.info(
          "Model Devis -> produit: " + state.estimate.product.libelle
        );
        logger.debug("Récupération du code agence..");
        await dispatch("getAgency", null, {
          root: true,
        })
          .then((hasBroker) => {
            if (hasBroker) {
              logger.info(
                "Ajout du code agence de l'utilisateur au model de devis"
              );
              state.estimate.agencyCode = rootState.auth.data.user.agency.code;

              state.loader["getEstimateModel"] = false;
              state.hasBroker = hasBroker;
            } else {
              logger.error("Pas de code agence pour le courtier corespondant");
              state.loader["getEstimateModel"] = false;
              state.hasBroker = hasBroker;
            }
          })
          .catch(() => {
            logger.error("Récupération du code agence : " + error.message);
            state.loader["getEstimateModel"] = false;
            state.hasBroker = hasBroker;
          });
      } catch (error) {
        logger.error("Récupération du model de devis : " + error.message);
        state.loader["getEstimateModel"] = false;
      }
      logger.timeEnd("getEstimateCopy()");
    },
    async fetchClient(context, idClient) {
      logger.info("Récupération du client..");
      logger.time("fetchClient()");
      try {
        context.state.loader["getClient"] = true;
        const response = await axios.post("/api/client/get", {
          idClient: idClient,
        });

        //Recupere la liste des  villes pour le code postal avant d'assigner la personne au formulaire
        await context.dispatch(
          "getCitiesListForPerson",
          response.data.person.address.zipCode
        );
        context.state.estimate.person = response.data.person;

        logger.info(
          "client -> id: " +
            response.data.person.id +
            ", nom: " +
            response.data.person.lastname +
            " " +
            response.data.person.firstname
        );
        context.state.loader["getClient"] = false;
      } catch (error) {
        logger.error("Récupération du client: " + error.message);
        context.state.loader["getClient"] = false;
      }
      logger.timeEnd("fetchClient()");
    },
    async getEstimation(context, options) {
      logger.info("Tarification lancée..");
      logger.time("getEstimation()");
      try {
        context.state.loader["getEstimation"] = true;
        console.log(options);
        if (options) {
          if(context.state.estimate.productRisk){
            context.state.estimate.productRisk.options = {
              ...context.state.estimate.productRisk.options,
              ...options,
            };
          }else{
            context.state.estimate.productRisk = {
              options: {
                ...options,
              },
            };
          }
          
        }
        context.state.estimate.periodicite = options.fractionnement.code;
        const response = await axios.post("/api/estimation", {
          estimate: context.state.estimate,
        });

        context.state.estimation = response.data.estimation;

        context.state.loader["getEstimation"] = false;

        context.state.snackbars["pricing"] = {
          state: true,
          content: {
            type: response.data.message.type,
            message: response.data.message.message,
          },
        };
      } catch (error) {
        logger.error("Erreur de tarification : " + error.message);
        context.state.loader["getEstimation"] = false;
        context.state.snackbars["pricing"] = {
          state: true,
          content: {
            type: response.data.message.type,
            message: response.data.message.message,
          },
        };
      }
      logger.timeEnd("getEstimation()");
    },
    async createEstimate(context, idClient) {
      logger.info("Création du devis..");
      logger.time("createEstimate()");
      try {
        context.state.loader["createEstimate"] = true;

        const response = await axios.post("/api/estimate/creation", {
          estimate: context.state.estimate,
        });
        if (response.data.success) {
          context.state.estimate.id = response.data.contractId;
          context.state.estimate.number = response.data.contractNumber;
          logger.info(
            "Devis -> id : " +
              context.state.estimate.id +
              ", numéro: " +
              context.state.estimate.number
          );

          context.state.loader["createEstimate"] = false;

          context.state.snackbars["pricing"] = {
            state: true,
            content: {
              type: response.data.message.type,
              message: response.data.message.message,
            },
          };
        } else {
          logger.error("Création du devis: " + response.data.message.message);
          context.state.loader["createEstimate"] = false;
          context.state.snackbars["pricing"] = {
            state: true,
            content: {
              type: response.data.message.type,
              message: response.data.message.message,
            },
          };
        }
      } catch (error) {
        logger.error("Création du devis: " + error.message);
        context.state.loader["createEstimate"] = false;
        context.state.snackbars["pricing"] = {
          state: true,
          content: {
            type: response.data.message.type,
            message: response.data.message.message,
          },
        };
      }
      logger.timeEnd("createEstimate()");
    },
    async getClientItemsLists(context) {
      const civilitiesReq = axios.get("/api/listItems/civilities");
      const countriesReq = axios.get("/api/listItems/countries");
      const cspsReq = axios.get("/api/listItems/csps");
      const maritalStatusesReq = axios.get("/api/listItems/marStat");
      const relationsReq = axios.get("/api/listItems/relations");
      const legalStatusesReq = axios.get("/api/listItems/legalStatuses");

      try {
        context.state.loader["listClient"] = true;
        const responses = await axios.all([
          civilitiesReq,
          countriesReq,
          cspsReq,
          maritalStatusesReq,
          relationsReq,
          legalStatusesReq,
        ]);

        context.state.itemsLists["civilities"] = responses[0].data.listItems;
        context.state.itemsLists["countries"] = responses[1].data.listItems;
        context.state.itemsLists["csps"] = responses[2].data.listItems;
        context.state.itemsLists["maritalStatuses"] =
          responses[3].data.listItems;
        context.state.itemsLists["relations"] = responses[4].data.listItems;
        context.state.itemsLists["legalStatuses"] = responses[5].data.listItems;

        context.state.loader["listClient"] = false;
        logger.info("END - ListsItems Client");
      } catch (error) {
        console.log(error);
        this.handleAxiosError(error);
        context.state.loader["listClient"] = false;
      }
    },
    async getAutoOptsItemsLists(context) {
      const franchisesDTAReq = axios.get("/api/listItems/franchisesDTA");
      const franchisesBrisGlaceReq = axios.get(
        "/api/listItems/franchisesBrisGlace"
      );
      const montantsEOAReq = axios.get("/api/listItems/montantsEOA");
      const niveauxAssistanceReq = axios.get(
        "/api/listItems/niveauxAssistance"
      );
      const garantiesConducteurReq = axios.get(
        "/api/listItems/garantiesConducteur"
      );

      try {
        context.state.loader["listAutoOpts"] = true;
        const responses = await axios.all([
          franchisesDTAReq,
          franchisesBrisGlaceReq,
          montantsEOAReq,
          niveauxAssistanceReq,
          garantiesConducteurReq,
        ]);

        (context.state.itemsLists["franchisesDTA"] =
          responses[0].data.listItems),
          (context.state.itemsLists["franchisesBrisGlace"] =
            responses[1].data.listItems),
          (context.state.itemsLists["montantsEOA"] =
            responses[2].data.listItems),
          (context.state.itemsLists["niveauxAssistance"] =
            responses[3].data.listItems),
          (context.state.itemsLists["garantiesConducteur"] =
            responses[4].data.listItems),
          (context.state.loader["listAutoOpts"] = false);
      } catch (error) {
        this.handleAxiosError(error);
        context.state.loader["listAutoOpts"] = false;
      }
    },
    /*  async getCitiesListForAuto(context) {
            try {
                context.state.loader['listCities'] = true;
                const response = await axios
                    .get("/api/listItems/citiesByZipCodeForAuto", {
                        params: {
                            zipCode: context.state.estimate.productRisk.vehicleInfos.zipCode,
                            productCode: context.state.estimate.product.code,
                        },
                    });

                context.state.itemsLists['cities'] = response.data.listItems;

                context.state.estimate.productRisk.vehicleInfos.city = [];

                context.state.loader['listCities'] = false;
            } catch (error) {
                context.state.estimate.productRisk.vehicleInfos.city = [];
                context.state.itemsLists.cities = [];

                context.state.loader['listCities'] = false;
                this.handleAxiosError(error);
            }
        }, */
    async getCitiesListForPerson(context, zipCode) {
      if (zipCode != null && zipCode != "") {
        try {
          context.state.loader["listCitiesForPerson"] = true;
          const response = await axios.get(
            "/api/listItems/citiesByZipCodeForPerson",
            {
              params: {
                zipCode: zipCode,
              },
            }
          );

          context.state.itemsLists["citiesForPerson"] = response.data.listItems;
          if (response.data.listItems.length == 1) {
            context.state.estimate.person.address.city =
              response.data.listItems[0];
          }

          context.state.loader["listCitiesForPerson"] = false;
        } catch (error) {
          context.state.estimate.person.address.city = "";
          context.state.itemsLists.citiesForPerson = [];

          context.state.loader["listCitiesForPerson"] = false;
          this.handleAxiosError(error);
        }
      }
    },
  },
};

export default estimationStoreModule;
