<template>
  <div>
    <v-form v-model="valid" @submit.prevent="getDataFromApi">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="searchNumber"
            label="Numéro de contrat ou devis"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :autofocus="numberAutofocus"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <v-select
            :items="productsCode"
            v-model="productCode"
            label="Produit"
            outlined
            hide-details
            hide-selected
            clearable
            item-text="libelle"
            item-value="code"
            :loading="loadingProducts"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="3">
          <v-select
            v-model="etatGlobal"
            :items="globalStatus"
            item-text="libelle"
            item-value="libelle"
            label="Statut"
            outlined
            hide-details
            hide-selected
            clearable
            
            :loading="loadingStatus"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="3">
          <v-dialog
            ref="dialogCreate"
            v-model="modalDateCreate"
            :return-value.sync="pickerDateCreate.data"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(pickerDateCreate.data)"
                append-icon="date_range"
                label="Date de création (du ~ au)"
                readonly
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
                clearable
                clear-icon="clear"
                @click:clear="pickerDateCreate.data = []"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="pickerDateCreate.data"
              scrollable
              range
              header-color="tertiary"
              color="tertiary_light"
              first-day-of-week="1"
            >
              <v-spacer></v-spacer>

              <v-btn text @click="modalDateCreate = false">Annuler</v-btn>
              <v-btn text @click="$refs.dialogCreate.save(pickerDateCreate.data)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="3">
          <v-dialog
            ref="dialogEffect"
            v-model="modalDateEffect"
            :return-value.sync="pickerDateEffect.data"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(pickerDateEffect.data)"
                append-icon="date_range"
                label="Date d'effet (du ~ au)"
                readonly
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
                clearable
                clear-icon="clear"
                @click:clear="pickerDateEffect.data = []"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="pickerDateEffect.data"
              scrollable
              range
              header-color="tertiary"
              color="tertiary_light"
              first-day-of-week="1"
            >
              <v-spacer></v-spacer>

              <v-btn text @click="modalDateEffect = false">Annuler</v-btn>
              <v-btn text @click="$refs.dialogEffect.save(pickerDateEffect.data)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            v-model="lastName"
            label="Nom"
            outlined
            hide-details
            clearable
            clear-icon="clear"
            :autofocus="nameAutofocus"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            v-model="firstName"
            label="Prénom"
            outlined
            hide-details
            clearable
            clear-icon="clear"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="companyName"
            label="Raison sociale"
            outlined
            hide-details
            clearable
            clear-icon="clear"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <v-checkbox v-model="unpaid" hide-details>
            <template v-slot:label>
              <div>Impayé</div>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12" sm="3">
          <v-btn block large color="button" class="button_color--text" type="submit">
            <v-icon left>search</v-icon>Rechercher
          </v-btn>
        </v-col>

        <v-col cols="12" sm="3">
          <h1>{{total}} résultat(s)</h1>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col class="pt-10">
        <v-data-table
          :headers="headers"
          :items="contracts"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          item-key="id"
          @click:row="rowClick"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions:[20,50,100],
          }"
          class="elevation-3 row-pointer"
          no-data-text=""
          no-results-text=""
        >
          <template v-slot:item.dateEffet="{ item }">
            <span>{{ item.dateEffet !== "" ? new Date(item.dateEffet).toLocaleDateString('fr-FR', {timeZone: 'Pacific/Noumea'}) : item.dateEffet }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>

<script>
import axios from "axios";
export default {
  name: "ContractSearch",
  props: {
    isModal: {
      type: Boolean,
      deafult: false,
    },
  },
  data: () => ({
    valid: true,
    nameAutofocus: false,
    numberAutofocus: false,

    searchNumber: "",

    productsCode: [],
    globalStatus: [],

    modalDateCreate: false,
    pickerDateCreate: {
      data: [],
      show: false,
    },
    modalDateEffect: false,
    pickerDateEffect: {
      data: [],
      show: false,
    },

    lastName: "",
    firstName: "",
    companyName: "",
    productCode: "",
    etatGlobal: "",
    etatGlobalTitle: "",
    unpaid: false,

    total: 0,

    contracts: [],

    loading: false,
    loadingProducts: false,
    loadingStatus: false,

    options: {},

    firstPage: 0,
    sortBy: "dateEffet",
    sortDesc: true,

    /* Manage default fields values to post */
    sortField: "dateEffet",
    sortOrder: "DESCENDING",

    headers: [
      {
        text: "Numéro de contrat ou devis",
        align: "start",
        sortable: false,
        value: "numero",
      },
      { text: "Code produit", value: "codeProduit", sortable: true },
      { text: "Nom du soucripteur", value: "nomSouscripteur", sortable: false },
      {
        text: "Numéro de téléphone",
        value: "adresseTelephonique",
        sortable: false,
      },
      { text: "Date d'effet", value: "dateEffet", sortable: true },
      { text: "Solde", value: "solde", sortable: false },
      { text: "Statut", value: "libelleStatut", sortable: true },
    ],
  }),
  created() {},
  mounted() {
    let date = new Date();
    let today = date.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).split('T')[0];
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).split('T')[0];
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).split('T')[0];
    let minusDays = new Date(new Date().setDate(new Date().getDate()-31)).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}).split('T')[0];

    if (this.$route.query.searchNumber) {
      this.searchNumber = this.$route.query.searchNumber;
      this.numberAutofocus = true;
      // Debug EX-105
      // this.getDataFromApi();
    }
    if (this.$route.query.lastName) {
      this.lastName = this.$route.query.lastName;
      this.nameAutofocus = true;
      // Debug EX-105
      // this.getDataFromApi();
    }
    this.loadingProducts = true;
    this.getItemsLists().then((data) => {
      this.productsCode = data.productsCode;
      this.loadingProducts = false;
    });
    this.loadingStatus = true;
    this.getGlobalStatus().then((data) => {
      this.globalStatus = data.globalStatus;
      if (this.$route.query.etatGlobal) {
        this.etatGlobal = data.globalStatus.filter(item=>item.includes(this.$route.query.etatGlobal))[0];
        if (this.$route.query.etatGlobal=='Contrat validé') {
          this.pickerDateEffect.data[0] = firstDay;
          this.pickerDateEffect.data[1] = lastDay;
        }
        if (this.$route.query.etatGlobal=='Devis') {
          this.pickerDateCreate.data[0] = minusDays;
          this.pickerDateCreate.data[1] = today;
        }
        // Debug EX-105
        this.getDataFromApi();
      }
      this.loadingStatus = false;
    });
  },
  computed: {},
  methods: {
    getItemsLists() {
      return new Promise((resolve, reject) => {
        const productsCodeReq = axios.get("/api/listItems/productsCode");
        /*  const countriesReq = axios.get("/api/listItems/countries");
        const cspsReq = axios.get("/api/listItems/csps");
        const maritalStatusesReq = axios.get("/api/listItems/marStat"); */

        axios
          .all([
            productsCodeReq /* , countriesReq, cspsReq, maritalStatusesReq */,
          ])
          .then(
            axios.spread((...responses) => {
              let productsCode = responses[0].data.listItems;
              /* let countries = responses[1].data.listItems;
              let csps = responses[2].data.listItems;
              let maritalStatuses = responses[3].data.listItems; */

              resolve({
                productsCode,
                /* countries,
                csps,
                maritalStatuses, */
              });
            })
          )
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    // Pourquoi on formatte la date ici ?
    formatDate(date) {
      if (date.length === 0) return null;
      const [year, month, day] = date[0].split("-");
      if (!date[1]) return null;
      const [year2, month2, day2] = date[1].split("-");
      const start = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      const end = `${day2.padStart(2, "0")}/${month2.padStart(
        2,
        "0"
      )}/${year2}`;
      return start + " ~ " + end;
    },
    rowClick(row) {
      if (this.isModal) {
        this.$emit("retrieveContrat", row);
      } else this.goToContract(row);
    },
    goToContract(row) {
      this.$router.push("/contract/search/" + row.id);
    },
    getDataFromApi() {
      this.loading = true;
      this.contractSearchResults()
        .then((data) => {
          this.contracts = data.items;
          this.total = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    contractSearchResults() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        /* Manage offset querying to post for okayo request */
        if (!page || page == 1) {
          this.firstPage = 0;
        } else {
          this.firstPage = (page - 1) * itemsPerPage;
        }

        /* Manage order by value to post for okayo request */
        if (sortDesc[0]) {
          this.sortOrder = "ASCENDING";
        } else {
          this.sortOrder = "DESCENDING";
        }

        /* Manage sort by value to post for okayo request */
        if (sortBy[0]) {
          this.sortField = sortBy[0];
        } else {
          this.sortField = this.sortField;
        }

        /* Change date format to AAAA-MM-DD for okayo request */
        if (this.pickerDateEffect.data[0]) {
          this.dateEffetDu = new Date(this.pickerDateEffect.data[0])
            .toISOString()
            .slice(0, 10);
        } else {
          this.dateEffetDu = null;
        }

        if (this.pickerDateEffect.data[1]) {
          this.dateEffetAu = new Date(this.pickerDateEffect.data[1])
            .toISOString()
            .slice(0, 10);
        } else {
          this.dateEffetAu = null;
        }

        /* Change date format to AAAA-MM-DD for okayo request */
        if (this.pickerDateCreate.data[0]) {
          this.dateCreationDu = new Date(this.pickerDateCreate.data[0])
            .toISOString()
            .slice(0, 10);
        } else {
          this.dateCreationDu = null;
        }

        if (this.pickerDateCreate.data[1]) {
          this.dateCreationAu = new Date(this.pickerDateCreate.data[1])
            .toISOString()
            .slice(0, 10);
        } else {
          this.dateCreationAu = null;
        }
        
        if (this.etatGlobal) {
          if (this.etatGlobal != "undefined") {
            this.etatGlobalTitle = this.etatGlobal;
          } else {
            this.etatGlobalTitle = "";
          }
        } else {
          this.etatGlobalTitle = "";
        }
        
        axios
          .post("/api/contract/search", {
            pageSize: itemsPerPage, //limit
            firstPage: this.firstPage, //offset
            sortField: this.sortField,
            sortOrder: this.sortOrder,

            idPersonnes: "",
            numero: this.searchNumber,
            productCode: this.productCode,
            etatGlobal: this.etatGlobalTitle,

            dateEffetDu: this.dateEffetDu,
            dateEffetAu: this.dateEffetAu,
            dateCreationDu: this.dateCreationDu,
            dateCreationAu: this.dateCreationAu,

            nom: this.lastName,
            prenom: this.firstName,
            raisonSociale: this.companyName,
            getOnlyImpayes: this.unpaid,
          })
          .then((response) => {
            // Success 🎉
            // console.log(response.data)
            let items = response.data.contractList;
            const total = parseInt(response.data.totalCount, 10); //string to int mandatory

            resolve({
              items,
              total,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    getGlobalStatus() {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/listItems/globalStatus", {})
          .then((response) => {
            // Success 🎉
            //console.log(response.data)
            let globalStatus = response.data.listItems;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              globalStatus,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {
    // Debug EX-105
    options: {
      async handler() {
        await this.getDataFromApi()
      },
      deep: true,
    },
    singleContrat: {
      handler() {
        this.getContrat(this.singleContrat);
      },
    },
  },
};
</script>
