<template>
  <v-container fluid class="container">
    <ValidationObserver ref="paymentModeObs" v-slot="{ valid }">
      <v-form style="width: 100%">
        <v-card>
          <v-card-title> Mode de paiement </v-card-title>
          <v-card-text>
            <ValidationProvider v-slot="{ errors }" vid="selectPaymentCash" name="mode de paiement comptant"
              rules="required">
              <v-select v-model="payementModes.comptant" :items="itemsLists.paymentTypesCMPT" item-text="libelle"
                item-value="code" label="Mode de paiement comptant *" return-object :error-messages="errors"
                :loading="listsLoading" outlined  hide-selected required
                @input="$refs.paymentModeObs.validate()"></v-select>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" vid="selectPaymentRenew" name="mode de paiement renouvellement"
              rules="required">
              <v-select v-model="payementModes.renouvellement" :items="itemsLists.paymentTypesCMPT" item-text="libelle"
                item-value="code" label="Mode de paiement renouvellement *" return-object :error-messages="errors"
                outlined clearable :loading="listsLoading" hide-selected required disabled></v-select>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" vid="selectPaymentSec" name="mode de paiement termes secondaires"
              :rules="{ required: splitting != 'A' }">
              <v-select v-if="splitting != 'A'" v-model="payementModes.termeSecondaire" :loading="listsLoading"
                :items="itemsLists.paymentTypesTESEC" item-text="libelle" item-value="code"
                label="Mode de paiement termes secondaires *" return-object :error-messages="errors" outlined clearable
                hide-selected required></v-select>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" vid="debitDay" name="jour de prélévement" rules="required">
              <v-select v-model="payementModes.debitDay" :items="itemsLists.debitDays" label="Jour de prélévement *"
                :error-messages="errors" item-text="libelle" item-value="code" outlined clearable hide-selected
                :loading="listsLoading" required></v-select>
            </ValidationProvider>
          </v-card-text>
          
          <div v-if="isRequired">
          <v-card-title> Adresse bancaire </v-card-title>

          <v-card-text>
            <ValidationProvider v-slot="{ errors }" vid="selectType" name="type d'adresse bancaire"
              :rules="{ required: isRequired }">
              <v-select v-model="bankAccount.type" :items="itemsLists.types" item-text="libelle" item-value="code"
                label="Type d'adresse bancaire *" return-object :error-messages="errors" outlined :loading="listsLoading"
                clearable hide-selected></v-select>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" vid="selectPays" name="pays" :rules="{ required: isRequired }">
              <v-select v-model="bankAccount.country" :items="itemsLists.countries" item-text="libelle" item-value="code"
                label="Pays *" return-object :error-messages="errors" outlined :loading="listsLoading" clearable
                hide-selected></v-select>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" vid="selectDevise" name="devise" :rules="{ required: isRequired }">
              <v-select v-model="bankAccount.currency" :items="itemsLists.currencies" item-text="libelle"
                item-value="code" label="Devise *" :loading="listsLoading" return-object :error-messages="errors" outlined
                clearable hide-selected></v-select>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" vid="titulaire" name="titulaire"
              :rules="{ required: isRequired, max: 50 }">
              <v-text-field outlined :error-messages="errors" v-model.trim="bankAccount.accountOwner" label="Titulaire *"
                class="upperCaseInput" clearable
                @input="bankAccount.accountOwner = bankAccount.accountOwner.toUpperCase()"></v-text-field>
            </ValidationProvider>
            <!--
            <ValidationProvider
              v-slot="{ errors }"
              vid="codeIBAN"
              name="code iban"
              :rules="{ required: isRequired, validateIban: true }"
            >
              <v-text-field
                :error-messages="errors"
                v-model.trim="bankAccount.ibanCode"
                label="Code IBAN *"
                outlined
                class="upperCaseInput"
                clearable
                @input="bankAccount.ibanCode = bankAccount.ibanCode.toUpperCase()"
              ></v-text-field>
            </ValidationProvider>
-->
            <v-row>
              <v-col>
                <ValidationProvider v-slot="{ errors }" vid="ribBanque" name="ribBanque"
                  :rules="{ required: isRequired }">
                  <v-text-field :error-messages="errors" v-model.trim="bankAccount.ribBanque" label="Code Banque *"
                    outlined class="upperCaseInput" clearable
                    @input="bankAccount.ribBanque = bankAccount.ribBanque.toUpperCase()"></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider v-slot="{ errors }" vid="ribGuichet" name="ribGuichet"
                  :rules="{ required: isRequired }">
                  <v-text-field :error-messages="errors" v-model.trim="bankAccount.ribGuichet" label="Code Guichet *"
                    outlined class="upperCaseInput" clearable
                    @input="bankAccount.ribGuichet = bankAccount.ribGuichet.toUpperCase()"></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider v-slot="{ errors }" vid="ribCompte" name="ribCompte"
                  :rules="{ required: isRequired }">
                  <v-text-field :error-messages="errors" v-model.trim="bankAccount.ribCompte" label="N° Compte *" outlined
                    class="upperCaseInput" clearable
                    @input="bankAccount.ribCompte = bankAccount.ribCompte.toUpperCase()"></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider v-slot="{ errors }" vid="ribCle" name="ribCle" :rules="{ required: isRequired }">
                  <v-text-field :error-messages="errors" v-model.trim="bankAccount.ribCle" label="Cle rib *" outlined
                    class="upperCaseInput" clearable
                    @input="bankAccount.ribCle = bankAccount.ribCle.toUpperCase()"></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-alert type="warning" :value="true" v-if="rib.trim() !== '' && validateRIB(rib) === false">
              Le RIB doit être valide pour pouvoir valider le contrat
            </v-alert>

            <!--
            <ValidationProvider v-slot="{ errors }" vid="codeBIC" name="code bic"
              :rules="{ required: isRequired, validateBic: true }">
              <v-text-field v-model.trim="bankAccount.bicCode" :error-messages="errors" :counter="50" label="Code BIC *"
                outlined class="upperCaseInput" clearable
                @input="bankAccount.bicCode = bankAccount.bicCode.toUpperCase()"></v-text-field>
            </ValidationProvider>
            -->
            <ValidationProvider v-slot="{ errors }" vid="domiciliation" name="domiciliation"
              :rules="{ required: isRequired, max: 50 }">
              <v-text-field v-model.trim="bankAccount.domiciliation" :error-messages="errors" :counter="50"
                label="Domiciliation *" outlined class="upperCaseInput" clearable
                @input="bankAccount.domiciliation = bankAccount.domiciliation.toUpperCase()"></v-text-field>
            </ValidationProvider>
          </v-card-text>
        </div>
          <v-card-actions>
            <v-btn block large color="button" class="button_color--text"
              :disabled="createContractLoading || (!payementModes.comptant) || ($store.state.transformation.contractId && $store.state.transformation.contractId !== '') || (validateRIB(rib) === false && (payementModes.comptant === 'IN_PRLVT' || payementModes.comptant === 'IN_ECH_PRLVT' || rib.trim() !== ''))"
              :loading="createContractLoading" @click="contractValidation">
              Valider votre contrat
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>autorenew</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<style>
.container {
  padding: 0 !important;
}

.upperCaseInput input {
  text-transform: uppercase;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<script>


import { mapState, mapActions } from "vuex";

const ibantools = require("ibantools");
import Vue from 'vue';
import { required, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { localize } from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);
setInteractionMode("eager");
function validateIBAN(iban) {
  // 1. Normaliser l'IBAN
  iban = iban.replace(/\s/g, '').toUpperCase();

  // 2. Valider la longueur et le format de base
  if (iban.length !== 27 || !iban.match(/^NC\d+/)) {
    return false;
  }

  // 3. Déplacer les 4 premiers caractères à la fin
  iban = iban.substring(4) + iban.substring(0, 4);

  // 4. Convertir les lettres en nombres
  let numericIBAN = '';
  for (let i = 0; i < iban.length; i++) {
    const character = iban[i];
    numericIBAN += (character >= 'A' && character <= 'Z') ? (character.charCodeAt(0) - 'A'.charCodeAt(0) + 10) : character;
  }

  // 5. Calculer le modulo 97
  let remainder = 0;
  for (let i = 0; i < numericIBAN.length; i++) {
    remainder = (remainder * 10 + parseInt(numericIBAN[i], 10)) % 97;
  }
  console.log("remainder", remainder);

  return remainder === 1;
}



extend("required", {
  ...required,
  // message: '{_field_} can not be empty',
});

extend("max", {
  ...max,
  // message: '{_field_} can not be empty',
});

extend("validateIban", {
  message: (field) => "Le format du code IBAN n'est pas correct",
  validate: (value) => {
    const isValidFormat = ibantools.isValidIBAN(value);
    //const isValidCodeIban = Vue.prototype.$ibanStartsWith.includes(value.substring(0, 2));
    console.log("isValidFormat", isValidFormat);
    //console.log("isValidCodeIban",isValidCodeIban);
    console.log("IBAN", value);
    console.log("MyValidateIBAN", validateIBAN(value));

    return isValidFormat;// && isValidCodeIban;
  },
});

function validateRIB(rib) {
  // Supprimer les espaces
  rib = rib.replace(/\s/g, '');
  console.log("rib", rib);
  // Vérifier la longueur du RIB (23 caractères)
  if (rib.length !== 23) {
    console.log("rib.length", rib.length);
    return false;
  }

  // Récupérer les parties du RIB
  var bankCode = rib.substring(0, 5);
  var branchCode = rib.substring(5, 10);
  var accountNumber = rib.substring(10, 21);
  var ribKey = rib.substring(21);

  // Convertir les lettres en nombres (A=1, B=2, ..., Z=26) et créer une chaîne de nombres
  var accountNumberConverted = '';
  for (var i = 0; i < accountNumber.length; i++) {
    var char = accountNumber[i];
    if (isNaN(char)) {
      accountNumberConverted += (char.charCodeAt(0) - 55).toString();
    } else {
      accountNumberConverted += char;
    }
  }

  // Convertir tous les éléments en BigInt
  var bigBankCode = BigInt(bankCode);
  var bigBranchCode = BigInt(branchCode);
  var bigAccountNumber = BigInt(accountNumberConverted);
  var bigRibKey = BigInt(ribKey);
  console.log("bigBankCode", bigBankCode);
  console.log("bigBranchCode", bigBranchCode);
  console.log("bigAccountNumber", bigAccountNumber);
  console.log("bigRibKey", bigRibKey);

  // Calculer la clé RIB
  var computedKey = 97n - (((89n * bigBankCode) + (15n * bigBranchCode) + (3n * bigAccountNumber)) % 97n);
  console.log("computedKey", computedKey, bigRibKey, computedKey === bigRibKey);
  // Comparer la clé calculée avec la clé du RIB
  return computedKey === bigRibKey;
}

extend("validateRIB", {
  message: (field) => "Le format du code RIB n'est pas correct",
  validate: validateRIB
});

extend("validateBic", {
  message: (field) => "Le format du code BIC n'est pas correct",
  validate: (value) => {
    return ibantools.isValidBIC(value);
  },
});

export default {
  name: "PayementMethod",

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data: () => ({

  }),
  props: {},
  created() {
    this.getPayementModesItemsLists();
    if (!this.$store.state.transformation.estimate.person.bankAccount) {
      logger.debug('Création d\'un compte bancaire vide')
      this.$store.commit("transformation/createBankAccount");
    }
  },
  mounted() {


  },
  computed: {
    ...mapState({
      payementModes: (state) => state.transformation.estimate.payementModes,
      bankAccount: (state) => state.transformation.estimate.person.bankAccount,
      splitting: (state) => state.transformation.estimate.formula.splittingCode,
      itemsLists: (state) => state.transformation.itemsLists,
      listsLoading: (state) => state.transformation.loader["listModePayement"],
      createContractLoading: (state) =>
        state.transformation.loader["createContract"],
      rib: (state) => {
        const bankAccount = state.transformation.estimate.person.bankAccount;
        if (bankAccount.ribBanque && bankAccount.ribGuichet && bankAccount.ribCompte && bankAccount.ribCle) {
          const rib = String(bankAccount.ribBanque) + String(bankAccount.ribGuichet) + String(bankAccount.ribCompte) + String(bankAccount.ribCle);
          if (rib) {
            return rib;
          } else {
            return '';
          }
        } else {
          return '';
        }
      },
      isRequired: function (state) {
        return (
          state.transformation.estimate.payementModes.comptant.code === "IN_PRLVT"
           ||  state.transformation.estimate.payementModes.comptant.code === "IN_ECH_PRLVT"
        );
      }
    }),


  },
  methods: {
    ...mapActions("transformation", [
      "getPayementModesItemsLists",
      "createContract",
      "getContractDetails",
    ]),
    validateRIB,
    async contractValidation() {
      console.log("contractValidation CALL ");
      logger.debug('Click "Valider votre contrat"')
      await this.$refs["paymentModeObs"].validate().then(async (valid) => {

        //tout parcours : le RIB ne doit pas etre obligatoire 
        // - si le mode est CB et que la prime est annuel (pas d'échéancier)
        if((
          this.$store.state.transformation.estimate.payementModes.comptant.code === "IN_PRLVT" 
        || this.$store.state.transformation.estimate.formula.splittingCode === "IN_ECH_PRLVT")){
          if (validateRIB(this.rib)) {
          logger.debug('RIB valide')
          console.log("RIB valide");
          } else {
            logger.debug('RIB invalide');
            console.log("RIB invalide");
            return;
          }
        }


      
        if (valid) {
          try {
            const res = await this.createContract();
            if(res !== true){
              throw res;
            }
            if (this.$store.state.transformation.contractId != "")
              await this.getContractDetails();
            if (Object.keys(this.$store.state.transformation.contract).length > 0)
              this.$store.commit("transformation/nextTransformationStep");
          } catch (e) {
            console.error("e", e);
          }
        } else
          logger.debug('Formulaire de payement invalide')
      });
    },
    closeDialog: function () {
      this.$emit("close-dialog");
    },
    updateBankAdressDetails(updValue) {
      this.$set(this.estimateModel, "bankAdressDetails", updValue);
    },
  },
  watch: {
    "payementModes.comptant": {
      handler: function (val, oldVal) {
        this.$store.commit("transformation/updPayModeRenou", val);
      },
      deep: true,
    },
    bankAccount: {
      handler: function (val, oldVal) {
        this.$store.state.transformation.estimate.person.bankAccount.id = '-1';
        this.$store.commit("transformation/updBankAccount", val);
        console.log("bankAccount", val);
      },
      deep: true,
    }
  },
};
</script>
