<template>
  <div>
    <!-- CHANGER LA CONDITION SUR LES EDITICS -->
    <div v-if="!isEditicsLoaded">
      <ValidationObserver ref="autoOptsDisclaimer" v-slot="{ valid }">
        <v-form @submit.prevent="estimateHandler" style="width: 100%">
          <v-row>
            <v-col cols="3" sm="3">
              <v-menu ref="menuEffectDate" v-model="menuEffectDate" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider v-slot="{ errors }" rules="required|effectDateRule" name="date d'effet">
                    <v-text-field v-model="formatedEffectDate" label="Date d'effet" outlined :error-messages="errors"
                      prepend-icon="calendar" clearable clear-icon="clear" @click:clear="effectDate = ''" v-bind="attrs"
                      @blur="effectDate = parseDate(formatedEffectDate)" v-on="on" required readonly
                      :disabled="loading || createLoading"></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker :min="estimate.currentDate"  v-model="effectDate" scrollable header-color="tertiary" color="tertiary_light"
                  first-day-of-week="1" no-title @input="menuEffectDate = false"></v-date-picker>
              </v-menu>
            </v-col>
            <!-- <v-col >
                <v-icon>
                  add
                </v-icon>
            </v-col>-->
            <v-col cols="3" sm="3" style="color: grey">
              La date d'effet doit être comprise dans les 35 prochains jours à
              partir de ce jour.
            </v-col>
            <!-- <v-spacer /> -->
            <v-col v-if="estimate.product.code == $codeGav" cols="3" sm="3">
              <Fractionnement ref="fractionnementForm" />
            </v-col>
            <v-col v-if="estimate.product.code === 'GPJNC'" cols="3" sm="3">
              <Fractionnement ref="fractionnementForm" />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="estimate.product.code == $codeAuto" cols="12">
              <v-card>
                <v-card-title>Vos options :</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-checkbox v-model="estimate.productRisk.options.conduiteExclusive" true-value="true" false-value="false"  hide-details
                        color='button_light'>
                        <template v-slot:label>
                          <div>Conduite exclusive</div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <OptionalGuaranteesEligible ref="OptionalGuaranteesEligibleForm" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="pb-3">
                        <Franchises />
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="pb-3">
                        <Fractionnement />
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="estimate.product.code == $codeMrhBudget">
              <OptsMrh />
            </v-col>

            <v-col cols="4" offset="8">
              <v-btn block large color="button" class="button_color--text" type="submit"
                :disabled="loading || createLoading">
                <v-icon left>search</v-icon>
                Calculer
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Selectionner votre formule et votre fractionnement</v-card-title>
            <v-card-text>
              <v-skeleton-loader v-show="loading && Object.keys(estimation).length == 0" :loading="true"
                type="table-thead, table-tbody"></v-skeleton-loader>
              <v-data-table v-show="Object.keys(estimation).length > 0" :headers="estimation['headers']"
                :items="estimation['data']" hide-default-footer :loading="loading">
                <template v-slot:body="{ items }">
                  <tr v-for="row in items" :key="row.splitting">
                    <td v-for="(cellData, index) in row" :key="index">
                      <v-radio-group v-if="index != 'splitting' && !index.includes('info_')" v-model="formulaSelection">
                        <v-radio :disabled="loading || createLoading" :value="row.splitting + '_' + index">
                          <template v-slot:label>
                            <div>
                              <h4>{{ cellData }}</h4>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <h3 v-else-if="index === 'splitting'">{{ cellData }}</h3>
                      <h4 style="padding-left: 32px; font-style: italic;" v-else>{{ cellData }}</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>

                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text>
              <p>
                Pour les fractionnements semestriels, trimestriels et mensuels,
                les frais seront appliqués sur le comptant. Les montants
                affichés dans le tableaux ci-dessus pour ces fractionnements
                sont les échéances secondaires.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              Vos garanties et options incluses:
              {{ this.formulaSelection.replace("_", " - ") }}
            </v-card-title>
            <v-row>
              <v-col>
                <!-- GLA_ENF-6 -->
                <v-simple-table v-if="Object.keys(estimation).length > 0 &&
      estimation['formulas'][formulaSelection] != undefined
      ">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Garantie</th>
                        <th class="text-right">TTC</th>
                        <th class="text-right">Franchise</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(guarantee, i) in estimation['formulas'][
      formulaSelection
    ].guarantees.filter(function (g) {
      return g.isSuscribed
    })" :key="i">
                        <td>{{ guarantee.libelle }}</td>
                        <td class="text-right">{{ guarantee.amount }} {{ $currency }}</td>
                        <td class="text-right" v-if="guarantee.franchiseValue > 0">
                          {{ guarantee.franchiseValue }}
                          {{ guarantee.franchiseCurrencylabel }}
                        </td>
                        <td v-else></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="4" offset="8">
          <v-btn block large :loading="createLoading" :disabled="this.formulaSelection == '' || createLoading"
            color="button" class="button_color--text" @click="createEstimateHandler()">
            <v-icon left>search</v-icon>
            Valider Tarif
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>autorenew</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="isEditicsLoaded">
      <v-row>
        <v-col cols="12">
          <b class="ml-5">
            {{ estimate.product.libelle }}: votre devis n°
            <a @click="goToCreatedEstimation">{{ estimate.number }}</a>
            à bien été créé.
          </b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <EditicsDataTable :data="this.editics.data" :headers="this.editics.headers" :options="this.editics.options"
            :isEstimation="true" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" offset="6">
          <v-btn block large color="button" class="button_color--text" @click="
      $router.push({
        name: 'Transformer un devis en contrat ',
        params: {
          estimateId: estimate.id,
          fromEstimation: true,
        },
      })
      ">
            <v-icon left>done</v-icon>
            Transformer en contrat
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn block large color="button" class="button_color--text" @click="$router.push('/')">
            <v-icon left>home</v-icon>
            Retourner à l'accueil
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="snackbar.state" :color="snackbar.content.type" :multi-line="true"
      :timeout="snackbar.content.type == 'succes' ? 1000 : 5000">
      {{ snackbar.content.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="button_color--text" text v-bind="attrs" @click="snackbar.state = false">Fermer</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.v-slider__thumb-label,
.v- -label-container {
  position: fixed;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

<script>
import axios from "axios";
import EditicsDataTable from "../../components/EditicsDataTable.vue";
import OptsMrh from "./OptsMrh.vue";
import { mapState, mapActions } from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

import Vue from 'vue';
import Franchises from "@/components/estimation/Franchises.vue";
import Fractionnement from "@/components/estimation/Fractionnement.vue";
import OptionalGuaranteesEligible from "@/components/estimation/OptionalGuaranteesEligible.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});

const customEffectDateRule = function (value) {
  if (value != undefined) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var inRangedAllowedDate = Object.assign(new Date(), today);

    /* console.log(inRangedAllowedDate) */
    var parts = value.split("/");
    var occurrenceDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );

    inRangedAllowedDate.setDate(inRangedAllowedDate.getDate() + 35);

    return today.getTime() <= occurrenceDate.getTime()
      ? inRangedAllowedDate.getTime() >= occurrenceDate.getTime()
        ? true
        : ""
      : "";
  }
};

extend("effectDateRule", {
  validate(value) {
    return customEffectDateRule(value);
  },
});

extend("isValid", {
  params: ["drivers"],
  validate(value, { drivers }) {
    if (value != undefined) {
      return !(value == "OUI" && Object.keys(drivers).length > 1);
    }
  },
  message:
    "Il n'est pas possible de selectionner la conduite exclusive car plusieurs conducteurs sont déclarés.",
});

export default {
  name: "Pricing",
  components: {
    OptionalGuaranteesEligible,
    Franchises,
    Fractionnement,
    EditicsDataTable,
    ValidationObserver,
    ValidationProvider,
    OptsMrh
  },
  data: () => ({
    driverExclusive: false,
    menuEffectDate: false,
    formatedEffectDate: null,

    valid: false,
    isEditicsLoaded: false,

    contractId: "",
    contractNumber: "",

    formulaSelection: "",

    editics: {
      data: [],
      headers: [],
      options: [],
    },
  }),
  props: {},
  created() {
    if (this.estimate.product.code == Vue.prototype.$codeAuto) this.getAutoOptsItemsLists();
  },
  async mounted() {
    const productsCodeReq = await axios.get("/api/listItems/MODUL_FRANCH_BDG");
    console.log(productsCodeReq);
  },
  computed: {
    ...mapState({
      loading: (state) => state.estimation.loader["getEstimation"],
      createLoading: (state) => state.estimation.loader["createEstimate"],
      listsLoading: (state) => state.estimation.loader["listAutoOpts"],
      options: (state) => state.estimation.estimate.productRisk.options ?? {},
      estimate: (state) => state.estimation.estimate,
      itemsLists: (state) => state.estimation.itemsLists,
      estimation: (state) => state.estimation.estimation,
      snackbar: (state) => state.estimation.snackbars["pricing"],
    }),
    effectDate: {
      get: function () {
        return this.estimate.effectDate;
      },
      set: function (v) {
        if (v != undefined) {
          let now = new Date().toISOString();
          if (this.isToday(new Date(v)))
            this.$store.commit("estimation/updEffectDate", now);
          else this.$store.commit("estimation/updEffectDate", v);
        }
      },
    },
    drivers: function () {
      return this.estimate.productRisk.drivers;
    },
  },
  methods: {
    ...mapActions("estimation", [
      "getEstimation",
      "createEstimate",
      "getAutoOptsItemsLists",
    ]),
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
    goToCreatedEstimation() {
      this.$router.push("/contract/search/" + this.estimate.id);
    },
    formatDate(date) {
      if (!date) return null;
      return new Date(date).toLocaleDateString('fr-FR', { timeZone: 'Pacific/Noumea' });
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    estimateHandler() {
      console.log(this.$refs["autoOptsDisclaimer"])
      this.$refs["autoOptsDisclaimer"].validate().then((valid) => {
        console.log("FORM IS VALID:", valid)
        //console.log(this.getEstimation())
        if (valid) {
          let options = {};
          if (this.$refs.fractionnementForm) {
            options = { ...options, ...this.$refs.fractionnementForm.options };
          }
          if (this.$refs.OptionalGuaranteesEligibleForm) {
            console.log("this.$refs.OptionalGuaranteesEligibleForm.options", this.$refs.OptionalGuaranteesEligibleForm)
            options = { ...options, ...this.$refs.OptionalGuaranteesEligibleForm.options };
          }
          console.log("OPTIONS", options)
          /* this.firstLoad = true; */
          this.getEstimation(options);
        }
      });
    },
    createEstimateHandler() {
      this.$refs["autoOptsDisclaimer"].validate().then((valid) => {
        if (valid) {
          this.createEstimate();
        }
      });
    },
    getEditicsPromise() {
      return new Promise((resolve, reject) => {
        //validate form
        axios
          .post("/api/editics/get", {
            contractId: this.estimate.id,
          })
          .then((response) => {
            // Success 🎉

            let editics = response.data.editics;
            let success = response.data.success;
            let message = response.data.message;
            let errors = response.data.errors;

            resolve({
              editics,
              success,
              message,
              errors,
            });
          })
          .catch((error) => {
            logger.error("Récuperation des éditiques: " + error.message);
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
  },
  watch: {
    effectDate(val) {
      this.formatedEffectDate = this.formatDate(val);
    },
    "estimate.id": function (val, oldVal) {
      logger.info("Récuperation des éditiques..");
      this.getEditicsPromise().then((data) => {
        this.editics = data.editics;
        this.success = data.success;
        this.message = data.message;
        this.errors = data.errors;
        this.isEditicsLoaded = true;
      });
    },
    "estimate.product.code": {
      handler(val, oldVal) {
        if (val == Vue.prototype.$codeAuto) {
          logger.debug("Récuperation des lists d'options pour le produit Auto");
        }
      },
      deep: true,
    },
    // Reset le choix de la formule et des options lorsque la tarification est modifié
    estimation: function (val, oldVal) {
      logger.debug(
        "Remise à zéro du choix de la formule - Nouvel tarification"
      );
      this.formulaSelection = "";
    },
    formulaSelection: function (val, oldVal) {
      if (val != "") {
        logger.debug("Selection de la formule choisie");
        this.$store.commit("estimation/updFormula", val);
      }
    },
    // Reinit. la tarification quand le risk est modifié
    "estimate.productRisk": {
      handler(val, oldVal) {
        logger.debug(
          "Remise à zéro de la tarification - Modification du risque"
        );
        this.$store.commit("estimation/resetEstimation");
      },
      deep: true,
    },
    "estimate.person": {
      handler(val, oldVal) {
        logger.debug(
          "Remise à zéro de la tarification - Modification du souscripteur"
        );
        this.$store.commit("estimation/resetEstimation");
      },
      deep: true,
    },
    formatedEffectDate(val) {
      this.$store.commit("estimation/resetEstimation");
    },
    // Reinit. les frais de courtages lorsque le coeff de reduc est > 0
    /* "options.coeffReduc": function (val, oldVal) {
      if (val > 0) this.options.brokerFees = "0";
    }, */
  },
};
</script>
