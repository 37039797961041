<template>
  <v-row>
    <v-col>
      <ValidationProvider v-slot="{ errors }" rules="required" name="Facilité de paiement">
        <v-select :loading="loading" :disabled="loading || isTransformation" :items="itemsLists.fractionnements" item-text="libelle"
          item-value="code" v-model="options.fractionnement" label="Facilité de paiement" return-object outlined required
          :error-messages="errors" />
      </ValidationProvider>
    </v-col>
    <!--
    <v-col>
      <ValidationProvider v-slot="{ errors }" rules="required" name="% de remise commerciale">
        <v-text-field v-model="options.pourcentageRemiseCommerciale" label="% de remise commerciale" outlined clearable
          clear-icon="clear" required :error-messages="errors" />
      </ValidationProvider>
    </v-col>
    <v-col>
      <ValidationProvider v-slot="{ errors }" rules="required" name="Justification % de remise">
        <v-select :loading="loading" :disabled="loading" :items="itemsLists.justificationsRemise" item-text="libelle"
          item-value="code" v-model="options.justificationRemise" label="Justification % de remise" return-object outlined
          required :error-messages="errors" />
      </ValidationProvider>
    </v-col>
    -->
  </v-row>
</template>

<style scoped></style>

<script>

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState, mapActions } from "vuex";
import Vue from 'vue';
import axios from "axios";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "Le champ {_field_} est requis",
});


export default {
  name: "Fractionnement",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ["isTransformation"],
  data: () => ({
    loading: true,
    perteFinanciere: false,
    itemsLists: {
      justificationsRemise: [],
      fractionnements: []
    }
  }),
  computed: {
    ...mapState({
      options: (state) => {
        if(state.transformation) { 
          console.log("IS TRANSFORMATION",state.transformation)
          return {
            "fractionnement": state.transformation.estimate.formula.splittingCode
          }
        }
        console.log("##state.estimation",state.estimation);
        return state.estimation?.estimate?.productRisk?.options ?? {}
      },
    })
  },
  methods: {},
  async mounted() {
    try {
      const responses = await axios
        .all([
          axios.get("/api/listItems/JUSTIFS_REMISE"),
          axios.get("/api/listItems/FRACTIONNEMENT"),
        ]);
      this.itemsLists.justificationsRemise = responses[0].data.listItems;
      this.itemsLists.fractionnements = responses[1].data.listItems;
    } catch (error) {
      this.handleAxiosError(error);
    } finally {
      this.loading = false;
    }
  }
};
</script>
