var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Vos options :")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"niveau Franchise IDV"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.franchiseIDV,"return-object":"","item-text":"libelle","item-value":"code","label":"Franchise IDV","outlined":"","loading":_vm.loader,"error-messages":errors,"disabled":_vm.readonly},model:{value:(_vm.options.franchiseIDV),callback:function ($$v) {_vm.$set(_vm.options, "franchiseIDV", $$v)},expression:"options.franchiseIDV"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"bris de glace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.yesNo,"return-object":"","item-text":"libelle","item-value":"code","label":"Bris de glace","outlined":"","loading":_vm.loader,"error-messages":errors,"disabled":_vm.readonly},model:{value:(_vm.options.brisGlace),callback:function ($$v) {_vm.$set(_vm.options, "brisGlace", $$v)},expression:"options.brisGlace"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"dezonage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.yesNo,"return-object":"","item-text":"libelle","item-value":"code","label":"Dezonage","outlined":"","loading":_vm.loader,"error-messages":errors,"disabled":_vm.readonly},model:{value:(_vm.options.dezonage),callback:function ($$v) {_vm.$set(_vm.options, "dezonage", $$v)},expression:"options.dezonage"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"protection juridique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.yesNo,"return-object":"","item-text":"libelle","item-value":"code","label":"Protection Juridique","outlined":"","loading":_vm.loader,"error-messages":errors,"disabled":_vm.readonly},model:{value:(_vm.options.juridicProtection),callback:function ($$v) {_vm.$set(_vm.options, "juridicProtection", $$v)},expression:"options.juridicProtection"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"RC. Vie Privee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.yesNo,"return-object":"","item-text":"libelle","item-value":"code","label":"RC. Vie Privee","outlined":"","loading":_vm.loader,"error-messages":errors,"disabled":_vm.readonly},model:{value:(_vm.options.rcViePrivee),callback:function ($$v) {_vm.$set(_vm.options, "rcViePrivee", $$v)},expression:"options.rcViePrivee"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"vol"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.itemsLists.yesNo,"label":"Vol","outlined":"","loading":_vm.listsLoading,"error-messages":errors,"disabled":_vm.readonly},model:{value:(_vm.options.vol),callback:function ($$v) {_vm.$set(_vm.options, "vol", $$v)},expression:"options.vol"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }