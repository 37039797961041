<template>
  <v-container fluid class="container">
    <v-card elevation="0" class="productCard" color="list_background_light">
      <v-card-text>
        <v-row>
          <v-col v-show="this.$parent.$parent.$parent.deleteMode">
            <v-checkbox
              color="red"
              v-model="$parent.$parent.$parent.selected.products"
              :value="product.id"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="product.title"
              label="Nom du produit"
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="product.menuTitle"
              label="Nom affichage menu"
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="product.okayoEquivalent"
              :items="listItems['productsCode']"
              label="Equivalent Okayo"
              outlined
              hide-details
              item-text="libelle"
              item-value="code"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="product.unauthorizedCourtier"
              :items="codeProduits"
              label="Courtier unauthorized"
              multiple
              outlined
              hide-details
              disabled
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 0" class="grey--text text-caption">
                  (+{{ product.unauthorizedCourtier.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-col>
          <!-- <v-col  cols="1">
                        <v-btn
                            color="orange"
                            x-large
                            icon
                            v-on:click="removeProduct(gIndex + '_' + pIndex)"
                        >
                            <v-icon x-large>close</v-icon>
                        </v-btn>
                    </v-col> -->
          <v-col offset="2">
            <v-btn
              color="tertiary_light"
              x-large
              icon
              v-on:click="openProductCard(product.id.toString())"
            >
              <v-icon x-large
                >{{
                  openedProduct === product.id.toString()
                    ? "expand_less"
                    : "expand_more"
                }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-expand-transition>
                <div v-show="this.openedProduct == product.id ? true : false">
                    <v-row

                    >
                        {{this.files}}
                    </v-row>
                </div>
            </v-expand-transition> -->
      <v-expand-transition>
        <div
          v-show="this.openedProduct === product.id.toString() ? true : false"
        >
          <v-divider></v-divider>
          <v-card elevation="0" color="list_background_lighter">
            <v-card-text>
              <v-row
                v-for="productFile in product.productFiles"
                :key="productFile.id"
              >
                <v-col cols="1" align-self="center">
                  {{ productFile.productFileType }}
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="productFile.fileLibelle"
                    label="Libelle fichier"
                    outlined
                    hide-details
                  />
                </v-col>
                <v-col cols="3">
                  <v-file-input
                    chips
                    :label="productFile.file ? productFile.file.fileName : ''"
                    outlined
                    hide-details
                    show-size
                    @change="addFile($event, productFile)"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<style scoped>
.container {
  margin: 0 !important;
  padding: 0 !important;
}
.productCard {
  margin: 2.5px;
}
</style>

<script>
import axios from "axios";
export default {
  name: "Product",
  data: () => ({
    productFiles: [],
    productsCode: [],
    codeProduits: [],
  }),
  props: {
    product: Object,
    openedProduct: String,
    openProductCard: Function,
    filesData: Array,
    listItems: Array,
  },
  /** @todo add babel */
  created() {},

  mounted() {
    this.productFiles = this.product.productFiles;
  },
  computed: {},
  methods: {
    addFile(file, productFile) {
      // console.log(this.product.id + "_" + productFile.productFileType);
      if (productFile.file != null && productFile.file.hashedName != "") {
        this.$parent.$parent.$parent.filesIdsToDelete.push(
          productFile.file.hashedName
        );
      }
      this.$set(
        this.filesData,
        this.product.id + "_" + productFile.productFileType,
        file
      );
    },
  },
  watch: {
    productFiles: function () {
      // At each modification of data.files
      // If files.length = 0 add new entry for each file type
      if (this.productFiles.length == 0) {
        let fileTypes = ["Fiche", "CG", "Souscription"];
        let productFiles = [];

        fileTypes.forEach((type) => {
          let file = {
            id: "tmp_" + Math.floor(Math.random() * 5000),
            productId: this.product.id,
            productFileType: type,
            fileLibelle: "",
            file: null,
          };

          this.$set(this.productFiles, this.productFiles.length, file);
        });
      }
    },
  },
};
</script>
