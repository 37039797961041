<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ valid }">
      <PrincipalAssured
        :mainInsured="mainInsured"
        :itemsLists="itemsListsClient"
        :loader="false"
      ></PrincipalAssured>
      <SecondariesAssured :secondariesAssured="secondariesAssured" :defaultSecondaryAssured="Object.assign(defaultSecondaryAssured)" :itemsLists="itemsLists"></SecondariesAssured>
    </ValidationObserver>
    <v-row>
      <v-spacer />
      <v-col>
        <v-btn block large color="button" class="button_color--text" @click="nextStep">Suivant</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
</style>

<script>


const schema = {
  type: "object",
  properties: {
    secondariesAssured: {
      title: "Assurés",
      description: "Conjoint et enfants à charge de - de 26 ans",
      properties: {
        secondariesAssured: {
          description: "",
          type: "array",
          "x-display": "custom-inputTable",
          items: {
            type: "object",
          },
          inputTable: {
            // !!!!!!!!!!!!!!!!! Possible de garder la syntaxe de vjsf comme en haut pour un custom component ?
            lastname: {
              header: "Nom",
              type: "string",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            firstname: {
              header: "Prenom",
              type: "string",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            civility: {
              header: "Civilité",
              type: "list",
              remoteListItems:
                "/api/listItems/civilities", 
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelleCourt",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            relation: {
              header: "Relation",
              type: "list",
              remoteListItems:
                "/api/listItems/relations",
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelle",
              required: true,
              rules: [(v) => !!v || "Relation requise"],
              hideErrorDetails: false,
            },
            maritalStatus: {
              header: "Sit. Mat.",
              type: "list",
              remoteListItems:
                "/api/listItems/marStat",
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelle",
              required: true,
              rules: [(v) => !!v],
              hideErrorDetails: true,
            },
            birthDate: {
              header: "Date de naissance",
              type: "date",
              required: true,
              rules: [
                (v) => !!v/* ,
                (v) => {
                      var today = new Date();
                      var parts = v.split("/");
                      var birthDate = new Date(
                        parseInt(parts[2], 10),
                        parseInt(parts[1], 10) - 1,
                        parseInt(parts[0], 10)
                      );

                      var age = today.getFullYear() - birthDate.getFullYear();
                      var m = today.getMonth() - birthDate.getMonth();
                      if (
                        m < 0 ||
                        (m === 0 && today.getDate() < birthDate.getDate())
                      ) {
                        age--;
                      }

                      return (
                        (age >= 18 && age <= 77) && this.editedItem.relation == 'Enfant' ||
                        "L'age doit être compris entre 18 et 77 ans."
                      );
                    },
                
                /* , v => Math.floor((Date.now().toLocaleString('fr-FR') - new Date(v))/1000/60/60/24/365) > 26 &&  this.editedItem.relation == 'Enfant' || 'No way' */,
               ],
            },
            socioProfessionalCategory: {
              header: "CSP",
              type: "list",
              remoteListItems: "/api/listItems/csps",
              remoteListProp: "listItems",
              remoteListKey: "code",
              remoteListValue: "libelle",
              required: false,
              /* rules: [(v) => !!v], */
              hideErrorDetails: true,
            },
            profession: {
              header: "Profession",
              type: "string",
              required: false,
             /*  rules: [(v) => !!v], */
              hideErrorDetails: true,
            },
          },
        },
      },
    },
  },
};
import {
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import PrincipalAssured from '../../components/estimation/PrincipalAssuredComponent.vue';
import SecondariesAssured from '../../components/estimation/SecondariesAssuredComponent.vue';

setInteractionMode("eager");

import { mapActions, mapState } from "vuex";
import gavStoreModule from "../../store/estimation/gav.store";

export default {
  name: "Gav Risk",
  props: {

  },
  components: {
    ValidationObserver,
    SecondariesAssured,
    PrincipalAssured,
  },
  data: () => ({
    menuBirthDate: false,
    formatedBirthDate: null,
    valid: false,
  }),
  beforeCreate() {
    this.$store.registerModule("productRisk", gavStoreModule);
  },
  async created() {
    await this.getGavItemsLists();
    this.$store.commit('productRisk/setProductRisk', this.$store.state.estimation.estimate.productRisk);
  },
  beforeDestroy() {
      this.$store.unregisterModule("productRisk");
    },
  mounted() {

  },
  computed: {
    ...mapState({
      mainInsured: (state) => state.productRisk.principalAssured,
      secondariesAssured: (state) => state.productRisk.secondariesAssured,
      defaultSecondaryAssured: (state) => state.productRisk.defaultSecondaryAssured,
      itemsListsClient: (state) => state.estimation.itemsLists,
      itemsListsProduct: (state) => state.productRisk.itemsLists,
      listLoader: (state) => state.productRisk.loaders.gavLists,
    }),
    itemsLists() { return {'itemsListsClient': this.itemsListsClient,'itemsListsProduct': this.itemsListsProduct};}
  },
  methods: {
    ...mapActions("productRisk", [
      "getGavItemsLists",
    ]),
    async nextStep() {
       await this.$refs["form"].validate().then((valid) => {
        if (valid) {
         this.$store.commit('estimation/nextEstimationStep')
        } else
          logger.debug('Formulaire de payement invalide')
      });
    },
  },
  watch: {
    "mainInsured.isSubscriber"(isSubscriber, oldVal) {
      logger.info("watch isSubscriber" + isSubscriber + ' ' + oldVal);
      /* if (isSubscriber == 'false') { */
        logger.debug("Reset info assuré principal");
        this.$store.commit("productRisk/resetPersonState");
     /*  } */
    },
    /* 'model.secondariesAssured.secondariesAssured': {
      handler: function (secondariesAssured, oldVal)  {
       
        if(oldVal != undefined)
          this.$store.commit('estimation/setSecondariesAssured', secondariesAssured);
      },
      deep: true
    }, */
  },
};
</script>









 