<template lang="pug">
  v-row(justify='center')
    v-col(cols='12' sm='4')
      v-card.pb-5
        v-card-title.justify-center
          v-img(lazy-src='' max-height='48' max-width='48' :src='require(`@/assets/images/logo_white.png`)')
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Merci
          br
          | Vos signatures ont bien été enregistrées
</template>

<style></style>

<script>
export default {
  name: "Thankyou",
  data: () => ({ appDir: process.env.VUE_APP_NAME }),
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
