var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3"},[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg3":"","md4":"","sm6":"","xs12":""}},[_c('v-text-field',{staticClass:"ma-2",attrs:{"label":"Titre","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.search['role.title[LIKE]']),callback:function ($$v) {_vm.$set(_vm.search, 'role.title[LIKE]', $$v)},expression:"search['role.title[LIKE]']"}})],1),_c('v-flex',{attrs:{"lg3":"","md4":"","sm6":"","xs12":""}},[_c('v-switch',{staticClass:"ma-5",attrs:{"label":"Actif","hide-details":""},model:{value:(_vm.search['role.isEnabled[=]']),callback:function ($$v) {_vm.$set(_vm.search, 'role.isEnabled[=]', $$v)},expression:"search['role.isEnabled[=]']"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"button_color--text",attrs:{"color":"button","large":""},on:{"click":function($event){return _vm.list()}}},[_c('v-icon',[_vm._v("search")]),_vm._v("Rechercher")],1)],1)],1),_c('BasicCrud',{attrs:{"title":"Gestion des Roles","item-name":"role","headers":_vm.headers,"items":_vm.items.roles,"nbItemsTotal":_vm.items.nbItemsTotal,"default-item":_vm.defaultItem,"create":_vm.create,"remove":_vm.remove,"update":_vm.update,"loading":_vm.loading,"valid":_vm.valid},on:{"list":_vm.list},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('tr',[_c('td',_vm._g({staticClass:"truncate"},on),[_vm._v(_vm._s(item.title))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_c('tr',[(item.isEnabled)?_c('td',[_c('v-icon',{attrs:{"small":""}},[_vm._v("lock_open")])],1):_c('td',[_c('v-icon',{attrs:{"small":""}},[_vm._v("lock")])],1)])]}},{key:"form",fn:function(ref){
var editedItem = ref.editedItem;
return [_c('v-container',[_c('validation-observer',{ref:"roleObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VTextInput',{attrs:{"label":"Titre *","messages":errors,"attr":{"outlined":"","clearable":"","hide-details":""}},on:{"formValidate":_vm.formValidate},model:{value:(editedItem.title),callback:function ($$v) {_vm.$set(editedItem, "title", $$v)},expression:"editedItem.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('VSwitchInput',{attrs:{"label":"Actif","messages":_vm.errors,"attr":{"class":"mt-0","hide-details":""}},on:{"formValidate":_vm.formValidate},model:{value:(editedItem.isEnabled),callback:function ($$v) {_vm.$set(editedItem, "isEnabled", $$v)},expression:"editedItem.isEnabled"}})],1)],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }