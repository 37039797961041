<template>
    <v-col>
     <v-skeleton-loader v-if="firstLoad" :loading="loading" type="card"></v-skeleton-loader>
    <div v-if="!firstLoad">
      <v-row 
        v-for="gamut in gamuts"
        :key="gamut.id"
      >
        <Gamut :ref="gamut.id" :gamut="gamut" :openedGamut="openedGamut" :openGamutCard="openGamutCard" :filesData="filesData" :listItems="listItems" /> 
      </v-row>
    </div>
      <v-row class="buttonBar" v-show="this.deleteMode">
        <v-btn
          depressed
          class="ma-2 list_color--text"
          color="button"
          @click="handleDelete"
        >
          Supprimer
        </v-btn>
        <v-btn
          depressed
          color="error"
          class="ma-2 list_color--text"
          @click="handleDeleteCancel"
        >
          Annuler
        </v-btn>
      </v-row>
      <v-row class="buttonBar" v-show="!this.deleteMode">
        <v-btn
            class="ma-2 list_color--text"
            :loading="!firstLoad && loading"
            :disabled="loading"
            color="button"
            @click="loader = 'loading';saveGamuts()"
            >
            Sauvegarder
            <template v-slot:loader>
                <span class="custom-loader">
                <v-icon light>autorenew</v-icon>
                </span>
            </template>
        </v-btn>

        <v-menu
      bottom
      :close-on-click="true"
      :offset-y="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="ma-2"
            fab
            dark
            absolute
            right
            color="button"
            v-bind="attrs"
            v-on="on"
        >
            <v-icon color="white" ><!-- @click="addGamut" -->
                build
            </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >

          <v-list-item-title @click="menuClickHandler(item.action)">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>        
        <!-- <v-btn
            class="mx-2"
            fab
            dark
            absolute
            right
            color="indigo"
        >
            <v-icon color="orange" @click="addGamut">
                add
            </v-icon>
        </v-btn> -->
      </v-row>
    <div class="text-center ma-2">
    
   <!--  <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
  </v-col>
  
  
  
</template>

<style scoped>
.buttonBar {
  margin-top: 12px;
}
 .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

</style>

<script>
import axios from "axios";
import Gamut from "./Gamut.vue";
export default {
  name: "Gamuts",
  data: () => ({
    gamuts: [],
    openedGamut : '',
    loading: true,
    firstLoad: true,
    loader: null,
    filesData: [],
    items: [
        { title: 'Ajouter une gamme', action:  'addGamut'},
        { title: 'Ajouter un produit', action:  'addProduct'},
        { title: 'Suppression', action: 'deleteMode'}
      ],
    deleteMode: false,
    selected: {
      gamuts : [],
      products: []
    },
    idsToDelete: {
      gamuts : [],
      products: []
    },
    filesIdsToDelete: [],
    listItems:[]
  /*   snackbar: false,
    text: '', */
  }),
  components: {
    Gamut
  },
  created () {  
    this.getDataFromApi();
  },
  mounted () {
    this.getItemsLists().then((data) => {
        this.$set(this.listItems,'productsCode', data.productsCode);
    }); 
  },
  computed: {
  },
  methods: {
     getItemsLists() {
      return new Promise((resolve, reject) => {
        const productsCodeReq = axios.get("/api/listItems/productsCode");
        /*  const countriesReq = axios.get("/api/listItems/countries");
        const cspsReq = axios.get("/api/listItems/csps");
        const maritalStatusesReq = axios.get("/api/listItems/marStat"); */

        axios
          .all([
            productsCodeReq /* , countriesReq, cspsReq, maritalStatusesReq */,
          ])
          .then(
            axios.spread((...responses) => {
              let productsCode = responses[0].data.listItems;
              /* let countries = responses[1].data.listItems;
              let csps = responses[2].data.listItems;
              let maritalStatuses = responses[3].data.listItems; */

              resolve({
                productsCode,
                /* countries,
                csps,
                maritalStatuses, */
              });
            })
          )
          .catch((error) => {
            // Error 😨
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log("error.response");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log("error.request");
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error");
              console.log("Error", error.message);
            }
            console.log("error.config");
            console.log(error.config);
          });
      });
    },
    addGamut() {
      let gamut = {
        "id" : "tmp_" + Math.floor(Math.random() * 5000),
        "title" : '',
        "iconName" : '',
        "products" : []
      }
      this.$set(this.gamuts,this.gamuts.length,gamut);
      this.openedGamut = gamut.id;
    },
    listGamutsPromise() {
      return new Promise((resolve, reject) => {

        axios.get("/api/gamuts")
        .then((response) => {

          let items = response.data.gamuts;

          resolve({
            items,
          });
        }).catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response');
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request');
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config');
          console.log(error.config);
        });

      });
    },
    saveGamutsPromise() {
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append('gamuts', JSON.stringify(this.gamuts));
        formData.append('idsToDelete', JSON.stringify(this.idsToDelete));
        formData.append('filesIdsToDelete', JSON.stringify(this.filesIdsToDelete));

        for (const key in this.filesData) {
          formData.append(key, this.filesData[key]);
        }

        
        axios.post("/api/admin/gamuts/modify", formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            maxContentLength: 10000000,
            maxBodyLength: 10000000
        }).then(() => {
          
         
            this.rerender();
        }).catch((error) => {
          // Error 😨
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log('error.response');
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log('error.request');
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error');
            console.log('Error', error.message);
          }
          console.log('error.config');
          console.log(error.config);
        }).finally(() => {
          this[this.loader] = false
          this.loader = null
        })
      });
    },
    getDataFromApi() {
      this.loading = true
      this.listGamutsPromise().then(data => {
        this.$data.gamuts = data.items;
        if (this.firstLoad) this.firstLoad = false
        this.loading = false
      })
    },
    openGamutCard(gamutId) {
      this.openedGamut = (this.openedGamut === gamutId.toString() ? "" : gamutId.toString());
    },
    saveGamuts() {
      this.saveGamutsPromise().then(() => {
        /* this[this.loader] = false
        this.loader = null; */
       /*  Object.assign(this.$data, this.$options.data.apply(this)); */
      });
      
    },
    menuClickHandler(action) {
       switch (action) {
        case 'addGamut':
          this.addGamut();
          break
        case 'addProduct':
          if(this.openedGamut != ''){
            this.$refs[this.openedGamut][0].addProduct();
          } else
            console.log("ouvrez une gamme")
          break
        case 'deleteMode':
          this.deleteMode = true;
          break
      }
    },
    handleDelete() {
      for (const key in this.selected.gamuts) {
        var selectedId = this.selected.gamuts[key];
        // if not containing tmp_
        if (selectedId.indexOf("tmp_") === -1) {
          this.idsToDelete.gamuts.push(selectedId);
        }

        for (const key in this.gamuts) {

          var gamut = this.gamuts[key];
          if(gamut.id == selectedId){
            for (const key in gamut.products) {

              var product = gamut.products[key];
              // Iterate on productFiles to store existing hashedname file to delete 
              for (const key in product.productFiles) {
                var productFile = product.productFiles[key];
                if (productFile.file != null && productFile.file.hashedName != '') {
                  this.filesIdsToDelete.push(productFile.file.hashedName);
                }
              }
              // Iterate on filesData (store new file to upload) to remove file entry 
              for (const fileId in this.filesData) {
              
                if(fileId.indexOf(product.id) !== -1)
                  this.$delete(this.filesData, fileId)
              }
            }
            this.gamuts.splice(key,1);
          }
        }
      }

      for (const key in this.selected.products) {

        var selectedId = this.selected.products[key];
        if (selectedId.indexOf("tmp_") === -1) {
          this.idsToDelete.products.push(selectedId)
        }
        for (const key in this.gamuts) {

          var gamut = this.gamuts[key];
          for (const key in gamut.products) {

            var product = gamut.products[key];
            if(product.id == selectedId){

              // Iterate on productFiles to store existing hashedname file to delete 
              for (const key in product.productFiles) {
                var productFile = product.productFiles[key];
                if (productFile.file != null && productFile.file.hashedName != '') {
                  this.filesIdsToDelete.push(productFile.file.hashedName);
                }
              }

              for (const fileId in this.filesData) {
              
                if(fileId.indexOf(product.id) !== -1)
                  this.$delete(this.filesData, fileId)
              }

              gamut.products.splice(gamut.products.indexOf(product),1);
            }
          }
        }
      }

      this.selected = {
        gamuts : [],
        products: []
      };
     
      this.deleteMode = false;
    },
    handleDeleteCancel() {
      this.selected = {
        gamuts : [],
        products: []
      };
      this.deleteMode = false;
    },
    rerender() {
      this.gamuts= [];
      this.openedGamut = '';
      this.loading= true;
      this.firstLoad= true;
      this.loader= null;
      this.filesData= [];
      this.items= [
          { title: 'Ajouter une gamme', action:  'addGamut'},
          { title: 'Ajouter un produit', action:  'addProduct'},
          { title: 'Supression', action: 'deleteMode'},
        ];
      this.deleteMode= false;
      this.selected= {
        gamuts : [],
        products: []
      };
      this.idsToDelete= {
        gamuts : [],
        products: []
      };
      this.filesIdsToDelete= [];
      this.getDataFromApi();
    }
  },
  watch: {
    loader () {
        const l = this.loader
        this[l] = !this[l]
      },
  }
};
</script>
